/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
==================================================
* Project Name   :  eCart - Multivendor Ecommerce Full Store Website
* File           :  CSS Base
* Version        :  1.0.1
* Author         :  WRTeam
* Vendor         :  Multivendor
==================================================
*/

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@font-face {
    font-family: "Font Awesome 5 Free", sans-serif;
    src: url("../fontawesome/webfonts/webfont.eot"); /* IE9 Compat Modes */
    src: url("webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("webfont.woff2") format("woff2"), /* Super Modern Browsers */ url("webfont.woff") format("woff"),
        /* Pretty Modern Browsers */ url("webfont.ttf") format("truetype"), /* Safari, Android, iOS */ url("webfont.svg#svgFontName") format("svg"); /* Legacy iOS */
}

/*
    01. header css
*/

/* universal styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
}

/* .container-fluid,
.container{
    border: 1px solid red;
}

.row{
    border: 1px solid yellowgreen;
} */

:root {
    --buttons: #ff9900;
    /* when you use other color from current theme then replace the color code #fff */
    --button-text: #fbfefb; /* #fff */
    --a-hover: #ff9900;
    --pop-linear-background: #f6f5f3;
    --pop-text: #121212;
    --cart-bagde: #dc3545;
    --font-weight: 800;

    /* header color */
    --header-search-back: #211313;
    --header-bottom: #f6f6f6;
    --header-cat-title: #212121;

    /* footer color */
    --footer-back: #140f0f;

    /* dark-mode back-color */
    --dark-mode: #F6F5F3;
    /* --dark-mode: #433333; */
}
h1 h2 h3 h4 h5 h6
{
    color: #3A2525 !important;
    font-weight: 800;
}

p:last-child {
    margin-bottom: 0;
}

a,
button {
    color: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}

a,
button,
img,
input,
span {
    transition: all 0.3s ease 0s;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
    border-color: transparent !important;
    color: #fff !important;
    background-color: #131921 !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #131921;
    border-color: transparent !important;
    box-shadow: none !important;
}

.norm-btn {
    background-color: var(--buttons);
    color: #fff;
    padding: 10px;
    border-radius: 2px;
}

*:focus {
    outline: none !important;
}

a:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

button,
input[type="submit"] {
    cursor: pointer;
}

img {
    max-width: 100%;
    height: auto;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

figure {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

[class*="border"],
hr {
    border-color: #e2e5ec !important;
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.border-bottom {
    border-bottom: 1px solid #1b0b0e !important;
}

.border-width-2 {
    border-width: 2px !important;
}

.border-primary {
    border-color: var(--buttons) !important;
}

/* wrapper-content */
#wrapper {
    margin: 0;
    box-shadow: inset 0 2px 5px 0 rgb(0 0 0 / 11%);
    display: block !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--buttons);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.04);
}

body,
html {
    direction: ltr;
    font-family: "Lato", sans-serif;
    height: 100%;
    background-color: #7c3535;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* common classes */
.main-wrapper.header-transparent {
    padding: 0 !important;
    margin: 0 !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
}

.hidden {
    display: none;
}

.clear {
    display: block;
    clear: both;
    content: "";
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 1770px) {
    .container-fluid {
        max-width: 1740px;
    }
}
.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.bg-img {
    background-position: center center;
    background-size: cover;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
}

.view-all {
    text-align: center;
    font-weight: bold;
    position: relative;
    transition: all 1s ease;
}

.btn-sm {
    padding: 0.616rem 1rem;
    font-size: 0.8125rem;
}

/* .view-all::before {
    display: inline-block;
    content: "";
    border-top: 0.1rem solid black;
    width: 0.8rem;
    margin: 0 0.5rem;
    transform: translateY(-0.2rem);
} */

.sale-view-all,
.sale-view-all:hover {
    margin: auto;
    display: block;
    text-align: center;
    background-color: var(--buttons);
    color: #fff;
    width: 70px;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
}

/* typograpghy */
/* ================ Typography Css =============== */
h1 {
    font-size: 2em;
    font-family: "Lato", sans-serif;
    line-height: 1.25;
}

h2 {
    font-size: 1.625em;
    font-family: "Lato", sans-serif;
    line-height: 1.15384615;
}

h3 {
    font-size: 1.375em;
    font-family: "Lato", sans-serif;
    line-height: 1.13636364;
}

h4 {
    font-size: 1.125em;
    font-family: "Lato", sans-serif;
    line-height: 1.11111111;
}

h5 {
    font-size: 1.1em;
    font-family: "Lato", sans-serif;
    line-height: 1.1;
}

h6 {
    font-size: 1em;
    font-family: "Lato", sans-serif;
    line-height: 1.05;
}

/* eof typography */

/* color-switcher */
.demo-style-switch .switch-button em {
    color: var(--buttons);
}

/* Dark Mode */

.darkmodebtn {
    border: none;
    background-color: #446084;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.p-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
    margin-top: 0;
}

.mt-10 {
    margin-top: 10px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-150 {
    margin-top: 150px;
}

/*************************
      Margin right
*************************/

.mr-0 {
    margin-right: 0px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-35 {
    margin-right: 35px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-45 {
    margin-right: 45px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-60 {
    margin-right: 60px;
}

.mr-70 {
    margin-right: 70px;
}

.mr-80 {
    margin-right: 80px;
}

.mr-90 {
    margin-right: 90px;
}

.mr-100 {
    margin-right: 100px;
}

.mr-110 {
    margin-right: 110px;
}

.mr-120 {
    margin-right: 120px;
}

.mr-130 {
    margin-right: 130px;
}

.mr-140 {
    margin-right: 140px;
}

.mr-150 {
    margin-right: 150px;
}

.mr-200 {
    margin-right: 200px;
}

.mr-210 {
    margin-right: 210px;
}

.mr-220 {
    margin-right: 220px;
}

.mr-230 {
    margin-right: 230px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-150 {
    margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
    margin-left: 0;
}

.ml-10 {
    margin-left: 10px;
}

.ml-12 {
    margin-left: 12px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-25 {
    margin-left: 25px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-35 {
    margin-left: 35px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-45 {
    margin-left: 45px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-55 {
    margin-left: 55px;
}

.ml-60 {
    margin-left: 60px;
}

.ml-70 {
    margin-left: 70px;
}

.ml-75 {
    margin-left: 75px;
}

.ml-80 {
    margin-left: 80px;
}

.ml-90 {
    margin-left: 90px;
}

.ml-95 {
    margin-left: 95px;
}

.ml-100 {
    margin-left: 100px;
}

.ml-110 {
    margin-left: 110px;
}

.ml-120 {
    margin-left: 120px;
}

.ml-130 {
    margin-left: 130px;
}

.ml-135 {
    margin-left: 135px;
}

.ml-140 {
    margin-left: 140px;
}

.ml-145 {
    margin-left: 145px;
}

.ml-150 {
    margin-left: 150px;
}

.ml-200 {
    margin-left: 200px;
}

.ml-300 {
    margin-left: 300px;
}

.ml-ntv5 {
    margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
    padding-top: 0;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px;
}

.pt-85 {
    padding-top: 85px;
}

@media only screen and (max-width: 767px) {
    .pt-85 {
        padding-top: 45px;
    }
}

.pt-80 {
    padding-top: 80px;
}

@media only screen and (max-width: 767px) {
    .pt-80 {
        padding-top: 50px;
    }
}

.pt-95 {
    padding-top: 95px;
}

.pt-90 {
    padding-top: 90px;
}

@media only screen and (max-width: 767px) {
    .pt-90 {
        padding-top: 50px;
    }
}

.pt-100 {
    padding-top: 100px;
}

@media only screen and (max-width: 767px) {
    .pt-100 {
        padding-top: 50px;
    }
}

.pt-105 {
    padding-top: 105px;
}

@media only screen and (max-width: 767px) {
    .pt-105 {
        padding-top: 45px;
    }
}

.pt-110 {
    padding-top: 110px;
}

@media only screen and (max-width: 767px) {
    .pt-110 {
        padding-top: 50px;
    }
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

@media only screen and (max-width: 767px) {
    .pt-120 {
        padding-top: 50px;
    }
}

.pt-125 {
    padding-top: 125px;
}

.pt-128 {
    padding-top: 128px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-135 {
    padding-top: 135px;
}

.pt-165 {
    padding-top: 165px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-155 {
    padding-top: 155px;
}

.pt-170 {
    padding-top: 170px;
}

.pt-180 {
    padding-top: 180px;
}

.pt-200 {
    padding-top: 200px;
}

.pt-220 {
    padding-top: 220px;
}

.pt-250 {
    padding-top: 250px;
}

.pt-210 {
    padding-top: 210px;
}

.pt-230 {
    padding-top: 230px;
}

.pt-270 {
    padding-top: 270px;
}

.pt-240 {
    padding-top: 240px;
}

.pt-365 {
    padding-top: 365px;
}

.pt-370 {
    padding-top: 370px;
}

.pt-380 {
    padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
    padding-right: 0;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.pr-50 {
    padding-right: 50px;
}

.pr-60 {
    padding-right: 60px;
}

.pr-70 {
    padding-right: 70px;
}

.pr-80 {
    padding-right: 80px;
}

.pr-90 {
    padding-right: 90px;
}

.pr-100 {
    padding-right: 100px;
}

.pr-110 {
    padding-right: 110px;
}

.pr-120 {
    padding-right: 120px;
}

.pr-130 {
    padding-right: 130px;
}

.pr-140 {
    padding-right: 140px;
}

.pr-155 {
    padding-right: 155px;
}

.pr-270 {
    padding-right: 270px;
}

.pr-210 {
    padding-right: 210px;
}

.pr-215 {
    padding-right: 215px;
}

.pr-220 {
    padding-right: 220px;
}

.pr-230 {
    padding-right: 230px;
}

.pr-260 {
    padding-right: 260px;
}

.pr-430 {
    padding-right: 430px;
}

.pr-445 {
    padding-right: 445px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
    padding-bottom: 0;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .pb-50 {
        padding-bottom: 20px;
    }
}

.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
    .pb-70 {
        padding-bottom: 20px;
    }
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
    .pb-80 {
        padding-bottom: 50px;
    }
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
    .pb-90 {
        padding-bottom: 50px;
    }
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-100 {
    padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
    .pb-100 {
        padding-bottom: 50px;
    }
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-110 {
    padding-bottom: 110px;
}

@media only screen and (max-width: 767px) {
    .pb-110 {
        padding-bottom: 50px;
    }
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-135 {
    padding-bottom: 135px;
}

@media only screen and (max-width: 767px) {
    .pb-135 {
        padding-bottom: 86px;
    }
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-145 {
    padding-bottom: 145px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-175 {
    padding-bottom: 175px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-280 {
    padding-bottom: 280px;
}

.pb-210 {
    padding-bottom: 210px;
}

.pb-285 {
    padding-bottom: 285px;
}

.pb-270 {
    padding-bottom: 270px;
}

.pb-290 {
    padding-bottom: 290px;
}

/*************************
        Padding left
*************************/
.pl-0 {
    padding-left: 0;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-50 {
    padding-left: 50px;
}

.pl-60 {
    padding-left: 60px;
}

.pl-70 {
    padding-left: 70px;
}

.pl-80 {
    padding-left: 80px;
}

.pl-90 {
    padding-left: 90px;
}

.pl-100 {
    padding-left: 100px;
}

.pl-110 {
    padding-left: 110px;
}

.pl-120 {
    padding-left: 120px;
}

.pl-130 {
    padding-left: 130px;
}

.pl-140 {
    padding-left: 140px;
}

.pl-150 {
    padding-left: 150px;
}

.pl-155 {
    padding-left: 155px;
}

.pl-190 {
    padding-left: 190px;
}

.pl-200 {
    padding-left: 200px;
}

.pl-215 {
    padding-left: 215px;
}

/***************************
    Page section padding
****************************/
.ptb-0 {
    padding: 0;
}

.ptb-10 {
    padding: 10px 0;
}

.ptb-20 {
    padding: 20px 0;
}

.ptb-30 {
    padding: 30px 0;
}

.ptb-35 {
    padding: 35px 0;
}

.ptb-32 {
    padding: 32px 0;
}

.ptb-40 {
    padding: 40px 0;
}

.ptb-50 {
    padding: 50px 0;
}

.ptb-60 {
    padding: 60px 0;
}

.ptb-70 {
    padding: 70px 0;
}

.ptb-80 {
    padding: 80px 0;
}

.ptb-90 {
    padding: 90px 0;
}

.ptb-100 {
    padding: 100px 0;
}

.ptb-110 {
    padding: 110px 0;
}

.ptb-120 {
    padding: 120px 0;
}

.ptb-130 {
    padding: 130px 0;
}

.ptb-140 {
    padding: 140px 0;
}

.ptb-150 {
    padding: 150px 0;
}

.ptb-160 {
    padding: 160px 0;
}

.ptb-170 {
    padding: 170px 0;
}

.ptb-177 {
    padding: 177px 0;
}

.ptb-180 {
    padding: 180px 0;
}

.ptb-190 {
    padding: 190px 0;
}

.ptb-200 {
    padding: 200px 0;
}

.ptb-210 {
    padding: 210px 0;
}

.ptb-220 {
    padding: 220px 0;
}

.ptb-290 {
    padding: 290px 0;
}

.ptb-310 {
    padding: 310px 0;
}

/***************************
    Page section margin
****************************/
.mtb-0 {
    margin: 0;
}

.mtb-10 {
    margin: 10px 0;
}

.mtb-15 {
    margin: 15px 0;
}

.mtb-20 {
    margin: 20px 0;
}

.mtb-30 {
    margin: 30px 0;
}

.mtb-40 {
    margin: 40px 0;
}

.mtb-50 {
    margin: 50px 0;
}

.mtb-60 {
    margin: 60px 0;
}

.mtb-70 {
    margin: 70px 0;
}

.mtb-80 {
    margin: 80px 0;
}

.mtb-90 {
    margin: 90px 0;
}

.mtb-100 {
    margin: 100px 0;
}

.mtb-110 {
    margin: 110px 0;
}

.mtb-120 {
    margin: 120px 0;
}

.mtb-130 {
    margin: 130px 0;
}

.mtb-140 {
    margin: 140px 0;
}

.mtb-150 {
    margin: 150px 0;
}

.mtb-290 {
    margin: 290px 0;
}

/* scroll */
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: var(--buttons);
    color: var(--button-text);
    width: 50px;
    height: 50px;
    display: none;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    z-index: 1000;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#return-to-top em {
    margin: 0;
    position: relative;
    left: 19px;
    top: 13px;
    font-size: 1.188;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

/* eof scroll */
/* prelaoder */

.loader {
    display: none !important;
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #e5eff1;
}

.loader img {
    position: relative;
    margin: auto;
    width: 300px;
    height: 300px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* product preloader */
#loader2 {
    position: fixed;
    left: 50%;
    top: 50%;
    bottom: 50%;
    right: 50%;
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: none;
}

#loader2 img {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: 50px;
    height: 50px;
}

/* breadcrumb */
.overflow-visible {
    overflow: visible;
    position: relative;
    z-index: 2;
}
.breadcrumb .breadcrumb-item a
{
    color: #3A2525 !important;
}

.breadcrumb-item.active {
    color: var(--buttons);
}

.page_title {
    padding: 1.1rem;
    background-image: url(../images/breadcrumbs.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media (min-width: 480px) and (max-width: 1400px) {
    .page_title {
        background-size: 100% 100%;
        height: auto;
    }
}

@media (max-width: 479px) {
    .page_title {
        background-size: 100% 100%;
        height: auto;
        padding: 1.2rem;
    }

    .page_title h1 {
        font-size: small;
    }
}

@media (min-width: 992px) {
    .page_title h1,
    .page_title h2 {
        font-size: 1.375rem;
    }
}

.page_title h1,
.page_title h2 {
    line-height: 1;
    text-transform: capitalize;
}

.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0.2em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    justify-content: left;
    padding-top: 10px;
}
.ujuleft {
    margin-left: 5px;
}

@media (max-width: 479px) {
    .breadcrumb {
        padding-top: 5px;
    }
}

/* 01. header css*/
.sticky-header.sticky {
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 900ms;
    animation-duration: 900ms;
    -webkit-animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
    animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    border-bottom: 0;
    display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sticky-header.sticky {
        position: inherit;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        -webkit-animation-name: inherit;
        animation-name: inherit;
        padding: 0;
        background: inherit;
        opacity: inherit;
    }
}

@media only screen and (max-width: 767px) {
    .sticky-header.sticky {
        position: inherit;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        -webkit-animation-name: inherit;
        animation-name: inherit;
        padding: 0;
        background: inherit;
        opacity: inherit;
    }
}

.sticky-header.sticky .search_widget {
    top: 50px;
}

.sticky-header.sticky .dropdown_account {
    top: 50px;
}

.mobile_menu .container {
    position: relative;
}

.off_canvars_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    cursor: crosshair;
    background: #222222;
    top: 0;
}

.off_canvars_overlay.active {
    opacity: 0.5;
    visibility: visible;
}

.mobile_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    cursor: crosshair;
    background: #222222;
    top: 0;
}

.mobile_overlay.active {
    opacity: 0.5;
    visibility: visible;
}

.mobile_menu {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .mobile_menu {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .mobile_menu {
        display: block;
    }
}

.mobile_wrapper {
    width: 290px;
    position: fixed;
    background: #fff;
    z-index: 999;
    top: 0;
    height: 100vh;
    transition: 0.5s;
    left: 0;
    margin-left: -300px;
    padding: 50px 15px 30px;
    overflow-y: auto;
}

/* width */
.mobile_wrapper::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.mobile_wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.mobile_wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.mobile_wrapper.active {
    margin-left: 0;
}

.mobile_wrapper .slinky-theme-default {
    background: inherit;
    min-height: 300px;
    overflow-y: auto;
}

.mobile_wrapper .header_account_area {
    display: flex;
}

.mobile_wrapper .connect-with-us {
    display: block;
}

.offcanvas_main_menu > li.menu-item-has-children.menu-open > span.menu-expand {
    transform: rotate(180deg);
}

.offcanvas_main_menu > li ul li.menu-item-has-children.menu-open span.menu-expand {
    transform: rotate(180deg);
}

.offcanvas_main_menu li {
    position: relative;
}

.offcanvas_main_menu li:last-child {
    margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
    position: absolute;
    right: 0;
}

.offcanvas_main_menu li a {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: capitalize;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
}

.offcanvas_main_menu li a:hover {
    color: var(--a-hover);
}

.offcanvas_main_menu li ul.sub-menu {
    padding-left: 20px;
}

.offcanvas_footer {
    margin-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.offcanvas_footer span a {
    font-size: 0.875rem;
}

.offcanvas_footer span a:hover {
    color: var(--a-hover);
}

.mobile_canvas_close {
    position: absolute;
    top: 10px;
    right: 13px;
}

.mobile_canvas_close a {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 500;
    width: 32px;
    height: 32px;
    display: block;
    text-align: center;
}

.mobile_canvas_close a:hover {
    background: var(--a-hover);
    border-color: var(--a-hover);
    color: #fff;
}

.mobile_canvas_open {
    position: absolute;
    right: 17px;
    top: 11px;
    color: #fff;
    z-index: 9;
}

@media (min-width: 576px) and (max-width: 767px) {
    .mobile_canvas_open {
        right: 0px;
    }
}

.mobile_canvas_open a {
    font-size: 1.625rem;
    width: 41px;
    height: 37px;
    display: block;
    line-height: 39px;
    text-align: center;
    color: black;
}

@media only screen and (max-width: 767px) {
    .mobile_canvas_open a {
        width: 32px;
        height: 32px;
        line-height: 34px;
        font-size: 2rem;
        margin-top: 8px;
    }
}

.mobile_canvas_open a:hover {
    color: var(--a-hover);
    border-color: var(--a-hover);
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

/*header css here*/

.main_header .top_bar_left > li {
    display: inline-block;
}
.main_header .top_bar_left button {
    color: #fff !important;
    margin-left: 10px;
}

.header_top {
    background-color: #232f3e;
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header_top {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .header_top {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header_social_icon {
        text-align: center !important;
        margin-bottom: 18px;
    }
}

@media only screen and (max-width: 767px) {
    .header_social_icon {
        text-align: center !important;
        margin-bottom: 18px;
    }
}

.header_social_icon ul li {
    display: inline-block;
    padding-right: 34px;
}

.header_social_icon ul li:last-child {
    padding-right: 0;
}

.header_social_icon ul li a {
    font-size: 1rem;
}

.header_social_icon ul li a:hover {
    color: var(--a-hover);
}

.middle_content {
    padding: 0px 0px;
    background-color: var(--header-search-back);
    color: #fff;
}
.header_bottom {
    background-color: var(--header-bottom);
    color: #3A2525;
    font-weight: 500;
    padding: 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .header_bottom {
        padding: 15px;
    }
}

@media (max-width: 767px) {
    .header_bottom {
        padding: 10px 0;
    }
}

.logo img {
    max-width: 200px;
    height: 60px;
    object-fit: contain;
}

@media only screen and (max-width: 1199px) {
    .logo img {
        max-width: 120px;
    }
}

@media only screen and (max-width: 575px) {
    .logo img {
        max-width: 150px;
        display: block;
        margin: auto auto 0px auto;
    }
}

.dropdown_links {
    position: absolute;
    background: #fff;
    border: 1px solid #ededed;
    padding: 10px 20px;
    text-align: left;
    top: 100%;
    right: -36px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    overflow: hidden;
    z-index: 9999;
    border-radius: 3px;
    visibility: hidden;
    max-height: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .dropdown_links {
        padding: 10px 12px;
    }
}

@media only screen and (max-width: 767px) {
    .dropdown_links {
        padding: 10px 12px;
    }
}

.dropdown_links li a {
    text-transform: capitalize;
    display: block;
    font-size: 0.813rem;
    white-space: nowrap;
    line-height: 28px;
    color: #222222;
}

.dropdown_links li a:hover {
    color: var(--a-hover);
}

.category_hover_content {
    position: relative;
    border-radius: 25px 0 0 25px;
    width: 40%;
}

@media (max-width: 479px) {
    .category_hover_content select {
        width: 100% !important;
        border-radius: 5px !important;
    }
}

.category_hover_content select {
    border: 0;
    background: #f5f5f5;
    *padding-left: 25px;
    padding-right: 0px;
    font-size: 0.9rem;
    height: 45px;
    line-height: 45px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 100%;
    text-align:left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .category_hover_content {
        border-radius: 6px 0 0 6px;
    }
}

@media only screen and (max-width: 479px) {
    .category_hover_content {
        border-radius: 6px;
        width: 100%;
        margin: 0 auto 7px;
    }
}

.category_hover_content .select_option {
    border: 0;
    background: #f5f5f5;
    padding-left: 25px;
    padding-right: 35px;
    font-size: 0.813rem;
    height: 45px;
    line-height: 45px;
    border-radius: 25px 0 0 25px;
    width: 190px;
    place-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .category_hover_content .select_option {
        padding-right: 28px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .category_hover_content .select_option {
        height: 43px;
        line-height: 43px;
        font-size: 0.75rem;
        width: 145px;
        letter-spacing: 0;
        padding-left: 10px;
        padding-right: 23px;
        border-radius: 6px 0 0 6px;
    }
}

@media only screen and (max-width: 767px) {
    .category_hover_content .select_option {
        height: 37px;
        line-height: 37px;
        font-size: 0.813rem;
        border-radius: 6px;
    }
}

@media only screen and (max-width: 479px) {
    .category_hover_content .select_option {
        width: 100%;
    }
}

.category_hover_content .select_option::after {
    top: 54%;
    right: 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .category_hover_content .select_option::after {
        right: 8px;
    }
}

.category_hover_content .select_option ul.list {
    max-height: 300px;
    overflow: auto;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .category_hover_content .select_option ul.list {
        max-height: 220px;
    }
}

@media only screen and (max-width: 767px) {
    .category_hover_content .select_option ul.list {
        max-height: 220px;
    }
}

.searchbar_content .search-box {
    color: #000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .searchbar_content {
        margin-right: 35px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .searchbar_content {
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .searchbar_content {
        margin-right: 0;
    }
}

.searchbar_content form {
    display: flex;
    width: 100%;
    margin: auto;
}

@media (min-width: 1400px) and (max-width: 1799px) {
    .col-8.mobile_screen_none {
        width: 62%;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .col-8.mobile_screen_none {
        width: 56%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .col-8.mobile_screen_none {
        width: 47%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .searchbar_content form {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .searchbar_content form {
        width: 100%;
        border-radius: 6px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
}

@media only screen and (max-width: 479px) {
    .searchbar_content form {
        flex-direction: column;
        border: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .mobile_screen_none {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .mobile_screen_none {
        display: none;
    }
}

.mobail_s_block {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .mobail_s_block {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .mobail_s_block {
        display: block;
    }
}

.search-box {
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 479px) {
    .search-box {
        border-radius: 10px;
    }
}

.search-box input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #222222;
}

.search-box input::-moz-placeholder {
    /* Firefox 19+ */
    color: #222222;
}

.search-box input:-ms-input-placeholder {
    /* IE 10+ */
    color: #222222;
}

.search-box input:-moz-placeholder {
    /* Firefox 18- */
    color: #222222;
}

.search-box input {
    border: 0;
    width: 100%;
    height: 45px;
    color: #222222;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0 75px 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .search-box input {
        font-size: 0.75rem;
        padding: 0 54px 0 10px;
        width: 100%;
    }
}

@media only screen and (max-width: 479px) {
    .search-box input {
        font-size: 0.75rem;
        padding: 0 54px 0 10px;
        width: 100%;
    }
}

.search-box button {
    border: 0;
    position: absolute;
    top: 0;
    height: 100%;
    line-height: 48px;
    width: 60px;
    padding: 0;
    text-align: center;
    right: 0;
    font-weight: 400;
    font-size: 1.25rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: var(--buttons);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .search-box button {
        width: 48px;
        padding: 0 6px;
        top: 0;
        bottom: 0;
        right: 0;
        font-size: 1rem;
        line-height: 38px;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .search-box button {
        width: 48px;
        padding: 0 6px;
        top: 0;
        bottom: 0;
        right: 0;
        font-size: 1rem;
        line-height: 38px;
        border-radius: 0 6px 6px 0;
    }
}

.header_account_list {
    margin: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header_account_list {
        margin-right: 17px;
    }
}

.header_account_list > a {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
}

.header_account_list > a:hover {
    color: var(--a-hover);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header_account_list > a {
        font-size: 1.375rem;
    }
}

@media only screen and (max-width: 767px) {
    .header_account_list > a {
        font-size: 1.375rem;
    }
}

.header_account_list.register ul li {
    display: block;
    border-top: 1px solid lightgray;
}

.header_account_list.register ul li a:hover {
    color: var(--a-hover);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header_account_list.register ul li a {
        font-size: 0.813rem;
    }
}

@media only screen and (max-width: 767px) {
    .header_account_list.register ul li a {
        font-size: 0.813rem;
    }
}

.header_account_list.register ul li span {
    margin: 0 2px;
    padding-left: 10px;
}

.header_account_list span.item_count {
    margin-left: 5px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    background: var(--cart-bagde);
    color: #fff;
    border-radius: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 0.75rem;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .header_account_list span.item_count {
        margin-left: 2px;
        line-height: 16px;
    }
}

.header_account_area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.content_right_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.content_right_info > .col > button {
    color: #fff;
}

@media (min-width: 1800px) {
    .content_right_info > button {
        margin-left: 0;
    }
}

.content_right_info > button,
.content_right_info > button:hover {
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .content_right_info {
        justify-content: center;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .content_right_info {
        justify-content: center;
    }
}

@media (max-width: 574px) {
    .content_right_info {
        display: block;
    }
}

.content_right_info .pin__code {
    max-width: 170px;
}

@media (max-width: 574px) {
    .content_right_info .pin__code {
        max-width: 100%;
        padding: 0 10px;
    }
}
/* cart page */

.cart__page {
    display: none;
}

@media (max-width: 767px) {
    .cart__page {
        display: block;
        font-size: 1rem;
        font-weight: bold;
    }
}
/*mini cart css here*/

.mini_no_cart img {
    object-fit: contain;
    height: 150px;
    width: 150px;
    max-width: 100%;
    max-height: 100%;
}

.cart_close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.cart_text h3 {
    font-size: 1.125rem;
    text-transform: capitalize;
    font-weight: var(--font-weight);
    margin-bottom: 0;
}

.mini_cart_close > a {
    font-size: 1.563rem;
}

.mini_cart_close > a:hover {
    color: var(--a-hover);
}

.mini_cart {
    position: fixed;
    min-width: 355px;
    height: 100%;
    padding: 18px 28px 33px;
    background: #fff;
    z-index: 999;
    right: 0;
    top: 0;
    transition: 0.5s;
    transform: translateX(100%);
    overflow: auto;
}

.mini_cart.active {
    transform: translateX(0);
    transition: 0.5s;
}

.midd_button{
    padding: 7px 15px;
}

.mini_cart_button{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mini_cart_button .button-minus{
    border-radius: 50%;
}

.mini_cart_button .midd_button{
    border-radius: 10px;
}

.mini_cart_button .button-plus-cart{
    border-radius: 50%;
}

@media only screen and (max-width: 767px) {
    .mini_cart {
        min-width: 300px;
        height: 100%;
        padding: 18px 18px 33px;
    }
}

.cart_img {
    width: 90px;
    height: 45px;
    margin-right: 10px;
    border: 1px solid transparent;
}

.cart_img:hover {
    border-color: var(--a-hover);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_img {
        width: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .cart_img {
        width: 70px;
    }
}

.cart_info {
    width: 63%;
}

.cart_info a {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    display: block;
    margin-bottom: 6px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_info a {
        font-size: 0.813rem;
    }
}

@media only screen and (max-width: 767px) {
    .cart_info a {
        font-size: 0.813rem;
    }
}

.cart_info a:hover {
    color: var(--a-hover);
}

.cart_info p {
    font-size: 0.75rem;
}

.cart_info p span {
    font-weight: var(--font-weight);
}

.cart_remove a {
    font-size: 0.938rem;
    display: block;
    line-height: 20px;
    text-align: center;
}

.cart_remove a:hover {
    color: var(--a-hover);
}

.cart_item {
    overflow: hidden;
    padding: 20px 0;
    border-bottom: 1px solid #ededed;
    display: flex;
    justify-content: space-between;
}

.mini_cart_table {
    padding: 23px 0;
}

.cart_total {
    display: flex;
    justify-content: space-between;
}

.cart_total span {
    font-size: 0.875rem;
    font-weight: 400;
}

.cart_total span.price {
    font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_total span {
        font-size: 0.813rem;
    }
}

@media only screen and (max-width: 767px) {
    .cart_total span {
        font-size: 0.813rem;
    }
}

.cart_button:first-child {
    margin-bottom: 15px;
}

.cart_button a {
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 0.75rem;
    font-weight: 500;
    background: #eef0f1;
    display: block;
    text-align: center;
    line-height: 20px;
    margin-bottom: 0;
    padding: 13px 0px 11px;
    border: 1px solid #ededed;
}

.cart_button a:hover {
    background: var(--a-hover);
    border-color: var(--a-hover);
    color: #fff;
}

/*mini cart css end*/
/*  main menu css here */
.main_menu_position {
    position: relative;
    display: block;
    margin: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .main_menu_content {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .main_menu_content {
        display: none;
    }
}

.main_menu_content nav > ul {
    display: flex;
}

.main_menu_content nav > ul > li {
    position: relative;
    margin-right: 37px;
}

.main_menu_content nav > ul > li:last-child {
    margin-right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main_menu_content nav > ul > li {
        margin-right: 24px;
    }
}

.main_menu_content nav > ul > li:hover ul.sub_menu {
    visibility: visible;
    max-height: 360px;
    padding: 24px 20px;
}

.main_menu_content nav > ul > li:hover .mega_menu {
    visibility: visible;
    max-height: 300px;
    padding: 25px 30px 30px 30px;
}

.main_menu_content nav > ul > li:hover > a {
    color: var(--a-hover);
}

.main_menu_content nav > ul > li:hover > a::before {
    opacity: 1;
    visibility: visible;
}

.main_menu_content nav > ul > li > a {
    display: block;
    font-size: 1rem;
    line-height: 50px;
    text-transform: capitalize;
    font-weight: 500;
    position: relative;
}

.main_menu_content nav > ul > li > a em {
    margin-left: 3px;
}

.main_menu_content nav > ul > li > a.active {
    color: var(--a-hover);
}

.main_menu_content nav > ul > li ul.sub_menu {
    position: absolute;
    min-width: 220px;
    padding: 0 20px;
    background: #ffffff;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
    max-height: 0;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    left: 0;
    right: auto;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 99;
    top: 101%;
    text-align: left;
}

.main_menu_content nav > ul > li ul.sub_menu li a {
    font-size: 0.875rem;
    font-weight: 400;
    display: block;
    line-height: 32px;
    text-transform: capitalize;
    color: #000;
}

.main_menu_content nav > ul > li ul.sub_menu li a:hover {
    color: var(--a-hover);
}

.main_menu_content nav > ul > li.mega_items_content {
    position: static;
}

.main_menu_content nav > ul > li .mega_menu {
    position: absolute;
    min-width: 100%;
    padding: 0 30px 0 30px;
    background: #ffffff;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
    max-height: 0;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    left: 0;
    right: auto;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 99;
    top: 101%;
    text-align: left;
}

.mega_menu_content {
    display: flex;
    justify-content: space-between;
}

.mega_menu_content > li > a {
    font-size: 0.938rem;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 500;
    display: block;
    margin-bottom: 12px;
}

.mega_menu_content > li > a:hover {
    color: var(--a-hover);
}

.mega_menu_content > li ul li {
    display: block;
}

.mega_menu_content > li ul li a {
    font-size: 0.875rem;
    font-weight: 400;
    display: block;
    line-height: 30px;
    text-transform: capitalize;
}

.mega_menu_content > li ul li a:hover {
    color: var(--a-hover);
}

/*main menu css end*/

/*-- Categories menu css here --*/
.categories_menu {
    position: relative;
}
.categories_toggle em {
    margin-right: 10px;
}

.title_content {
    position: relative;
    cursor: pointer;
    height: 51px;
    background: var(--buttons);
    padding-left: 20px;
    color: var(--header-cat-title);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .title_content {
        height: 45px;
        border-radius: 6px;
    }
}

@media only screen and (max-width: 767px) {
    .title_content {
        height: 43px;
        border-radius: 6px;
    }
}

.title_content h2 {
    font-size: 1rem;
    line-height: 50px;
    cursor: pointer;
    margin-bottom: 0;
    display: block;
    text-transform: capitalize;
    position: relative;
}

.title_content > em {
    position: absolute;
    top: 22px;
    right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .title_content h2 {
        line-height: 45px;
        float: inherit;
        padding-right: 37px;
    }
}

@media only screen and (max-width: 767px) {
    .title_content h2 {
        line-height: 45px;
        float: inherit;
        padding-right: 37px;
    }
}

.categories_content_toggle {
    background: #ffffff;
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 9;
    display: none;
    padding: 10px 0;
    box-shadow: 0px 0px 5.76px 0.24px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle {
        display: none;
        max-height: 360px;
        overflow: auto;
        padding: 10px 0 20px;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle {
        display: none;
        max-height: 350px;
        overflow: auto;
    }
}

.categories_content_toggle > ul > li {
    position: relative;
}

.categories_content_toggle > ul > li > a {
    font-size: 0.875rem;
    line-height: 38px;
    text-transform: capitalize;
    font-weight: 400;
    display: block;
    cursor: pointer;
    padding: 0 20px 0 30px;
    color: #000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .categories_content_toggle > ul > li > a {
        line-height: 38px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li > a {
        line-height: 38px;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li > a {
        line-height: 38px;
    }
}

.categories_content_toggle > ul > li > a em.fas.fa-plus {
    float: right;
    font-size: 0.438rem;
    line-height: 35px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li > a em.fas.fa-plus {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li > a em.fas.fa-plus {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li > a:hover {
        color: var(--a-hover);
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li > a:hover {
        color: var(--a-hover);
    }
}

.categories_content_toggle > ul > li:last-child > a {
    border-bottom: 0;
}

.categories_content_toggle > ul > li:hover ul.cate_mega_menu {
    opacity: 1;
    visibility: visible;
    left: 100%;
}

.categories_content_toggle > ul > li:hover > a {
    color: var(--a-hover);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li:hover > a {
        background: inherit;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li:hover > a {
        background: inherit;
    }
}

.categories_content_toggle > ul > li ul.cate_mega_menu {
    position: absolute;
    left: 120%;
    width: 300px;
    background: #ffffff;
    color: #000;
    padding: 25px 20px 23px;
    overflow: hidden;
    top: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    display: block;
    justify-content: space-between;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu {
        width: 480px;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu {
        display: none;
        opacity: inherit !important;
        visibility: inherit !important;
        left: inherit !important;
        width: 100%;
        top: 100%;
        max-height: 350px;
        overflow: auto;
        transition: unset;
        box-shadow: inherit;
        position: inherit;
        padding: 0 20px 0px 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu {
        display: none;
        opacity: inherit !important;
        visibility: inherit !important;
        left: inherit !important;
        width: 100%;
        top: 100%;
        max-height: 350px;
        overflow: auto;
        transition: unset;
        box-shadow: inherit;
        position: inherit;
        padding: 0 20px 0px 60px;
    }
}

.categories_content_toggle > ul > li ul.cate_mega_menu.open {
    display: block;
    left: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu > li {
        padding: 0;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu > li {
        padding: 0;
    }
}

.categories_content_toggle > ul > li ul.cate_mega_menu > li > a {
    line-height: 30px;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
}

.categories_content_toggle > ul > li ul.cate_mega_menu > li > a:hover {
    color: var(--a-hover);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu > li > a {
        line-height: 38px;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu > li > a {
        line-height: 38px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu > li.menu_item_content > a {
        text-transform: capitalize;
        font-weight: 400;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle > ul > li ul.cate_mega_menu > li.menu_item_content > a {
        text-transform: capitalize;
        font-weight: 400;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle ul li.menu_item_content > a {
        position: relative;
    }

    .categories_content_toggle ul li.menu_item_content > a::before {
        position: absolute;
        content: "+";
        right: 15px;
        top: 50%;
        transform: translatey(-50%);
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle ul li.menu_item_content > a {
        position: relative;
    }

    .categories_content_toggle ul li.menu_item_content > a::before {
        position: absolute;
        content: "+";
        right: 15px;
        top: 50%;
        transform: translatey(-50%);
        font-size: 1.25rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle ul li.menu_item_content.open > a::before {
        display: none;
    }

    .categories_content_toggle ul li.menu_item_content.open > a::after {
        position: absolute;
        content: "-";
        right: 15px;
        top: 50%;
        transform: translatey(-50%);
        font-size: 1.875rem;
    }
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle ul li.menu_item_content.open > a::before {
        display: none;
    }

    .categories_content_toggle ul li.menu_item_content.open > a::after {
        position: absolute;
        content: "-";
        right: 15px;
        top: 50%;
        transform: translatey(-50%);
        font-size: 1.875rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cate_sub_menu {
        display: none;
        padding: 0 0 0 30px;
    }
}

@media only screen and (max-width: 767px) {
    .cate_sub_menu {
        display: none;
        padding: 0 0 0 30px;
    }
}

.cate_sub_menu li a {
    text-transform: capitalize;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 30px;
}

.cate_sub_menu li a:hover {
    color: var(--a-hover);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cate_sub_menu li a {
        line-height: 38px;
    }
}

@media only screen and (max-width: 767px) {
    .cate_sub_menu li a {
        line-height: 38px;
    }
}

.categories_content_toggle ul li ul.cate_mega_menu.column_2 {
    width: 400px;
}

@media only screen and (max-width: 767px) {
    .categories_content_toggle ul li ul.cate_mega_menu.column_2 {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_content_toggle ul li ul.cate_mega_menu.column_2 {
        width: 100%;
    }
}

.categorie_sub {
    opacity: 0;
    z-index: 999;
    position: absolute;
    width: 100%;
    left: 0;
    background: #ffffff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    top: 65%;
    visibility: hidden;
}

.categorie_sub.open {
    top: 95%;
    opacity: 1;
    visibility: visible;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.11);
}

.categories_content_toggle ul li.has-sub > a::before {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 1.125rem;
    pointer-events: none;
}

.categories_content_toggle ul li.has-sub > a.open::before {
    content: "\f056";
}

.categories_content_toggle ul li.has-sub ul.categorie_sub li a {
    text-transform: capitalize;
    font-size: 0.875rem;
    font-weight: 400;
    padding-left: 30px;
    display: block;
    line-height: 45px;
}

.categories_content_toggle ul li.has-sub ul.categorie_sub li a:hover {
    color: var(--a-hover);
}

.categories_content_toggle ul li ul.cate_mega_menu > li:first-child {
    padding-left: 0;
}

.categories_content_toggle ul li ul.cate_mega_menu > li:last-child {
    padding-right: 0;
}

.categorie_banner {
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 500px;
}

@media only screen and (max-width: 767px) {
    .categorie_banner {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categorie_banner {
        display: none;
    }
}

.categorie_banner a img:hover {
    opacity: 0.8;
}

.connect-with-us {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .connect-with-us {
        display: none;
        text-align: center;
        margin-bottom: 22px;
    }
}

@media only screen and (max-width: 767px) {
    .connect-with-us {
        display: none;
        text-align: center;
        margin-bottom: 22px;
    }
}

.connect-with-us p {
    font-size: 0.813rem;
    line-height: 18px;
    display: inline-block;
    position: relative;
}

.connect-with-us > em {
    font-size: 2rem;
    color: var(--buttons);
    padding-right: 10px;
}

.connect-with-us p a {
    display: block;
    font-size: 0.938rem;
}

.connect-with-us p a:hover {
    color: var(--a-hover);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .connect-with-us p {
        font-size: 0.813rem;
    }
}

@media only screen and (max-width: 767px) {
    .connect-with-us p {
        font-size: 0.813rem;
    }
}

.top_bar_left {
    list-style: none;
    padding: 0;
    font-size: 1rem;
}

.mini_cart_close {
    top: 10px;
    position: absolute;
}

/* slider */
.main-slider-sec {
    position: relative;
}

.main-slider-sec i {
    color: #fff;
}

.inner-slider-img img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}



.main-slider-sec .nav-style .owl-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 1.25rem;
}

.main-slider-sec .nav-style .owl-nav .owl-prev {
    margin-bottom: 62px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.main-slider-sec .nav-style .owl-nav .owl-prev:hover {
    color: var(--buttons);
}

@media only screen and (max-width: 767px) {
    .main-slider-sec .nav-style .owl-nav .owl-prev {
        margin-bottom: 35px;
    }
}

.main-slider-sec .nav-style .owl-nav .owl-next {
    margin-top: 63px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.main-slider-sec .nav-style .owl-nav .owl-next:hover {
    color: var(--buttons) 1;
}

@media only screen and (max-width: 767px) {
    .main-slider-sec .nav-style .owl-nav .owl-next {
        margin-top: 35px;
    }
}

.main-slider-sec .nav-style.nav-dot-left .owl-nav {
    left: 50px;
}

@media only screen and (max-width: 767px) {
    .main-slider-sec .nav-style.nav-dot-left .owl-nav {
        left: 15px;
    }
}

.main-slider-sec .dot-style .owl-dots {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 50px;
    font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {
    .main-slider-sec .dot-style .owl-dots {
        left: 15px;
    }
}

.main-slider-sec .dot-style .owl-dots .owl-dot {
    display: none;
    font-size: 1.25rem;
}

.main-slider-sec .dot-style .owl-dots .owl-dot.active {
    display: block;
    color: var(--buttons);
}

.main-slider-sec .dot-style .owl-dots.nav-dot-left .owl-dots {
    left: 50px;
}

.slider-content {
    margin-top: 65px;
}

.slider-content h3 {
    font-size: 5rem;
    color: var(--buttons);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content h3 {
        font-size: 4.375rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content h3 {
        font-size: 4.063rem;
    }
}

@media only screen and (max-width: 767px) {
    .slider-content h3 {
        font-size: 3.125rem;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-content h3 {
        font-size: 2.813rem;
    }
}

.slider-content h1 {
    font-size: 4.375rem;
    font-weight: bold;
    margin: 29px 0 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content h1 {
        font-size: 3.438rem;
        margin: 20px 0 14px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content h1 {
        font-size: 3.125rem;
        margin: 10px 0 14px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-content h1 {
        font-size: 2.5rem;
        margin: 16px 0 10px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-content h1 {
        font-size: 2rem;
        margin: 10px 0 10px;
    }
}

.slider-content p {
    font-size: 1.375rem;
    margin: 0px 0 44px;
    font-family: "Poppins", sans-serif;
    color: #010225;
    letter-spacing: 0.2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-content p {
        font-size: 1.375rem;
    }
}

@media only screen and (max-width: 767px) {
    .slider-content p {
        font-size: 1.125rem;
        margin: 0px 0 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-content.ml-70 {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .slider-content {
        margin-top: 35px;
        text-align: center;
    }

    .slider-content.ml-70 {
        margin-left: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-content {
        margin-top: 70px;
        text-align: left;
    }
}

.inner-slider-img {
    margin-left: -205px;
    margin-right: -35px;
    margin-top: 140px;
    width: 700px;
    height: 66vh;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .inner-slider-img {
        margin-left: -80px;
        margin-right: 0px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .inner-slider-img {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .inner-slider-img {
        margin-left: -30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .inner-slider-img {
        margin-left: -40px;
        margin-right: 0;
        margin-top: 95px;
    }
}

@media only screen and (max-width: 767px) {
    .inner-slider-img {
        margin-left: 0px;
        margin-right: 0;
        margin-top: 130px;
    }
}

.owl-item .slider-content * {
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-item.active .slider-animated-content h3 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.owl-item.active .slider-animated-content h5 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.owl-item.active .slider-animated-content h1 {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.owl-item.active .slider-animated-content img {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.owl-item.active .slider-animated-content p {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.owl-item.active .slider-animated-content a {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.owl-item.active .slider-animated-content.inner-slider-img img {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-content.slider-sin-img-hm9 img {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

.main-slider-sec :is(div > .btn-hover > a.norm-btn):hover {
    background-color: #222222;
    color: #fff;
}

/* shipping area */
.shipping-content {
    background: #f6f5f3;
}

.shipping_inner_content {
    padding: 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .shipping_inner_content {
        padding: 75px 0 53px;
    }
}

@media only screen and (max-width: 767px) {
    .shipping_inner_content {
        padding: 56px 0 32px;
    }
}

.single_shipping_content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_shipping_content {
        margin-bottom: 22px;
    }
}

@media only screen and (max-width: 767px) {
    .single_shipping_content {
        margin-bottom: 25px;
        justify-content: start;
    }
}

.shipping_icon {
    margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shipping_icon {
        margin-right: 6px;
    }
}

.shipping_icon em {
    font-size: 2.625rem;
    color: var(--buttons);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shipping_icon em {
        font-size: 1.875rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .shipping_icon em {
        font-size: 1.875rem;
    }
}

@media only screen and (max-width: 767px) {
    .shipping_icon em {
        font-size: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 26px;
        height: 26px;
        max-width: 100%;
        object-fit: contain;
    }
}

.shipping_content h2 {
    font-size: 0.938rem;
    line-height: 20px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shipping_content h2 {
        font-size: 0.813rem;
    }
}

.shipping_content p {
    line-height: 24px;
    text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shipping_content p {
        font-size: 0.75rem;
    }
}

/* trending products */

.trending_content .product_left_bar .product_thumb {
    height: 200px;
}

.trending_content .product_left_bar .product_carousel_content .product_thumb a img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    width: auto;
    margin: auto;
}

.trending_content .product_right_bar .product_thumb {
    height: 200px;
    /* border: 1px solid red; */
    width: 200px;
    overflow: hidden;
    margin: 15px auto;
    display: block;
    position: relative;
}

.trending_content .product_right_bar .product_carousel_content .product_thumb a img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.trending_content .product_left_bar .trending-banner {
    height: 220px;
}

@media (max-width: 1400px) {
    .trending_content .product_left_bar .trending-banner {
        height: auto;
    }
}

.trending_content .product_left_bar .trending-banner img {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
}

.trending .trending_content {
    background: #fff;
    padding: 50px 0;
}

.product_area .deals_title {
    background: var(--buttons);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.product_area .addto_cart_btn a {
    background: var(--buttons);
}

@media only screen and (max-width: 767px) {
    .product_left_bar {
        margin-bottom: 60px;
    }
}

.product_left_bar .banner_thumb {
    margin-top: 30px;
}

.product_left_bar .banner_thumb img {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .product_left_bar .banner_thumb img {
        width: 100%;
    }
}

.product_left_bar .banner-image {
    margin: 15px auto;
    position: relative;
}

.deals_title h2 {
    margin-bottom: 0;
    font-size: 1.5rem;
    color: #fff;
    display: block;
    text-align: center;
    line-height: 50px;
}

.deals_product_inner {
    padding: 20px 20px 30px;
    background: #fff;
    border-left: 0.1em solid var(--buttons);
    border-right: 0.1em solid var(--buttons);
    border-bottom: 0.1em solid var(--buttons);
}

.deals_product_inner .product_timing {
    position: inherit;
    margin-top: 26px;
}

@media only screen and (max-width: 767px) {
    .deals_product_inner .product_timing {
        margin-top: 20px;
    }
}

.deals_product_inner .single_countdown {
    padding: 10px 11px;
    margin-right: 10px;
    border-radius: 3px;
}

@media only screen and (max-width: 767px) {
    .deals_product_inner .single_countdown {
        padding: 7px 6px;
        margin-right: 5px;
    }
}

.deals_product_inner .addto_cart_btn {
    margin-top: 32px;
}

@media only screen and (max-width: 767px) {
    .deals_product_inner .addto_cart_btn {
        margin-top: 24px;
    }
}

.deals_product_inner .addto_cart_btn a {
    font-size: 0.938rem;
    line-height: 44px;
    height: 44px;
    text-transform: uppercase;
    padding: 0 20px;
    display: inline-block;
    border-radius: 10px;
    background: var(--buttons);
    color: #fff;
}

.deals_product_inner .addto_cart_btn a:hover {
    background: #222222;
}

@media only screen and (max-width: 767px) {
    .deals_product_inner .addto_cart_btn a {
        font-size: 0.875rem;
        line-height: 38px;
        height: 38px;
        padding: 0 15px;
    }
}

.product_carousel_content .product_thumb a img {
    width: auto;
}

@media only screen and (max-width: 767px) {
    .product_carousel_content .product_thumb a img {
        width: auto;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .product_carousel_content:hover .owl-nav div {
        left: -20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .product_carousel_content:hover .owl-nav div {
        left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product_carousel_content:hover .owl-nav div {
        left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .product_carousel_content:hover .owl-nav div {
        left: 0;
    }
}

.product_carousel_content:hover .owl-nav div.owl-next {
    right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .product_carousel_content:hover .owl-nav div.owl-next {
        right: -20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .product_carousel_content:hover .owl-nav div.owl-next {
        right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product_carousel_content:hover .owl-nav div.owl-next {
        right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .product_carousel_content:hover .owl-nav div.owl-next {
        right: 0;
    }
}

.product_carousel_content .owl-nav.disabled {
    display: block;
}

.product_carousel_content .owl-nav div {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    font-size: 2.25rem;
    left: 0;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
}

.product_carousel_content .owl-nav div:hover {
    color: var(--buttons);
}

.product_right_bar .single_product > figure {
    /* border: 1px solid #1b0b0e; */
    /* padding: 0.438rem 0; */
}

.product_right_bar .single_product > figure:hover {
    -webkit-box-shadow: 0 4px 15px rgb(153 153 153 / 30%);
    box-shadow: 0 4px 15px rgb(153 153 153 / 30%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.single_product:hover .action_links {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}

.single_product:hover .action_links ul li a {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.single_product:hover .product_thumb a.secondary_img {
    opacity: 1;
    visibility: visible;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out;
}

.single_product:hover .product_timing {
    bottom: 76px;
}

.product_thumb {
    position: relative;
    overflow: hidden;
}

.product_thumb a.secondary_img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out;
}

.product_thumb a img {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .product_thumb a img {
        width: 100%;
    }
}

.action_links {
    transition: all 0.3s ease;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    background: #ffffff;
    padding: 4px 7px;
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}

.action_links ul {
    display: flex;
}

.action_links ul li {
    margin-right: 4px;
}

.action_links ul li:last-child {
    margin-right: 0;
}

.action_links ul li a {
    font-size: 1rem;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #301919;
    border-radius: 50%;
}

.action_links ul li a:hover {
    background: var(--buttons);
    color: #fff !important;
}

.action_links ul li:nth-child(3) a::after {
    visibility: hidden;
    display: none;
}

.label_product span {
    position: absolute;
}

.label_product span.label_sale {
    top: 20px;
    left: 0px;
    text-transform: uppercase;
    color: #ffffff;
    background: var(--cart-bagde);
    font-size: 0.75rem;
    height: 20px;
    line-height: 20px;
    width: 100px;
    text-align: center;
    display: block;
    border-radius: 5px;
    z-index: 1;
}

.label_product span.label_sale::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: var(--cart-bagde) transparent transparent transparent;
    left: 8px;
    top: 100%;
    position: absolute;
}

.label_product span.label_discount {
    top: 50px;
    left: 20px;
    text-transform: uppercase;
    color: #ffffff;
    background: var(--buttons);
    font-size: 0.75rem;
    height: 20px;
    line-height: 20px;
    width: 40px;
    text-align: center;
    display: block;
    border-radius: 5px;
}

.label_product span.label_discount::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: var(--buttons) transparent transparent transparent;
    left: 8px;
    top: 100%;
    position: absolute;
}

.label_product span.label_new {
    top: 20px;
    right: 20px;
    text-transform: uppercase;
    color: #ffffff;
    background: var(--buttons);
    font-size: 0.75rem;
    height: 20px;
    line-height: 20px;
    width: 40px;
    text-align: center;
    display: block;
    border-radius: 5px;
}

.label_product span.label_new::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: var(--buttons) transparent transparent transparent;
    left: 8px;
    top: 100%;
    position: absolute;
}

.product-details-tab {
    position: relative;
}

.product-details-tab .label_product span.label_sale {
    top: 20px;
    left: 20px;
}

.discount-product {
    display: inline-block;
    vertical-align: top;
    color: var(--button-text);
    background: var(--buttons);
    padding: 2px 5px;
    border-radius: 5px;
    text-align: center;
    line-height: 20px;
    height: 21px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: capitalize;
    z-index: 4;
    margin-left: 2px;
}

.product_content {
    margin-top: 16px;
    text-align: center;
    /* background-color: white; */
}

.product_reviews {
    display: block;
    line-height: 1;
    position: relative;
    margin: 10px auto;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.product_reviews > .comment_note {
    position: relative;
}

.product_reviews > .comment_note > .star_content {
    display: inline-block;
}

.product_reviews > .comment_note > .star_content em {
    color: #fdd835;
}

@media only screen and (max-width: 767px) {
    .product_content {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .product_content h4 {
        margin-bottom: 14px;
    }
}

.product_content h4 a:hover {
    color: var(--buttons);
}

.product_content p {
    font-size: 0.875rem;
    line-height: 12px;
    margin-bottom: 0;
}

.product_content p a:hover {
    color: var(--buttons);
}

.price_box {
    margin-top: 12px;
    margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
    .price_box {
        margin-top: 9px;
    }
}

.price_box span {
    line-height: 16px;
}

.price_box span.current_price {
    font-weight: 600;
    font-size: 1.063rem;
}

.product_carousel_content .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
}

.product_carousel_content:hover .owl-nav div {
    opacity: 1;
    visibility: visible;
    left: -30px;
    width: 34px;
    height: 34px;
    line-height: 32px;
    border: 1px solid #ebebeb;
    background: #fff;
    z-index: 1;
    color: #888;
    border-radius: 100%;
    text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .product_carousel_content:hover .owl-nav div {
        left: -20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .product_carousel_content:hover .owl-nav div {
        left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product_carousel_content:hover .owl-nav div {
        left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .product_carousel_content:hover .owl-nav div {
        left: 0;
    }
}

.product_carousel_content .owl-nav div.owl-next {
    right: 0;
    left: auto;
}

.product_items .single_product {
    margin-bottom: 15px;
    position: relative;
}

.product_items .single_product:last-child {
    margin-bottom: 0;
}

.single_countdown {
    display: inline-block;
    padding: 10px 10px;
    border: 1px solid #ededed;
    margin-right: 10px;
    background: #fff;
}

.single_countdown:last-child {
    margin-right: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .single_countdown {
        padding: 10px 8px;
        margin-right: 5px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_countdown {
        padding: 5px 8px;
        margin-right: 6px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_countdown {
        padding: 5px 8px;
        margin-right: 6px;
    }
}

@media only screen and (max-width: 767px) {
    .single_countdown {
        padding: 5px 8px;
        margin-right: 6px;
    }
}

.countdown_number {
    font-size: 1.063rem;
    line-height: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .countdown_number {
        font-size: 1.063rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .countdown_number {
        font-size: 1.125rem;
    }
}

@media only screen and (max-width: 767px) {
    .countdown_number {
        font-size: 1.125rem;
    }
}

.countdown_title {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .countdown_title {
        font-size: 0.688rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .countdown_title {
        font-size: 0.688rem;
    }
}

@media only screen and (max-width: 767px) {
    .countdown_title {
        font-size: 0.688rem;
    }
}

.product_timing {
    transition: all 0.3s ease;
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 9;
    right: 0;
    text-align: center;
}

.product_right_bar .section_title {
    margin-bottom: 23px;
    justify-content: space-between;
}

.product_right_bar .section_title h2 {
    font-size: 1.5rem;
    line-height: 26px;
    margin: 0;
    position: relative;
    text-transform: capitalize;
    font-weight: var(--font-weight);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_right_bar .desc_title {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    margin-top: 10px;
}

.product_right_bar .product_carousel_content .owl-nav div {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #888;
    top: 50%;
    width: 34px;
    left: auto;
    line-height: 40px;
    right: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    background: #fff;
    border-radius: 50em;
    box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
    font-size: 15px;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    border-radius: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.product_right_bar .product_carousel_content .owl-nav div.owl-next {
    right: -18px;
}

.product_right_bar .product_carousel_content .owl-nav div.owl-prev {
    left: -18px;
}

@media (max-width: 767px) {
    .product_right_bar .product_carousel_content .owl-nav div.owl-next {
        right: -6px;
    }

    .product_right_bar .product_carousel_content .owl-nav div.owl-prev {
        left: -6px;
    }
}


.product_container {
    background: #fff;
    border-radius: 10px;
}

.product_right_bar .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.product_right_bar .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.product_left_bar .banner-image:hover::before {
    left: 0;
    right: 0;
    opacity: 0;
    transition: all 900ms linear;
}

.product_left_bar .banner-image:hover::after {
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: all 900ms linear;
}

.product_left_bar .banner-image::before {
    background: rgba(255, 255, 255, 0.3);
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    right: 51%;
    top: 0;
    opacity: 1;
    pointer-events: none;
}

.product_left_bar .banner-image::after {
    background: rgba(255, 255, 255, 0.6);
    bottom: 50%;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 1;
    pointer-events: none;
}

.product_left_bar .banner-image img {
    transition: 0.3s;
}

/* popular categories */

.popular_title {
    position: relative;
    justify-content: space-between;
}

.popular_title h2 {
    margin: 0;
    position: relative;
    text-transform: capitalize;
    font-weight: var(--font-weight);
    line-height: 1;
    display: block;
    font-size: 1.5rem;
}

.pop_desc_title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    margin-top: 10px;
}

.popular_content {
    position: relative;
}

.popular-categories .pop_list-categories {
    position: relative;
}

.popular-categories .pop_list-categories .pop_thumb-category {
    position: relative;
    overflow: hidden;
}
/* rounded */
.pop_item-listcategories-rounded {
    position: relative;
    padding: 10px;
    margin-bottom: 5px;
    overflow: hidden;
    background: var(--pop-linear-background);
}
.pop_thumb-category-rounded {
    height: 150px;
    width: 150px;
    object-fit: contain;
    float: right;
}
.pop_thumb-category-rounded img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.pop_item-listcategories-rounded .pop_desc_listcat {
    position: absolute;
    left: 40px;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pop_item-listcategories-rounded .pop_desc_listcat .name_categories h4 {
    font-size: 1.125rem;
    font-weight: var(--font-weight);
    text-transform: capitalize;
    margin-bottom: 15px;
    line-height: 1;
}

.pop_item-listcategories-rounded .pop_desc_listcat .number_product {
    font-size: 0.875rem;
    text-transform: capitalize;
    margin-bottom: 10px;
    line-height: 1;
}

.pop_item-listcategories-rounded .pop_desc_listcat .view-more a {
    color: #253237;
    font-size: 0.875rem;
    text-transform: capitalize;
    line-height: 1;
    position: relative;
    display: inline-block;
}
.pop_item-listcategories-rounded .pop_desc_listcat .view-more a em {
    color: var(--buttons);
}
@media (max-width: 1400px) {
    .popular-categories .pop_list-categories .pop_thumb-category {
        height: auto;
    }
}

.popular-categories .pop_list-categories .pop_thumb-category:hover::before {
    left: 0;
    right: 0;
    opacity: 0;
    transition: all 900ms linear;
}

.popular-categories .pop_list-categories .pop_thumb-category:hover::after {
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: all 900ms linear;
}

.popular-categories .pop_list-categories .pop_thumb-category::before {
    background: rgba(255, 255, 255, 0.3);
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    right: 51%;
    top: 0;
    opacity: 1;
    pointer-events: none;
}

.popular-categories .pop_list-categories .pop_thumb-category::after {
    background: rgba(255, 255, 255, 0.6);
    bottom: 50%;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 1;
    pointer-events: none;
}

.popular-categories .pop_list-categories .pop_thumb-category img {
    width: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    max-width: 100%;
    max-height: 100%;
    height: 170px;
}

.popular-categories .pop_list-categories .pop_desc_listcat {
    position: absolute;
    left: 20px;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: var(--pop-text);
}

.popular-categories .pop_list-categories .pop_desc_listcat .name_categories h4 {
    font-size: 1.125rem;
    font-weight: var(--font-weight);
    text-transform: capitalize;
    margin-bottom: 15px;
    line-height: 1;
}

.popular-categories .pop_list-categories .pop_desc_listcat .number_product {
    font-size: 0.875rem;
    text-transform: capitalize;
    margin-bottom: 10px;
    line-height: 1;
}

.popular-categories .pop_list-categories .pop_desc_listcat .view-more a {
    font-size: 0.875rem;
    text-transform: capitalize;
    line-height: 1;
    position: relative;
    display: inline-block;
}
.popular-categories .pop_list-categories .pop_desc_listcat .view-more a em {
    color: var(--buttons);
}
.pop .popular-categories .owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.popular-categories .owl-nav > div.owl-prev {
    left: -18px;
}

.popular-categories .owl-nav > div {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #888;
    top: 35%;
    width: 34px;
    left: auto;
    font-size: 0.875rem;
    line-height: 40px;
    right: 0px;
    width: 40px;
    height: 40px;
    padding: 0;
    background: #fff;
    border-radius: 50em;
    box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
    font-size: 15px;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    border-radius: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.popular-categories .owl-nav > div:before {
    font-size: 0.875rem;
    line-height: 32px;
    display: block;
}

.popular-categories .owl-nav > div.owl-next {
    right: -18px;
}

.popular-categories .owl-dots {
    text-align: center;
    line-height: 1;
    margin-top: 20px;
}

.popular-categories .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.popular-categories .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    width: 100%;
}

/* advertise-banner */
.home-banner {
    position: relative;
    display: inline-block;
    width: 100%;
}
.banner_box_content
{
    padding: 6px !important;
}

.home-banner.banner-sec-1 .banner_box_content {
    height: 300px;
}

.home-banner.banner-sec-1 .banner_box_content img {
    height: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

@media (max-width: 1400px) {
    .home-banner.banner-sec-1 .banner_box_content {
        height: auto;
    }
}

@media (max-width: 991px) {
    .home-banner {
        margin-top: 30px;
        margin-bottom: 0px;
    }
}

.home-banner .banner_box_content {
    position: relative;
    overflow: hidden;
}

.home-banner .banner_box_content img {
    width: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    transition: 0.3s;
}

.home-banner .banner_box_content:hover::before {
    left: 0;
    right: 0;
    opacity: 0;
    transition: all 900ms linear;
}

.home-banner .banner_box_content:hover::after {
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: all 900ms linear;
}

.home-banner .banner_box_content::before {
    background: rgba(255, 255, 255, 0.3);
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    right: 51%;
    top: 0;
    opacity: 1;
    pointer-events: none;
}

.home-banner .banner_box_content::after {
    background: rgba(255, 255, 255, 0.6);
    bottom: 50%;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 1;
    pointer-events: none;
}

/* new-arrival */
.new-arrival .product_thumb {
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin: 15px auto;
    /* border: 1px solid red; */
    display: block;
    position: relative;
}

.new-arrival .product_thumb img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

/* big advertise */

.home-banner.banner-sec-2 .banner_box_content {
    *height: 160px;
}

.home-banner.banner-sec-2 .banner_box_content img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}

@media (max-width: 1400px) {
    .home-banner.banner-sec-2 .banner_box_content {
        height: auto;
        margin: auto;
    }
}

/* featured product */

.featured_product .product_thumb {
    /* height: 120px;
    width: 120px; */
}

.featured_product .product_thumb img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}

.featured_product .section_title {
    justify-content: space-between;
}

.featured_product .section_title h2 {
    margin-bottom: 0px;
    font-size: 1.5rem;
    font-weight: var(--font-weight);
}

.featured_product_area .single_product {
    overflow: hidden;
    border: 1px solid #1b0b0e;
}

.featured_product_area .label_product span.label_sale {
    top: inherit;
}

@media only screen and (max-width: 767px) {
    .featured_product_area .single_product:hover .action_links {
        bottom: -11px;
    }
}

.featured_product_area .single_product:hover .price_box {
    opacity: 0;
}

.featured_product_area .product_thumb {
    width: 120px;
    float: left;
}

@media (max-width: 991px) {
    .featured_product_area .product_thumb {
        height: 108px;
    }
}

@media (max-width: 767px) {
    .featured_product_area .product_thumb {
        height: 92px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .featured_product_area .product_thumb {
        width: 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .featured_product_area .product_thumb {
        width: 108px;
    }
}

@media only screen and (max-width: 767px) {
    .featured_product_area .product_thumb {
        width: 92px;
    }
}

.featured_product_area .product_content {
    position: relative;
    width: calc(100% - 120px);
    float: left;
    text-align: left;
    margin-top: 15px;
    padding-left: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .featured_product_area .product_content {
        width: calc(100% - 110px);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .featured_product_area .product_content {
        width: calc(100% - 108px);
    }
}

@media only screen and (max-width: 767px) {
    .featured_product_area .product_content {
        width: calc(100% - 92px);
        margin-top: 5px;
    }
}

.featured_product_area .product_content h4 {
    margin-bottom: 18px;
}

@media only screen and (max-width: 767px) {
    .featured_product_area .product_content h4 {
        margin-bottom: 14px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .featured_product_area .product_items .single_product {
        margin-bottom: 23px;
    }

    .featured_product_area .product_items .single_product:last-child {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .featured_product_area .product_items .single_product {
        margin-bottom: 23px;
    }

    .featured_product_area .product_items .single_product:last-child {
        margin-bottom: 0;
    }
}

.featured_product_area .action_links {
    left: 13px;
    transform: inherit;
    bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .featured_product_area .action_links {
        padding: 2px 5px;
    }
}

.featured_product_area .price_box {
    margin-top: 15px;
    transition: 0.3s;
}

@media only screen and (max-width: 767px) {
    .featured_product_area .price_box {
        margin-top: 12px;
    }
}

.product_carousel .product_thumb a img {
    width: 100%;
    margin: 0 auto;
}

.featured_product .owl-nav {
    display: block !important;
}

.featured_product .owl-nav div {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #888;
    top: 40%;
    width: 34px;
    left: auto;
    line-height: 40px;
    right: 0px;
    width: 40px;
    height: 40px;
    padding: 0;
    background: #fff;
    border-radius: 50em;
    box-shadow: 0 0 1px 1px rgb(20 23 28 / 10%), 0 3px 1px 0 rgb(20 23 28 / 10%);
    z-index: 1;
    opacity: 1;
    visibility: visible;
    border-radius: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.featured_product .owl-nav div.owl-prev {
    left: -18px;
}

.featured_product .owl-nav div.owl-next {
    right: -18px;
}

/* subscribe newsletter */
.newsletter {
    background: var(--footer-back);
    padding-right: 15px;
    padding-left: 15px;
}

.newsletter-bg {
    padding: 40px 0;
}

.newsletter-icon {
    max-width: 70px;
    margin-right: 10px;
}

@media (max-width: 991px) {
    .newsletter-icon {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.newsletter-title {
    color: #ffffff;
}

@media (max-width: 991px) {
    .newsletter-title {
        display: inline-block;
        margin-top: 0;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1769px) {
    .newsletter-inner .main_title {
        font-size: 1.125rem;
        margin-top: 0;
        line-height: 20px;
    }
}

.newsletter-title .main_title {
    color: #ffffff;
    font-size: 1.563rem;
    letter-spacing: 0;
}

.main_title {
    display: inline-block;
    margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1769px) {
    .newsletter-title .sub-title {
        font-size: 0.938rem;
    }
}

.newsletter-title .sub-title {
    font-size: 1.125rem;
}

.newsletter-box {
    position: relative;
}

.newsletter-inner input {
    background: #f5f5f5;
    border: none;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -o-border-radius: 25px;
    padding: 11px 0 15px 20px;
    width: 100%;
}

@media (max-width: 991px) {
    .newsletter-inner input {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .newsletter-box {
        margin-top: 15px;
    }
}

.newsletter .newsletter-inner form button {
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    text-transform: uppercase;
    color: var(--button-text);
    font-weight: var(--font-weight);
}

.newsletter .btn-color {
    background: var(--buttons);
}

.newsletter .btn,
.newsletter button {
    padding: 13px 20px;
    font-size: 1rem;
    line-height: 21px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -o-border-radius: 25px;
    background-color: var(--buttons);
}

.newsletter .newsletter-icon > em {
    color: var(--buttons);
}

/* footer */
.footer,
.footer a,
.footer p {
    color: #fff;
}

.footer {
    background: var(--footer-back);
    padding-bottom: 1px;
    padding: 1px 15px;
}

.footer > div > hr {
    margin: 0;
    border-top: 1px solid #073b4c;
}

.footer-middle {
    padding: 3.125rem 0;
}

.footer-middle .f-logo {
    margin-bottom: 20px;
    margin-top: 20px;
}

.footer-middle .f-logo img {
    max-width: 200px;
    height: 50px;
}

.footer-inner hr {
    border-top: 1px solid #073b4c;
}

.app_button img {
    height: 50px;
    padding-bottom: 10px;
}

.footer .company__desc {
    text-align: justify;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 273px;
}

.footer .company__address {
    text-align: justify;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 273px;
}

@media (max-width: 991px) {
    .footer .footer-inner .col {
        flex: 100%;
    }
}

@media (min-width: 992px) {
    .footer-static-block .footer-block-contant {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .footer-static-block .footer-block-contant {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-block {
        display: block !important;
    }
}

.footer-static-block .title {
    font-size: 1.25rem;
    font-weight: var(--font-weight);
    letter-spacing: 1px;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
}

.footer .title > span {
    width: 50px;
    margin-top: 15px;
}

.footer-respond p {
    line-height: 2;
}

.footer-static-block ul li:first-child {
    padding-top: 0;
}

.footer-static-block ul li {
    padding: 6px 0;
    text-transform: capitalize;
}

.footer-static-block li em.fas {
    float: left;
    font-size: 1rem;
    color: var(--buttons);
    margin-top: 5px;
}

.footer-static-block li p {
    margin-left: 25px;
    margin-bottom: 15px;
}
.footer-static-block .title
{
    color: white;
}

.footer-static-block .link li a
{
    color: white;
}

.copy-right {
    text-align: center;
}

@media (max-width: 991px) {
    .footer_social .social-icon {
        justify-content: center;
        display: flex;
    }
}

ul.social-icon li {
    display: inline-block;
}

ul.social-icon li em.fab {
    color: var(--buttons);
    font-size: 1.125rem;
    padding: 0 10px;
    text-align: center;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}

.footer-bottom .site-link li {
    display: inline-block;
}

.payment {
    text-align: end;
}

.footer-bottom .site-link li:first-child a {
    padding-left: 0;
}

.footer-bottom .site-link li a {
    padding: 0 10px;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .copy-right {
        margin: 10px 0;
        text-align: center;
    }
}

.copy-right a {
    color: var(--buttons);
}

@media (max-width: 991px) {
    .center-sm {
        text-align: center;
    }
}

@media (max-width: 1199px) {
    .site-link,
    .payment {
        text-align: center;
    }
}

@media (max-width: 991px) {
    .footer-static-block {
        position: relative;
        margin-bottom: 10px;
        border-bottom: 1px solid #073b4c;
    }
}

.footer .footer-static-block h3 > em {
    display: none;
}

@media (max-width: 991px) {
    .footer .footer-static-block h3 > em {
        display: inline;
        float: right;
        color: var(--buttons);
        margin-top: -30px;
    }

    .footer-respond .logo-desc {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (min-width: 991px) {
    .footer .collapse.dont-collapse-sm {
        display: block !important;
        height: auto !important;
        visibility: visible !important;
    }
}

@media (max-width: 991px) {
    .footer-static-block .footer-block-contant {
        display: none;
        margin-bottom: 20px;
    }
}

.footer .payment_icon ul > li {
    display: inline-block;
}

.footer .payment_icon ul > li img {
    width: 35px;
    height: 35px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* product-details */

.product__details .top_bar_left button {
    margin-left: 0;
}
.product-container {
    background: #ffffff;
    padding: 30px;
}

.product-details-tab .zoomWrapper img {
    height: auto;
    width: auto;
    object-fit: cover;
    margin: auto;
    display: block;
}

.product-detail-sec .owl-nav.disabled {
    display: block;
}

.product-detail-sec .home-banner .banner_box_content {
    height: 30vh;
}

.product-detail-sec .home-banner .banner_box_content img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.product_details_right .productdetails2 .s-tab-zoom.owl-carousel .owl-nav {
    display: block;
}

.s-tab-zoom.owl-carousel .owl-nav div {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    width: 32px;
    height: 32px;
    border: 1px solid #e5e5e5;
    background: #ffffff;
    border-radius: 100%;
    text-align: center;
    line-height: 30px;
    left: -7px;
    font-size: 1.125rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
}

.s-tab-zoom.owl-carousel .owl-nav div:hover {
    background: var(--buttons);
    border-color: var(--buttons);
    color: #ffffff;
}

.s-tab-zoom.owl-carousel .owl-nav div.owl-next {
    right: -7px;
    left: auto;
}
.product-detail-sec .policycontent {
    display: flex;
    margin-top: 15px;
}
.product-detail-sec .returnpolicy {
    align-items: center;
    border: 1px solid #f2f3f8;
    text-align: center;
    padding: 15px;
    margin-right: 10px;
}
.product-detail-sec .seller_name p a {
    font-size: 1rem;
}
.product-detail-sec .productcard .creativity img {
    height: 50px;
}
@media only screen and (max-width: 767px) {
    .product-details-tab {
        margin-bottom: 57px;
    }
}

.product-details-tab:hover .s-tab-zoom.owl-carousel .owl-nav div {
    opacity: 1;
    visibility: visible;
}

.single-zoom-thumb {
    width: 100%;
    margin: 20px auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-zoom-thumb {
        width: 85%;
    }
}

@media only screen and (max-width: 767px) {
    .single-zoom-thumb {
        width: 101%;
    }
}

.single-zoom-thumb ul li {
    border: 1px solid #ddd;
}

.product-detail-sec .single-zoom-thumb ul li a img {
    height: 100px;
    object-fit: contain;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0.625rem;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .product-detail-sec .single-zoom-thumb ul li a img {
        height: 80px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .product-detail-sec .single-zoom-thumb ul li a img {
        height: 100px;
    }
}

@media (max-width: 479px) {
    .product-detail-sec .single-zoom-thumb ul li a img {
        height: 70px;
    }
}

.quick_view .single-zoom-thumb ul li {
    margin-right: 5px;
}

.quick_view .single-zoom-thumb ul li a img {
    height: 100px;
    object-fit: contain;
    padding: 10px;
}

@media (max-width: 767px) {
    .quick_view .single-zoom-thumb ul li a img {
        height: 4.688rem;
    }
}

.single-zoom-thumb ul li a {
    width: 100%;
}

.product_details_right h1 {
    text-transform: capitalize;
    text-overflow: ellipsis;
    line-height: 20px;
    font-size: 1.375rem;
    font-weight: 400;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product_details_right h1 {
        font-size: 1.25rem;
        margin-bottom: 17px;
    }
}

@media only screen and (max-width: 767px) {
    .product_details_right h1 {
        margin-bottom: 17px;
        font-size: 1.125rem;
    }
}

.product_details_right h1 a {
    color: #252525;
}

.product_details_right .product_ratting ul li {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 17px;
}

.product_details_right ul {
    margin-right: 10px;
}

@media only screen and (max-width: 767px) {
    .product_details_right .product_ratting {
        margin-bottom: 12px;
    }
}

.product_details_right .product_ratting ul li a {
    color: #ffc107 !important;
}

.product_details_right .product_ratting ul li.review a {
    color: #5a5a5a;
    margin-left: 10px;
}

.product_details_right .price_box {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .product_details_right .price_box {
        margin-bottom: 9px;
    }
}

.product_details_right .price_box span.current_price {
    font-size: 23px;
    color: inherit;
}

@media only screen and (max-width: 767px) {
    .product_details_right .price_box span.current_price {
        font-size: 1.125rem;
    }
}

.product_details_right .price_box span.old_price {
    font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {
    .product_details_right .price_box span.old_price {
        font-size: 1.063rem;
    }
}

.product_details_right .product_desc {
    margin-bottom: 15px;
    padding-left: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.product_details_right .product_desc ul li {
    list-style-type: square;
}

@media only screen and (max-width: 767px) {
    .product_details_right .product_desc {
        margin-bottom: 15px;
        padding-bottom: 18px;
    }
}

.product_details_right .product_desc::before {
    display: none;
}

.product_details_right .product_desc p {
    font-size: 0.875rem;
    line-height: 26px;
}

.product_details_right .priduct_social ul li {
    display: inline-block;
    margin-right: 7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product_details_right .priduct_social ul li {
        margin-right: 2px;
    }
}

@media only screen and (max-width: 767px) {
    .product_details_right .priduct_social ul li {
        margin-right: 2px;
    }
}

.product_details_right .priduct_social ul li:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 767px) {
    .product_details_right .priduct_social ul li {
        margin-right: 0;
    }
}

.product_details_right .priduct_social ul li a {
    color: #fff;
    font-size: 0.75rem;
    line-height: 24px;
    padding: 0 8px;
    font-weight: bold;
    border-radius: 3px;
    text-transform: capitalize;
    display: block;
}

.product_details_right .priduct_social ul li a em {
    padding-right: 5px;
}

@media only screen and (max-width: 767px) {
    .product_details_right .priduct_social ul li a {
        padding: 0 4px;
        font-size: 10px;
    }
}

.product_details_right .priduct_social ul li a:hover {
    opacity: 0.8;
}

.product_details_right .priduct_social ul li a.facebook {
    background: #3b5999;
}

.product_details_right .priduct_social ul li a.twitter {
    background: #1da1f2;
}

.product_details_right .priduct_social ul li a.pinterest {
    background: #cb2028;
}

.product_details_right .priduct_social ul li a.google-plus {
    background: #fe6d4c;
}

.product_details_right .priduct_social ul li a.linkedin {
    background: #010103;
}

.product_details_right .product_timing {
    position: inherit;
    margin-bottom: 22px;
    margin-top: 30px;
}

@media only screen and (max-width: 767px) {
    .product_details_right .product_timing {
        margin-bottom: 16px;
        margin-top: 25px;
    }
}

.product_details_right .countdown_area {
    max-width: 220px;
}

@media only screen and (max-width: 767px) {
    .product_details_right .countdown_area {
        max-width: 190px;
    }
}

.product_variant.quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-top: 1px solid #eee;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.product_variant1:last-child {
    padding-bottom: 0;
}

.product-variant__label {
    font-size: 0.875rem;
}

.product-variant__btn--active {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid var(--buttons);
}

@media screen and (min-width: 1260px) {
    .product-variant__btn {
        margin: 12px 16px 12px 0;
    }
}

.product-variant__btn {
    background-color: var(--buttons);
    color: var(--button-text);
    border-radius: 3px;
    display: inline-block;
    font-size: 1rem;
    margin: 8px 16px 0 0;
    min-height: 32px;
    min-width: 32px;
    padding: 8px 12px;
    font-weight: var(--font-weight);
}

.product-variant__btn:hover {
    color: #fff !important;
}

/* .pro_savings {
    color: var(--buttons);
} */

.pro_fav {
    border: 0;
    font-size: 1rem;
    margin-left: 10px;
    background: var(--buttons);
    color: #fff;
    padding: 0.463rem 0.88rem;
    border-radius: 5px;
    text-transform: capitalize;
}

@media (max-width: 1200px) {
    .pro_fav {
        font-size: inherit;
        margin: auto;
    }
}

@media only screen and (max-width: 767px) {
    .product_variant.quantity {
        margin-bottom: 16px;
    }
}

.product_variant.quantity label {
    text-transform: capitalize;
    font-size: 0.875rem;
    margin-bottom: 0;
}

.product_variant.quantity input {
    width: 100px;
    text-align: center;
    border: 1px solid #ebebeb;
    background: none;
    height: 42px;
    padding: 0 12px;
    border-radius: 5px;
    margin-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .product_variant.quantity input {
        width: 110px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product_variant.quantity input {
        width: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .product_variant.quantity input {
        width: 65px;
        margin-left: 10px;
    }
}

.product_variant.quantity button {
    border: 0;
    font-size: 1rem;
    padding: 0.563rem;
    font-weight: 600;
    margin-left: 10px;
    background: var(--buttons);
    color: var(--button-text);
    border-radius: 5px;
    text-transform: capitalize;
}

@media (max-width: 1200px) {
    .product_variant.quantity button {
        font-size: inherit;
        margin: auto;
    }

    .home-banner .banner_box_content img {
        width: auto;
    }
}

@media (max-width: 991px) {
    .respond-change {
        display: grid;
        grid-template-columns: 100%;
    }

    .respond-change .home-banner {
        margin: 0 auto;
    }
}

@media (max-width: 479px) {
    .respond-change {
        margin: 40px auto;
    }
}

.product_variant.quantity .product-det-fav {
    background-color: #222222;
    color: #fff;
}

.product_variant.quantity .product-det-fav:hover {
    background-color: var(--buttons);
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .product_variant.quantity button {
        min-width: inherit;
        margin-left: 10px;
    }
}

.product_variant.quantity button:hover {
    background: #3e444a;
}

.product-variant__list {
    margin-bottom: 20px;
}

.product_variant.size {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.product_variant.size label {
    font-size: 0.938rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-right: 15px;
}

.product_variant.size .niceselect_option {
    float: inherit;
    max-width: 200px;
}

@media only screen and (max-width: 767px) {
    .product_d_info {
        margin-bottom: 57px;
    }
}

.product_d_info {
    margin-bottom: 30px;
}

.product_d_action {
    margin-bottom: 14px;
}

.product_d_action ul li a {
    font-size: 0.875rem;
    line-height: 28px;
}

.product_meta {
    margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
    .product_meta {
        margin-bottom: 20px;
    }
}

.product_meta span {
    font-weight: 500;
}

.product_meta span a {
    margin-left: 10px;
    font-weight: 400;
}

.variable_product .niceselect_option .list {
    width: 100%;
}

.main-content {
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 850px) {
    .main-content {
        margin: 15px auto;
        padding: 0;
    }
}

.outline-outward {
    display: inline-block;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.outline-outward:before {
    content: "";
    position: absolute;
    border: #ececec solid 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition-duration: 0.3s;
    transition-property: top right bottom left;
}

.outline-outward:hover:before {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
}

.trim {
    display: inline-block;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.trim:before {
    content: "";
    position: absolute;
    border: white solid 4px;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    opacity: 0;
    transition-duration: 0.3s;
    transition-property: opacity;
}

.trim:hover:before {
    opacity: 1;
}

.content-1 {
    background: #000;
}

.policy-content {
    padding-bottom: 0;
}

.block-content-inner {
    margin-bottom: 30px;
    border: 0;
    background: none;
    color: #6b7a83;
    align-items: center;
    justify-content: center;
}

.policy-content > div > div:first-child {
    padding-top: 0;
}

.policy-content > div > div h5 {
    margin-left: 26px;
    color: #6b7a83;
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    letter-spacing: 0.005em;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
}

.policy-content > div > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 65px;
    margin-bottom: 0;
    padding: 20px 0;
    border-bottom: solid 1px #dee5e8;
}

.policy-content > div > div em {
    border: 0;
    color: var(--buttons);
    font-size: 2.5rem;
}

.policy-content > div > div em::before {
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.1em;
    -webkit-font-smoothing: antialiased;
}

.outline-inward {
    display: inline-block;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.outline-inward:before {
    content: "";
    position: absolute;
    border: #ececec solid 4px;
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: -16px;
    opacity: 0;
    transition-duration: 0.3s;
    transition-property: top right bottom left;
}

.outline-inward:hover:before {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    opacity: 1;
}

/* product info */
.product_info_button {
    border-bottom: 1px solid #ededed;
    margin-bottom: 29px;
}

@media only screen and (max-width: 767px) {
    .product_info_button ul li {
        margin-bottom: 5px;
    }

    .product_info_button ul li:last-child {
        margin-bottom: 0;
    }
}

.product_info_button ul li a {
    display: block;
    float: left;
    text-transform: capitalize;
    font-size: 1.25rem;
    color: #555;
    font-weight: 500;
    margin-right: 35px;
    line-height: 26px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .product_info_button ul li a {
        margin-right: 25px;
        font-size: 1.063rem;
    }
}

.product_info_button ul li a.active {
    color: #333333;
    border: none;
    border-bottom: 3px solid var(--buttons);
    font-weight: bold;
    padding-bottom: 10px;
}

.product_info_button ul li a:hover {
    color: #333333;
}

.product_info_button ul li:last-child a {
    margin-right: 0;
}

.product_review_form button {
    border: none;
    background: var(--buttons);
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 15px 10px;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 0.813rem;
}

.product_review_form button:hover {
    background: #222222;
    color: #ffffff;
}

.product_info_content p {
    line-height: 28px;
}

.product_d_table {
    padding: 10px 0 22px;
}

.product_d_table table {
    border-top: 1px solid #ddd;
    width: 100%;
}

.product_d_table table tbody tr {
    border-bottom: 1px solid #ddd;
}

.product_d_table table tbody tr td {
    padding: 7px 17px;
}

.product_d_table table tbody tr td:first-child {
    border-right: 1px solid #ddd;
    width: 30%;
    font-weight: 700;
}

.product_d_inner {
    padding: 20px 30px 27px;
    border: 1px solid #ededed;
}

@media only screen and (max-width: 767px) {
    .product_d_inner {
        padding: 20px 20px 27px;
    }
}

.product_info_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 15px;
}

.product_info_inner .product_ratting {
    margin-bottom: 10px;
}

.product_info_inner .product_ratting p {
    margin-bottom: 5px;
}

.product_info_inner .product_ratting strong {
    margin-top: 10px;
    display: block;
    margin-bottom: 8px;
}

.reviews_wrapper h2 {
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
    .reviews_wrapper h2 {
        font-size: 0.938rem;
    }
}

.reviews_wrapper .product_ratting {
    margin-bottom: 20px;
}

.reviews_wrapper .product_ratting h3 {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: capitalize;
}

.reviews_wrapper .comment_title {
    margin-bottom: 20px;
}

.reviews_wrapper .product_review_form input {
    border: 1px solid #ddd;
    background: none;
    width: 100%;
    height: 40px;
    padding: 0 20px;
}

.reviews_wrapper .product_review_form textarea {
    border: 1px solid #ddd;
    background: none;
    height: 120px;
    resize: none;
    width: 100%;
    margin-bottom: 14px;
    padding: 0 20px;
}

.reviews_wrapper .product_review_form p {
    margin-bottom: 7px;
}

.reviews_wrapper .star_rating {
    float: right;
}

.reviews_wrapper .star_rating ul li {
    display: inline-block;
}

.reviews_wrapper .star_rating ul li a {
    color: #ffc107;
}

.reviews_wrapper .reviews_comment_box {
    display: flex;
    margin-bottom: 22px;
}

.reviews_comment_box .comment_thmb img {
    width: 50px;
    height: 50px;
}

.reviews_comment_box em {
    color: #ffc107;
}

.reviews_wrapper .reviews_comment_box .comment_text {
    width: 100%;
    border: 1px solid #ededed;
    position: relative;
    margin-left: 21px;
    padding: 12px;
    border-radius: 3px;
}

.reviews_wrapper .reviews_comment_box .comment_text::before {
    background: #fff;
    border-bottom: 1px solid #ededed;
    border-left: 1px solid #ededed;
    content: "";
    display: block;
    height: 10px;
    left: -6px;
    position: absolute;
    top: 10px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 10px;
}

.reviews_wrapper .reviews_meta p {
    font-size: 0.938rem;
    margin-bottom: 15px;
}

.reviews_wrapper .reviews_meta p strong {
    text-transform: uppercase;
    font-weight: 500;
    color: #222222;
}

.reviews_wrapper .product_ratting ul li {
    display: inline-block;
}

.reviews_wrapper .product_ratting ul li a {
    color: #ffc107;
}

/*  size chart */
.lazyloaded {
    opacity: 1;
}

.product-view .sizes-table {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    color: #21293c;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-collapse: collapse;
}

.product-view .sizes-table thead th:first-child,
.product-view .sizes-table tbody th {
    padding-left: 30px;
}

.product-view .sizes-table thead th {
    padding: 30px 0 30px 10px;
    background: #f4f4f2;
    font-weight: var(--font-weight);
}

.product-cont {
    min-height: 300px;
    padding: 22px 0 15px;
    border: 0;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.005em;
    line-height: 22px;
    box-shadow: none;
}

.product-view .sizes-table tbody td {
    padding: 12px 0 12px 10px;
    font-weight: bold;
}

.product-view .sizes-table tbody tr:nth-child(2n) > * {
    background: #ebebeb;
}

/* product-feature */
.pro-feature-right .product_carousel .owl-nav div {
    position: absolute;
    top: -50px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #888;
    width: 34px;
    left: auto;
    line-height: 32px;
    right: 0;
    height: 34px;
    border: 1px solid #ebebeb;
    background: #fff;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    border-radius: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.pro-feature-right .product_carousel .owl-nav div.owl-next {
    right: 0;
}

.pro-feature-right .product_carousel .owl-nav div.owl-prev {
    right: 45px;
}

@media only screen and (max-width: 767px) {
    .pro-feature-right .product_carousel .owl-nav div {
        top: -45px;
    }
}

@media only screen and (max-width: 767px) {
    .pro-feature-right {
        margin-bottom: 56px;
    }
}

.pro-feature-right .section_title {
    text-align: left;
    margin-bottom: 24px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .pro-feature-right .section_title {
        margin-bottom: 21px;
    }
}

.pro-feature-right .section_title h2 {
    font-size: 1.25rem;
    line-height: 22px;
    letter-spacing: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pro-feature-right .section_title h2 {
        font-size: 1.188;
        line-height: 21px;
    }
}

@media only screen and (max-width: 767px) {
    .pro-feature-right .section_title h2 {
        font-size: 1.25rem;
        line-height: 20px;
    }
}

.feature_product_area .single_product {
    overflow: hidden;
}

.pro-feature-inner {
    margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
    .pro-feature-inner {
        margin-bottom: 60px;
    }
}

.pro-feature-inner .product_thumb {
    width: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pro-feature-inner .product_thumb {
        width: 76px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pro-feature-inner .product_thumb {
        width: 76px;
    }
}

.pro-feature-inner .product_content {
    width: calc(100% - 90px);
    padding-left: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pro-feature-inner .product_content {
        width: calc(100% - 76px);
        padding-left: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pro-feature-inner .product_content {
        width: calc(100% - 76px);
        padding-left: 10px;
    }
}

.pro-feature-inner .product_content h4 {
    margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pro-feature-inner .product_content h4 {
        margin-bottom: 9px;
        font-size: 0.875rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pro-feature-inner .product_content h4 {
        margin-bottom: 9px;
        font-size: 0.875rem;
    }
}

.pro-feature-inner .price_box {
    margin-top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pro-feature-inner .price_box {
        margin-top: 6px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pro-feature-inner .price_box {
        margin-top: 6px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pro-feature-inner .price_box span.current_price {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pro-feature-inner .price_box span.current_price {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pro-feature-inner .price_box span.old_price {
        font-size: 0.875rem;
        margin-left: 3px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pro-feature-inner .price_box span.old_price {
        font-size: 0.875rem;
        margin-left: 3px;
    }
}

.pro-feature-inner .single_product:hover .action_links {
    bottom: 5px;
}

.feature_product_area .action_links {
    left: 13px;
    transform: inherit;
    bottom: 20px;
}

.pro-feature-inner .single_product:hover .price_box {
    opacity: 0;
}

.pro-feature-inner .single_product:hover .product_rating {
    opacity: 0;
}

.pro-feature-inner .action_links {
    bottom: 0;
    transition: 0.3s;
}

.pro-feature-inner .feature_product_area .product_thumb {
    width: 120px;
    height: 120px;
    float: left;
}

.pro-feature-inner .feature_product_area .product_thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}

.pro-feature-inner .feature_product_area .product_content {
    position: relative;
    width: calc(100% - 120px);
    float: left;
    text-align: left;
    margin-top: 0;
    padding-left: 16px;
    padding-top: 20px;
}

.pro-feature-inner .product_rating ul {
    display: flex;
}

.pro-feature-inner .product_rating ul li {
    padding-right: 5px;
}

.pro-feature-inner .product_rating ul li a {
    font-size: 0.875rem;
    color: #f9d738;
}

.pro-feature-inner .feature_product_area .single_product {
    border: 1px solid lightgray;
}

.pro-feature-inner .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.pro-feature-inner .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    width: 100%;
}

@media (max-width: 767px) {
    .pro-feature-inner {
        margin-top: 30px;
    }
}

/* similar page */

.similar-product-sec .single_product .product_thumb {
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin: 15px auto;
    display: block;
    position: relative;
}

.similar-product-sec .single_product .product_thumb img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

/* shop page */

.relevant_sort {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 42px;
    line-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
}

.price_box span.old_price {
    font-weight: 400;
    font-size: 0.938rem;
    margin: 0px 10px;
    color: #878787;
}

.row.right_shop_content {
    flex-direction: row;
}

.right_shop_content .inner_product_content {
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin: 0.625rem auto;
    display: block;
    position: relative;
}

.right_shop_content .inner_product_content img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.shop_toolbar_btn {
    display: flex;
    flex: 0 0 60%;
    max-width: 60%;
    /* color: #3A2525; */
}

.shop_toolbar_btn p {
    line-height: 20px;
    font-size: 14px;
    margin: 0;
    float: left;
    font-weight: 400;
    text-transform: capitalize;
}

.shop_toolbar_btn > button {
    margin-right: 15px;
    border: 0;
    background: inherit;
}

.shop_toolbar_btn > button.btn-grid-view {
    background: url(../images/grid.png) no-repeat scroll center center;
    width: 20px;
    height: 20px;
}

.shop_toolbar_btn > button.btn-grid-view.active {
    background: url(../images/grid.png) no-repeat scroll center center;
}

.shop_toolbar_btn > button.btn-list-view {
    background: url(../images/list.png) no-repeat scroll center center !important;
    width: 20px;
    height: 20px;
}

.shop_toolbar_btn > button.btn-list-view.active {
    background: url(../images/list.png) no-repeat scroll center center !important;
}

.product_content.inner_list_content {
    display: none;
}

.inner_grid_content .product_ratings {
    margin-bottom: 11px;
}

.inner_grid_content .product_ratings ul {
    justify-content: center;
}

.list_action {
    display: none;
}

.list-view .grid_name {
    display: none;
}

.list-view .share_links {
    display: none;
}

.list-view .share_links.list_action {
    display: block;
}

.list-view .list_action_right {
    display: block;
}

.list_action_right ul li {
    margin-right: 10px;
}

.list_action_right ul li:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 767px) {
    .list_action_right ul li {
        margin-right: 5px;
    }
}

.list_action_right ul li.add_to_cart a {
    padding: 0 30px;
    height: 42px;
    line-height: 40px;
    background: var(--buttons);
    /* background: red; */
    color: var(--button-text);
    border-radius: 30px;
    width: inherit;
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    text-transform: capitalize;
}

.list_action_right ul li.add_to_cart a:hover {
    /* background: var(--a-hover); */
    background: black;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .list_action_right ul li.add_to_cart a {
        padding: 0 20px;
        height: 38px;
        line-height: 36px;
    }
}

.list_action_right ul li a:hover {
    border-color: var(--a-hover);
    color: var(--a-hover);
}

@media only screen and (max-width: 767px) {
    .list_action_right ul li a {
        width: 38px;
        height: 38px;
        line-height: 41px;
    }
}

.list-view .product_content.inner_grid_content {
    display: none;
}

.list-view .product_content.inner_list_content {
    padding-left: 25px;
    text-align: left;
    margin-top: 10px;
    display: block;
    padding-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1400px) {
    .list-view .product_content.inner_list_content {
        padding-bottom: 5px;
        margin-top: 0;
    }

    .list-view .product_content.inner_list_content h4.product_name {
        font-size: inherit;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .list-view .product_content.inner_list_content {
        min-width: 60%;
        margin-top: 0;
        padding-bottom: 5px;
    }
}

@media only screen and (max-width: 767px) {
    .list-view .product_content.inner_list_content {
        flex-direction: column;
        flex: 0 0 100%;
        min-width: 100%;
        padding: 0 15px 15px 15px;
        text-align: center;
    }
}

.list-view .product_content.inner_list_content h4.product_name {
    font-size: 1.125rem;
}

.action_links ul li:nth-child(2) a::after {
    visibility: hidden;
    display: none;
}

.list-view .product_content.inner_list_content .share_links {
    opacity: inherit;
    visibility: visible;
    text-align: left;
    transform: inherit;
    position: inherit;
    padding: 0;
    box-shadow: inherit;
    border-radius: 0;
}

@media (max-width: 991px) {
    .list-view .product_content.inner_list_content .share_links {
        padding-top: 15px;
    }
}

.list-view .product_content.inner_list_content .product_desc {
    display: inline-block;
    margin: 0px 0 10px;
}

@media (max-width: 767px) {
    .list-view .share_links ul {
        justify-content: center;
    }
}

.list-view .product_content.inner_list_content .product_desc p {
    font-size: 0.875rem;
    line-height: 24px;
    color: #3A2525;
}

@media (max-width: 1199px) {
    .list-view .product_content.inner_list_content .product_desc {
        margin-bottom: 5px;
    }

    .price_box {
        margin-bottom: 3px !important;
    }
}

.list-view .single_product_content {
    display: grid;
    grid-template-columns: 20.68% auto;
}

@media (min-width: 1200px) and (max-width: 1699px) {
    .list-view .single_product_content {
        display: grid;
        grid-template-columns: 24.68% auto;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .list-view .single_product_content {
        display: grid;
        grid-template-columns: 31.68% auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .list-view .single_product_content {
        display: grid;
        grid-template-columns: 30.68% auto;
    }
}

@media only screen and (max-width: 767px) {
    .list-view .single_product_content {
        flex-direction: column;
        grid-template-columns: 100%;
    }
}

.list-view .single_product_content:hover .share_links {
    transform: inherit;
}

.single_product_content:hover .share_links {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}

.single_product_content .share_links ul li a:hover {
    background: var(--buttons);
    color: #fff;
}

.single_product_content .share_links ul li a::after {
    position: absolute;
    content: "";
    margin-left: 10px;
    top: 14px;
    width: 1px;
    height: 15px;
    background: #888;
    opacity: 0.4;
}

.single_product_content .share_links ul li:nth-last-child(1) a::after {
    display: none;
}

.list-view .inner_list_content .share_links ul li a::after {
    display: none;
}

@media only screen and (max-width: 767px) {
    .list-view .inner_product_content {
        flex: 0 0 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.col-cust-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: max-width;
    padding-right: 15px;
    padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .col-cust-5 {
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
    }
}

@media only screen and (max-width: 767px) {
    .col-cust-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media only screen and (max-width: 479px) {
    .col-cust-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.right_shop_content > div {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

.shop_toolbar_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    padding: 8px 10px;
    color: white;
}

@media only screen and (max-width: 767px) {
    .shop_toolbar_content {
        flex-direction: column;
    }
}

@media only screen and (max-width: 767px) {
    .shop_toolbar_btn {
        margin-bottom: 20px;
    }
}

.right_shop_content {
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .right_shop_content {
        margin-bottom: 30px;
    }
}

.right_shop_content .single_product_content {
    position: relative;
    margin-bottom: 20px;
    border: 1px solid lightgrey;
}

.right_shop_content.list-view .single_product_content {
    margin-bottom: 30px;
}

.shop_sidebar .active {
    color: var(--buttons);
}

/* shop page css end*/

/* extra */

.share_links ul li a {
    font-size: 1rem;
    display: inline-block;
    width: 33px;
    height: 33px;
    line-height: 36px;
    text-align: center;
    background: var(--buttons);;
    border-radius: 50%;
}

.share_links ul {
    display: flex;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .share_links ul {
        margin-top: -10px;
    }
}

.share_links ul li {
    margin-right: 4px;
}

.product_content h4 {
    font-size: 1rem;
    line-height: 15px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 16px;
    /* padding-top: 10px; */
}

@media only screen and (max-width: 767px) {
    .main_shop_content {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar_content .content_list {
        margin-bottom: 57px;
    }
}

@media only screen and (max-width: 767px) {
    .sidebar_content .content_list {
        margin-bottom: 48px;
    }
}

.sidebar_content .single_banner {
    border: 0;
}

@media only screen and (max-width: 767px) {
    .sidebar_content .single_banner {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .sidebar_content .single_banner a {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .sidebar_content .single_banner a img {
        width: 100%;
    }
}

.content_list {
    margin-bottom: 33px;
}

.content_list.content_categories > ul > li > a {
    font-size: 0.813rem;
    display: block;
    line-height: 30px;
    padding: 0 20px;
}

.content_list.content_categories > ul > li > a:hover {
    color: var(--a-hover);
}

.content_list.content_categories > ul > li:last-child > a {
    border-bottom: 0;
}

.content_list.content_categories > ul > li.content_sub_categories > a {
    position: relative;
}

.content_list.content_categories > ul > li.content_sub_categories > a::before {
    content: "+";
    cursor: pointer;
    font-size: 0.75rem;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translatey(-50%);
}

.content_list.content_categories > ul > li.content_sub_categories > a.active::before {
    content: "-";
    cursor: pointer;
    font-size: 0.75rem;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translatey(-50%);
}

.content_list.content_categories > ul > li ul {
    padding-left: 30px;
    display: none;
}

.content_list.content_categories > ul > li ul li a {
    font-size: 0.813rem;
    line-height: 30px;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.content_list.content_categories > ul > li ul li a:hover {
    color: var(--a-hover);
}

.content_list:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .content_list {
        margin-bottom: 68px;
    }
}

@media only screen and (max-width: 767px) {
    .content_list {
        margin-bottom: 49px;
    }
}

.content_list h3 {
    font-size: 0.938rem;
    margin-bottom: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #ededed;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .content_list h3 {
        font-size: 1.125rem;
    }
}

.content_list > ul > li > a {
    font-size: 0.875rem;
    display: block;
    line-height: 30px;
}

.content_list > ul > li > a:hover {
    color: var(--a-hover);
}

.content_list > ul > li > a span {
    float: right;
}

.ui-slider-horizontal .ui-slider-range {
    background: var(--buttons);
    height: 2px;
}

.ui-slider-horizontal {
    height: 2px;
    background: #dbdbdb;
    border: none;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 22px;
}

.content_list.content_filter {
    overflow: hidden;
}

.content_list.content_filter .ui-slider-horizontal .ui-slider-handle {
    top: -0.6rem;
}

.content_list.content_filter .ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background: #222e3e;
    font-weight: bold;
    color: #fff;
    text-align: center;
    border-radius: 5px;
}

.payment__Sec .content_list.content_filter .ui-state-default,
.payment__Sec .ui-widget-content .ui-state-default,
.payment__Sec .ui-widget-header .ui-state-default {
    padding: 1rem;
}

@media (max-width: 400px) {
    .payment__Sec .content_list.content_filter .ui-state-default,
    .payment__Sec .ui-widget-content .ui-state-default,
    .payment__Sec .ui-widget-header .ui-state-default {
        padding: 0;
    }
}

.content_list.content_filter form {
    padding-top: 10px;
}

.content_list.content_filter form input {
    background: none;
    font-size: 0.75rem;
    text-align: center;
    line-height: 31px;
    width: 100%;
}

.content_filter .ui-slider-1 {
    left: 0;
    width: 100%;
}

.content_filter .ui-slider-2 {
    left: 0;
}

.content_filter .ui-slider-3 {
    left: 100%;
}

.content_list.content_filter form button {
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    text-transform: capitalize;
    color: var(--button-text);
    background: var(--buttons);
    border: 0;
    border-radius: 30px;
    float: left;
    -webkit-transition: 0.3s;
    transition: all 0.3s ease 0s;
}

.content_list.content_filter form button:hover {
    background: var(--buttons);
}

.tag_cloud a {
    margin: 0 5px 12px 0;
    padding: 5px 15px;
    text-transform: capitalize;
    display: inline-block;
    border: 1px solid #ededed;
    background: #ffffff;
    border-radius: 3px;
    font-size: 0.813rem;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tag_cloud a {
        padding: 5px 10px;
    }
}

.tag_cloud a:hover {
    background: var(--a-hover);
    border-color: var(--a-hover);
    color: #ffffff;
}

.list_action_right ul li a {
    width: 42px;
    height: 42px;
    border: 1px solid #ededed;
    line-height: 40px;
}

.shop_toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
    .shop_toolbar {
        flex-direction: column-reverse;
        padding: 10px 10px 18px;
    }
}

.select_option {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .select_option {
        flex-direction: column;
    }
}

.select_option .nice-select {
    border: 0;
    height: 30px;
    line-height: 29px;
}

.select_option .nice-select ul.list {
    top: 114%;
    right: 0;
    width: 200px;
    max-height: 200px;
    overflow: auto;
}

@media only screen and (max-width: 767px) {
    .niceselect_option {
        margin-bottom: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .page_amount {
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 767px) {
    .page_amount p {
        padding-left: 0;
        margin-left: 0;
        border-left: 0;
    }
}

.list_button ul li {
    margin-right: 12px;
}

.list_button ul li:last-child {
    margin-right: 0;
}

.list_button ul li a:hover {
    color: var(--a-hover);
}

.list_button ul li a em {
    margin-right: 5px;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
}

.list_button ul li a.active {
    color: var(--active-color);
}

.list_button ul li a.active em {
    background: var(--buttons);
    color: #ffffff;
}

.product_ratting ul li {
    display: inline-block;
}

.product_ratting ul li a {
    color: #ffc107;
}

@media only screen and (max-width: 767px) {
    .product_list_item .inner_product_content {
        margin-bottom: 18px;
    }
}

.product_list_item .product_content h3 {
    margin-bottom: 10px;
}

.product_list_item .product_ratting {
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .pagination {
        margin-top: 19px;
    }
}

.pagination ul li {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #3A2525;
    border-radius: 3px;
    margin-left: 3px;
}

.pagination ul li:first-child {
    margin-left: 0;
}

.pagination ul li a {
    display: block;
    border-radius: 3px;
    color: white;
}

.page-link {
    color: var(--buttons);
}

.pagination ul li a:hover {
    background: var(--a-hover);
    color: #ffffff;
}

.pagination ul li.current {
    background: var(--buttons);
    color: #ffffff;
}

.pagination ul li.next {
    width: 40px;
}

.shop_toolbar.t_bottom {
    justify-content: center;
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .shop_toolbar.t_bottom .pagination {
        margin-top: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop_inverse_content .tab-content .row {
        flex-direction: row;
    }
}

@media only screen and (max-width: 767px) {
    .shop_inverse_content .tab-content .row {
        flex-direction: row;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .grid_view .quick_button {
        bottom: 5px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .grid_view .quick_button a {
        line-height: 37px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .grid_view .action_button ul li a {
        width: 43px;
        height: 40px;
        line-height: 38px;
    }
}

.share_links {
    transition: all 0.3s ease;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    /* background: #ffffff; */
    padding: 4px 7px;
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}

.grid_view .hover_action a {
    width: 43px;
    height: 40px;
    line-height: 38px;
}

.content_label {
    z-index: 10;
    top: 0;
    left: 0;
    font-size: 11px;
    line-height: 20px;
    padding: 4px 14px;
    font-weight: 700;
    position: absolute;
    color: #e63946;
}

.content_label::after {
    content: "";
    display: inline-block;
    border-top-width: 28px;
    border-top-style: solid;
    border-left: 0px;
    border-right: 20px solid transparent;
    height: 0;
    min-width: 68px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.content_label span {
    position: relative;
    z-index: 100;
    margin-right: 11px;
    color: #fff;
}

.inner_product_content {
    position: relative;
    overflow: hidden;
}

.blue.content_label::after {
    color: var(--buttons);
}

.right_shop_content .single_product_content .product_rating > ul > li {
    display: inline-block;
}

.right_shop_content .single_product_content .product_rating > ul {
    padding-top: 10px;
}

.right_shop_content .single_product_content .product_rating > ul > li > a {
    color: #fdd835;
}

.similar-product-sec .product_ratting {
    padding-top: 10px;
}

.product_rating em {
    color: #ffc107;
}

.content_list ul.inner-discount > li a span {
    float: left;
    display: block;
    width: 100%;
}

.content_list ul.inner-discount > li:nth-last-child(1) a span {
    margin-bottom: 30px;
}

.content_list label:hover {
    color: var(--buttons);
}

/* * Blog fullwidth */

.blog_section {
    background: #f4f4f4;
    padding: 63px 0 70px;
    margin-bottom: 65px;
}

@media only screen and (max-width: 767px) {
    .blog_section {
        padding: 53px 0 60px;
        margin-bottom: 54px;
    }
}

.blog_carousel .col-lg-3 {
    flex: 0 0 100%;
    width: 100%;
}

.blog_inner_content {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #fff;
}

.blog_inner_content h4 {
    font-size: 1.063rem;
    text-transform: capitalize;
    line-height: 18px;
    margin-bottom: 5px;
    font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog_inner_content h4 {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_inner_content h4 {
        font-size: 1rem;
        line-height: 23px;
    }
}

@media only screen and (max-width: 767px) {
    .blog_inner_content h4 {
        font-size: 0.938rem;
    }
}

.blog_inner_content h4 a:hover {
    color: var(--a-hover);
}

.articles_date {
    margin-bottom: 9px;
}

.articles_date p {
    font-size: 0.875rem;
    line-height: 15px;
}

.articles_date p a:hover {
    color: var(--a-hover);
}

.blog_fullwidth {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_fullwidth {
        margin-bottom: 60px;
        margin-top: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .blog_fullwidth {
        margin-bottom: 60px;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_fullwidth .blog_content {
        margin-bottom: 66px;
    }
}

@media only screen and (max-width: 767px) {
    .blog_fullwidth .blog_content {
        margin-bottom: 56px;
    }
}

.blog_fullwidth .blog_thumb {
    width: 100%;
    float: inherit;
}

.outer_blog_content .blog_thumb {
    height: 800px;
}

.outer_blog_content .blog_thumb img {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

@media (max-width: 1400px) {
    .outer_blog_content .blog_thumb {
        height: auto;
    }
}

.blog_fullwidth .blog_thumb iframe {
    height: 550px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog_fullwidth .blog_thumb iframe {
        height: 440px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_fullwidth .blog_thumb iframe {
        height: 440px;
    }
}

@media only screen and (max-width: 767px) {
    .blog_fullwidth .blog_thumb iframe {
        height: 200px;
    }
}

.blog_fullwidth .blog_inner_content {
    padding-top: 24px;
}

@media only screen and (max-width: 767px) {
    .blog_fullwidth .blog_inner_content {
        padding-top: 18px;
    }
}

.blog_fullwidth .blog_inner_content p.post_desc {
    margin-bottom: 0;
    padding: 16px 0 20px;
    max-width: 82%;
}

@media only screen and (max-width: 767px) {
    .blog_fullwidth .blog_inner_content p.post_desc {
        max-width: 100%;
        padding: 12px 0 17px;
    }
}

.blog_fullwidth .blog_inner_content h4 {
    margin-bottom: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_fullpage_section.blog_reverse {
        margin-top: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_reverse .row {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 767px) {
    .blog_reverse .row {
        flex-direction: column-reverse;
    }
}

.pagination_full {
    margin-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog_nosidebar {
        margin-bottom: 0;
    }
}

.blog_nosidebar .single_blog_content {
    overflow: hidden;
}

.blog_nosidebar .blog_thumb {
    float: left;
    width: 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_nosidebar .blog_thumb {
        width: 35%;
    }
}

@media only screen and (max-width: 767px) {
    .blog_nosidebar .blog_thumb {
        width: 100%;
    }
}

.blog_nosidebar .blog_thumb_active {
    float: left;
    width: 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_nosidebar .blog_thumb_active {
        width: 35%;
    }
}

@media only screen and (max-width: 767px) {
    .blog_nosidebar .blog_thumb_active {
        width: 100%;
    }
}

.blog_nosidebar .blog_inner_content {
    float: left;
    width: 70%;
    padding-top: 0;
    padding-left: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_nosidebar .blog_inner_content {
        width: 65%;
    }
}

@media only screen and (max-width: 767px) {
    .blog_nosidebar .blog_inner_content {
        width: 100%;
        padding-left: 0;
        padding-top: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_nosidebar .blog_inner_content h3 {
        line-height: 20px;
        margin-bottom: 9px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_nosidebar .blog_post_content {
        margin-bottom: 9px;
    }
}

.blog_content .blog_inner_content .btn_more a {
    font-size: 1rem;
    line-height: 42px;
    text-transform: capitalize;
    font-weight: 500;
    padding: 0 20px;
    display: inline-block;
    background: var(--buttons);
    color: #fff;
    border-radius: 5px;
}

.blog_sidebar_content1 {
    position: sticky;
    top: 0;
}

.blog_sidebar_content1 .widget_title {
    position: relative;
    margin-bottom: 20px;
}

.blog_sidebar_content1 .widget_title h3 {
    margin-bottom: 0;
}

.blog_sidebar_content1 .search_list {
    margin-bottom: 28px;
}

.blog_sidebar_content1 .search_list:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_sidebar_content1 .search_list {
        margin-bottom: 65px;
    }
}

@media only screen and (max-width: 767px) {
    .blog_sidebar_content1 .search_list {
        margin-bottom: 56px;
    }
}

.blog_sidebar_content1 .search_list.comments .post_thumb a img {
    border-radius: 50%;
}

.blog_sidebar_content1 .search_list.comments .post_info span a {
    color: var(--a-hover);
}

.blog_sidebar_content1 .search_list.comments .post_info a:hover {
    color: var(--a-hover);
}

.blog_sidebar_content1 .content1_categories {
    background: inherit;
    margin-bottom: 18px;
}

.blog_sidebar_content1 .content1_categories .widget_title {
    margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_sidebar_content1 .content1_categories {
        margin-bottom: 52px;
    }
}

@media only screen and (max-width: 767px) {
    .blog_sidebar_content1 .content1_categories {
        margin-bottom: 43px;
    }
}

.blog_sidebar_content1 .content1_categories ul li a {
    color: #222222;
    padding: 0;
    border: 0;
    line-height: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog_sidebar_content1 .tag_content1 ul li a {
        padding: 0 12px;
    }
}

.content_search input {
    height: 35px;
    border: 1px solid #ededed;
    background: #fff;
    color: #222222;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 15px;
}

.content_search button {
    color: #fff;
    display: inline-block;
    background: var(--buttons);
    border: none;
    padding: 0 20px;
    height: 34px;
    line-height: 35px;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: var(--font-weight);
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 3px;
}

.content_search button:hover {
    background: var(--buttons);
}

.tag_content1 ul li {
    display: inline-block;
}

.tag_content1 ul li a {
    margin: 0 6px 0 0;
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    border: 1px solid #ededed;
    background: #fff;
    padding: 0 15px;
    line-height: 29px;
    border-radius: 3px;
}

.tag_content1 ul li a:hover {
    background: var(--a-hover);
    border-color: var(--a-hover);
    color: #fff;
}

.post_thumb {
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 10px;
}

.post_thumb img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
}

@media (max-width: 1400px) {
    .post_thumb {
        height: auto;
    }
}

.post_content1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
}

.post_content1:last-child {
    margin-bottom: 0;
}

.post_info h4 {
    font-size: 0.813rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 14px;
    margin-bottom: 5px;
}

.post_info h4 a:hover {
    color: var(--a-hover);
}

.post_info span {
    font-size: 0.75rem;
}

.blog_categories ul li {
    border-top: 1px solid #ddd;
}

.blog_categories ul li a {
    padding: 10px 0;
    text-transform: capitalize;
    display: inline-block;
    margin-left: 0;
}

.blog_categories ul li a:hover {
    color: var(--a-hover);
}

.blog_categories ul li:last-child a {
    padding-bottom: 0;
}

.shipping_area.shipping_contact.blog_shipping {
    margin-top: 94px;
}

.blog_pagination {
    margin-top: 30px;
    margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
    .blog_pagination {
        margin-bottom: 60px;
        margin-top: 0;
    }
}

.blog_pagination .pagination {
    border: 1px solid #ededed;
    justify-content: center;
    padding: 10px 0;
}

@media only screen and (max-width: 767px) {
    .blog_pagination .pagination {
        margin-top: 0;
    }
}

.search_list h3 {
    font-size: 0.938rem;
    margin-bottom: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #ededed;
}

/* eof blog full-width */

/* blog-details */
.inner_post_header {
    margin-bottom: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .inner_post_header {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 767px) {
    .inner_post_header {
        margin-bottom: 22px;
    }
}

.inner_post_header h3 {
    font-size: 1.875rem;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .inner_post_header h3 {
        font-size: 1.625rem;
        line-height: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .inner_post_header h3 {
        font-size: 1.563rem;
        line-height: 25px;
    }
}

@media only screen and (max-width: 767px) {
    .inner_post_header h3 {
        font-size: 1.063rem;
        line-height: 24px;
    }
}

.blog_details {
    margin-bottom: 70px;
    margin-top: 64px;
}

@media only screen and (max-width: 767px) {
    .blog_details {
        margin-top: 55px;
        margin-bottom: 60px;
    }
}

.blog_details .single_blog_content {
    margin-bottom: 62px;
    padding-bottom: 0;
    border-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .blog_details .single_blog_content {
        margin-bottom: 59px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_details .blog_sidebar {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .blog_details .blog_sidebar {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_details .outer_blog_content {
        margin-bottom: 66px;
    }
}

@media only screen and (max-width: 767px) {
    .blog_details .outer_blog_content {
        margin-bottom: 56px;
    }
}

.blog_details .blog_post_content {
    margin-bottom: 0;
}

.blog_details .blog_thumb {
    width: 100%;
    float: inherit;
}

.blog_details .blog_content {
    width: 100%;
    float: inherit;
    padding-left: 0;
    padding-top: 30px;
}

@media only screen and (max-width: 767px) {
    .blog_details .blog_content {
        padding-top: 22px;
    }
}

.blog_details .post_content > p {
    line-height: 24px;
    margin-bottom: 10px;
    font-size: 0.938rem;
}

@media only screen and (max-width: 767px) {
    .blog_details .post_content > p {
        font-size: 0.875rem;
    }
}

.blog_details .post_content blockquote {
    margin: 31px 0 31px 40px;
    font-style: italic;
    padding: 30px 45px;
    background: #f6f6f6;
    border: 1px solid #ebebeb;
    border-left: 4px solid var(--buttons);
}

.blog_details .post_content blockquote p {
    font-size: 1rem;
}

@media only screen and (max-width: 767px) {
    .blog_details .post_content blockquote p {
        font-size: 0.938rem;
    }
}

@media only screen and (max-width: 767px) {
    .blog_details .post_content blockquote {
        margin: 23px 0 23px 0;
        padding: 20px 12px 15px;
    }
}

.blog_details .social_icons {
    display: flex;
    align-items: center;
}

.blog_details .social_icons p {
    font-size: 0.813rem;
    text-transform: capitalize;
    margin-right: 20px;
    margin-bottom: 0;
    line-height: 13px;
    font-weight: 500;
}

@media only screen and (max-width: 767px) {
    .blog_details .social_icons p {
        font-size: 0.813rem;
        margin-right: 10px;
    }
}

.blog_details .social_icons ul li {
    display: inline-block;
}

.blog_details .social_icons ul li a {
    background: #e6e6e6;
    border-radius: 100%;
    display: inline-block;
    font-size: 0.75rem;
    height: 26px;
    line-height: 28px;
    margin-right: 5px;
    text-align: center;
    width: 26px;
}

.blog_details .social_icons ul li a:hover {
    color: #ffffff;
    background: var(--a-hover);
}

@media only screen and (max-width: 767px) {
    .blog_details .social_icons ul li a {
        margin-right: 2px;
    }
}

.blog_details .social_icons ul li:first-child a {
    padding-left: 0;
    border-left: 0;
}

.tag_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .tag_content {
        flex-direction: column;
        text-align: left;
        align-items: inherit;
    }
}

.tag_content .inner_post_tag {
    margin-bottom: 0;
}

.tag_content .inner_post_tag span .fas {
    color: var(--buttons);
}

@media only screen and (max-width: 767px) {
    .tag_content .inner_post_tag {
        margin-bottom: 10px;
    }
}

.tag_content .inner_post_tag span {
    font-weight: 500;
    font-size: 0.813rem;
}

.tag_content .inner_post_tag span a:hover {
    color: var(--a-hover);
}

.comments_box {
    margin-bottom: 65px;
}

@media only screen and (max-width: 767px) {
    .comments_box {
        margin-top: 0;
        margin-bottom: 55px;
    }
}

.comments_box h3 {
    font-size: 0.938rem;
    margin: 0 0 20px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
}

.inner_comment {
    overflow: hidden;
    margin-bottom: 30px;
}

.inner_comment:last-child {
    margin-bottom: 0;
}

.inner_comment .comment_img {
    display: inline-block;
    width: 50px;
    height: 50px;
}

.inner_comment .comment_img img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (max-width: 767px) {
    .inner_comment .comment_img {
        width: 40px;
    }
}

.inner_comment .comment_content {
    margin-left: 70px;
    position: relative;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    padding: 15px;
}

@media only screen and (max-width: 767px) {
    .inner_comment .comment_content {
        margin-left: 55px;
    }
}

.inner_comment .comment_content h5 {
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.inner_comment .comment_content h5 a:hover {
    color: var(--a-hover);
}

.inner_comment .comment_content span {
    line-height: 18px;
    margin-bottom: 8px;
    font-size: 0.813rem;
    font-style: italic;
    display: inline-block;
}

.inner_comment .comment_content p {
    margin-bottom: 0;
    font-size: 0.875rem;
}

.comment_reply {
    position: absolute;
    top: 20px;
    right: 12px;
}

@media only screen and (max-width: 767px) {
    .comment_reply {
        top: 15px;
    }
}

.comment_reply a {
    padding: 5px 10px;
    border-radius: 3px;
    color: #fff !important;
    background: var(--buttons);
    display: block;
}

@media only screen and (max-width: 767px) {
    .comment_reply a {
        line-height: 20px;
        font-size: 0.813rem;
    }
}

.comment_reply a:hover {
    background: var(--a-hover);
}

.comments_form h3 {
    font-size: 0.938rem;
    margin: 0 0 20px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
}

.comments_form p {
    font-size: 0.813rem;
    line-height: 17px;
    margin-bottom: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .comments_form form .row {
        flex-direction: row;
    }
}

.comments_form form label {
    font-size: 0.813rem;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.comments_form form textarea {
    width: 100%;
    height: 200px;
    background: #ffffff;
    border: 1px solid #ededed;
    margin-bottom: 10px;
    resize: none;
    padding: 10px;
}

@media only screen and (max-width: 767px) {
    .comments_form form textarea {
        height: 120px;
    }
}

.comments_form form input {
    width: 100%;
    height: 40px;
    border: 1px solid #ededed;
    margin-bottom: 15px;
    padding: 0 20px;
}

.comments_form form button {
    border: 0;
    background: var(--buttons);
    font-weight: 500;
    color: #fff;
    padding: 15px;
}

.comments_form form button:hover {
    background: var(--buttons);
}

@media only screen and (max-width: 767px) {
    .comments_form form button {
        line-height: 38px;
        height: 38px;
        padding: 0 10px;
    }
}

.inner_comment.list_two {
    padding-left: 50px;
}

@media only screen and (max-width: 767px) {
    .inner_comment.list_two {
        padding-left: 0;
    }
}

.related_posts {
    border-top: 1px solid #ededed;
    padding: 66px 0 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .related_posts {
        padding: 76px 0 41px;
    }
}

@media only screen and (max-width: 767px) {
    .related_posts {
        padding: 56px 0 25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .related_posts .row {
        flex-direction: row !important;
    }
}

.related_posts h3 {
    font-size: 0.938rem;
    margin: 0 0 20px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
}

.related_thumb {
    margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .related_thumb img {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .related_thumb img {
        width: 100%;
    }
}

.related_content h4 {
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 13px;
    margin-bottom: 0;
    text-transform: uppercase;
}

.related_content h4 a {
    color: #222222;
}

.related_content h4 a:hover {
    color: var(--a-hover);
}

.related_content span {
    font-size: 0.813rem;
    line-height: 17px;
}

.related_content span em {
    margin-right: 3px;
}

.related_content .blog_post_content span {
    font-size: 0.75rem;
    line-height: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_related {
        margin-bottom: 28px;
    }
}

@media only screen and (max-width: 767px) {
    .single_related {
        margin-bottom: 25px;
    }
}

.blog-card-blog {
    margin-top: 30px;
}

.blog-sec .blog-card {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.blog-sec .blog-card .blog-card-image {
    position: relative;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.blog-sec .blog-card .blog-card-image img {
    height: 30vh;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 6px;
    pointer-events: none;
}

.blog-sec .blog-card .blog-table {
    padding: 15px 30px;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .blog-sec .blog-card .blog-table {
        padding: 15px;
    }
}

.blog-sec .blog-table {
    margin-bottom: 0px;
}

.blog-sec .blog-category {
    position: relative;
    line-height: 0;
    margin: 15px 0;
}

.blog-sec .blog-text-success {
    color: var(--buttons);
}

.blog-sec .blog-card-blog .blog-card-caption {
    margin-top: 5px;
}

.blog-sec .blog-card-caption {
    font-weight: 700;
    font-family: "Roboto Slab", "Times New Roman", serif;
}

.blog-sec .fa {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.blog-sec .blog-card-caption,
.blog-sec .blog-card-caption a {
    color: #333;
    text-decoration: none;
}

.blog-sec p {
    color: #3c4857;
    margin-top: 0;
    margin-bottom: 1rem;
}

.blog-sec .blog-card .ftr {
    margin-top: 15px;
}

.blog-sec .blog-card .ftr .author {
    color: #888;
}

.blog-sec .blog-card .ftr .author span:hover {
    color: var(--a-hover);
}

.blog-sec .blog-card .ftr div {
    display: inline-block;
}

.blog-sec .blog-card .author .avatar {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
}

.blog-sec .blog-card .ftr .stats {
    position: relative;
    top: 1px;
    font-size: 0.875rem;
    float: right;
    line-height: 30px;
}

/* eof blog-details*/

/* eof blog page */

/*--------  About us style ---------*/
@media only screen and (max-width: 767px) {
    .about-us-content {
        margin-top: 30px;
    }
}

.about-us-content h2 {
    font-size: 2rem;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-us-content h2 {
        font-size: 2rem;
        margin-bottom: 18px;
        padding-bottom: 22px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-content h2 {
        font-size: 1.563rem;
        margin-bottom: 15px;
        padding-bottom: 19px;
    }
}

@media only screen and (max-width: 767px) {
    .about-us-content h2 {
        font-size: 1.375rem;
        margin-bottom: 15px;
        padding-bottom: 19px;
    }
}

.about-us-content h2 span {
    color: var(--buttons);
}

.about-us-content p {
    margin: 0;
    color: #333;
    font-size: 0.938rem;
    line-height: 26px;
}

.about-us-content p.peragraph-blog {
    font-size: 1rem;
    font-weight: var(--font-weight);
    margin-bottom: 20px;
    line-height: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-content p.peragraph-blog {
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 767px) {
    .about-us-content p.peragraph-blog {
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 0.938rem;
    }
}

.about-us-content .about-us-btn {
    margin-top: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-us-content .about-us-btn {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-content .about-us-btn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .about-us-content .about-us-btn {
        margin-top: 20px;
    }
}

.about-us-content .about-us-btn a {
    display: inline-block;
    background-color: var(--buttons);
    color: var(--button-text);
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    z-index: 1;
    padding: 12px 22px;
    border-radius: 5px;
}

.about-us-content .about-us-btn a:hover {
    background-color: #222222;
}

.about-us-img {
    margin-right: 30px;
}

.about-us-img img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
}

@media (max-width: 1400px) {
    .about-us-img {
        height: auto;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-us-img {
        margin-right: 0px;
        height: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-img {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .about-us-img {
        margin-right: 0px;
    }
}

/* transaction history */

.trans_id,
.trans_payment,
.trans_date,
.trans_message,
.trans_amount,
.trans_status {
    display: none;
}

@media (max-width: 767px) {
    .trans_id,
    .trans_payment,
    .trans_date,
    .trans_message,
    .trans_amount,
    .trans_status {
        display: block;
        font-weight: bold;
    }
}

.transaction_history .dash-bg-right1 {
    background-color: #fff;
    box-shadow: none;
}
.transaction_history .favnext {
    text-align: end;
}
@media (max-width: 767px) {
    .transaction_history .thead-s-offer {
        display: none;
    }

    .transaction_history tbody tr {
        display: block;
        border-bottom: 5px solid #e5e7ea;
        padding: 15px 0;
    }

    .transaction_history tbody tr td {
        text-align: center;
        display: block;
        padding: 10px;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        border: none;
    }
}

/* notication */

.notification__sec .border_about {
    border: 1px solid #ddd;
    margin-top: 0.5rem;
}

.notification__sec .about-us-img img {
    height: 300px;
    width: 400px;
    padding: 40px;
    margin: auto;
    object-fit: contain;
}
@media (max-width: 479px) {
    .notification__sec .about-us-img img {
        height: auto;
        width: auto;
    }
}
/*--------  Feature style ---------*/

.feature-area .heading-feature {
    margin-bottom: 20px;
}

.feature-area .heading-feature h2 {
    font-size: 2rem;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
}

.feature-border {
    padding: 30px 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature-border {
        padding: 43px 20px 10px 20px;
    }

    .feature-border.feature-border-about {
        padding: 43px 20px 10px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .feature-border {
        padding: 43px 20px 10px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature-wrap {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .feature-wrap {
        text-align: center;
    }
}

.feature-wrap h5 {
    font-size: 1rem;
    margin: 18px 0 6px;
}

.feature-wrap p {
    margin: 0;
}

/* ----------------------------------------------------------------
    Team Members
-----------------------------------------------------------------*/
.team-members .team-member {
    background-color: #ffffff;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 4px;
}

.team-members .team-member .team-image {
    height: 200px;
    width: 200px;
    display: block;
    margin: auto;
}

@media (max-width: 1400px) {
    .team-members .team-member .team-image {
        height: auto;
    }
}

.team-members .team-member .team-image > img {
    width: 100%;
    border-radius: 4px;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.team-members .team-member .team-desc > h3 {
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 0;
}

.team-members .team-member .team-desc > span {
    font-size: 0.813rem;
    color: #9d9d9d;
    line-height: 18px;
}

.team-members .team-member .team-desc > p {
    margin-top: 10px;
    font-size: 0.875rem;
}

.team-members.team-members-circle .team-member {
    background-color: transparent;
    padding: 20px;
}

.team-members.team-members-circle .team-member .team-image > img {
    border-radius: 50%;
}

.team-members.team-members-card .team-member {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.team-members.team-members-card .team-member .team-image > img {
    border-radius: 0;
}

.team-members.team-members-card .team-member .team-desc {
    background-color: #fdfdfd;
    border-radius: 0px 0px 4px 4px;
    padding: 10px;
}

.team-members.team-members-shadow .team-member {
    border: 1px solid #eeeeee;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.03);
}

.team-members.team-members-shadow .team-member .team-image > img {
    border-radius: 0;
}

.team-members.team-members-shadow .team-member .team-desc {
    padding: 20px;
}

.team-members.team-members-transparent .team-member {
    background-color: transparent;
}

.team-members.team-members-transparent .team-member .team-desc > h3 {
    color: #ffffff;
}

.team-members.team-members-transparent .team-member .team-desc > span {
    color: #ffffff;
}

.team-members.team-members-transparent .team-member .team-desc > p {
    color: #ffffff;
}

.team-members.team-members-left .team-member {
    text-align: left;
}

.team-members.team-members-left .team-member .team-image {
    float: left;
    width: 40%;
    margin-bottom: 0;
}

.team-members.team-members-left .team-member .team-desc {
    overflow: hidden;
    position: relative;
    width: 60%;
    padding-left: 30px;
}

.team-members.team-members-left .team-member .team-desc > h3 {
    margin-top: 0;
}

.team-members .btn.btn-slide.btn-xs > span {
    left: 28px;
    top: 0px;
}

.team-members .btn.btn-slide > span {
    left: 40px;
    position: absolute;
    text-align: left;
    top: 13px;
    transition: left 0.5s ease 0s, opacity 0.5s ease 0s;
    white-space: nowrap;
}

.team-members .btn.btn-slide {
    border-radius: 50px;
    display: table-cell;
    font-weight: 400;
    opacity: 1;
    overflow: hidden;
    padding: 22px;
    position: relative;
    transition: width 0.4s ease 0s, background 0.4s ease 0s;
    width: 46px;
}

.team-member em {
    margin-left: -4px;
}

.team-members .btn.btn-light {
    background-color: #ffffff;
    border-color: #ebebeb;
    color: #4c5667;
}

.team-members .btn.btn-xs {
    font-size: 0.688rem;
    height: 28px;
    line-height: 28px;
    padding: 0 14px;
}

.align-center {
    display: inline-flex;
    text-align: center;
}

.team-members .btn {
    border-radius: 3px;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 1px;
    outline: none;
    padding: 12px 20px;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    background-color: var(--buttons);
    border-color: var(--buttons);
    border-width: 1px;
    border-style: solid;
    color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 6px;
    line-height: 14px;
}

.team-members .btn.btn-slide.btn-xs:hover {
    width: 110px;
}

.team-members .btn.btn-slide.btn-xs {
    margin: 5px;
    width: 30px;
}

.team-members .btn.btn-slide:hover {
    width: 140px;
    text-align: left;
}

.team-members .btn.btn-light:hover,
.team-members .btn.btn-light:focus,
.team-members .btn.btn-light:active,
.team-members .btn.btn-light.active {
    background-color: var(--buttons);
    border-color: var(--buttons);
    color: #fff;
}

.team-members .btn:hover,
.team-members .btn:focus,
.team-members .btn:active,
.team-members .btn.active {
    outline: 0 !important;
    background-color: var(--buttons);
    border-color: var(--buttons);
    color: #fff;
}

.team-members .owl-nav.disabled {
    display: block;
}

.team-members .owl-nav div.owl-prev {
    right: 55px;
}

.team-members .owl-nav div.owl-next {
    right: 15px;
}

.team-members .owl-nav div {
    position: absolute;
    top: -50px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #888;
    width: 34px;
    left: auto;
    line-height: 32px;
    right: 0;
    height: 34px;
    border: 1px solid #ebebeb;
    background: #fff;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    border-radius: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.animate-border {
    position: relative;
    display: block;
    width: 115px;
    height: 3px;
    background: var(--buttons);
}

.animate-border:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    left: 0;
    bottom: 0;
    border-left: 10px solid #fff;
    border-right: 10px solid #fff;
    -webkit-animation: animborder 2s linear infinite;
    animation: animborder 2s linear infinite;
}

@-webkit-keyframes animborder {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(113px);
        transform: translateX(113px);
    }
}

@keyframes animborder {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(113px);
        transform: translateX(113px);
    }
}

.animate-border.border-white:after {
    border-color: #fff;
}

.animate-border.border-yellow:after {
    border-color: #f5b02e;
}

.animate-border.border-black:after {
    border-right-color: #222e3e;
    border-left-color: #222e3e;
}

.animate-border.border-orange:after {
    border-right-color: var(--buttons);
    border-left-color: var(--buttons);
}

.animate-border.border-ash:after {
    border-right-color: #eef0ef;
    border-left-color: #eef0ef;
}

.animate-border.border-offwhite:after {
    border-right-color: #f7f9f8;
    border-left-color: #f7f9f8;
}

@keyframes primary-short {
    0% {
        width: 15%;
    }

    50% {
        width: 90%;
    }

    100% {
        width: 10%;
    }
}

@keyframes primary-long {
    0% {
        width: 80%;
    }

    50% {
        width: 0%;
    }

    100% {
        width: 80%;
    }
}

/* testimonial */
.inner-testimonial .client-content {
    border: 1px solid #e7e9ec;
    padding: 40px 40px 65px;
}

@media only screen and (max-width: 767px) {
    .inner-testimonial .client-content {
        padding: 40px 22px 65px;
    }
}

.inner-testimonial .client-content p {
    color: #333;
    font-size: 1rem;
    line-height: 30px;
    margin: 0;
}

.inner-testimonial .client-info {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: left;
    margin-left: 32px;
    margin-top: -44px;
    padding-right: 18px;
    position: relative;
    z-index: 99;
}

.inner-testimonial .client-info img {
    height: 85px;
    width: 85px;
    max-height: 100%;
    max-width: 100%;
}

@media only screen and (max-width: 767px) {
    .inner-testimonial .client-info {
        margin-left: 10px;
    }
}

.inner-testimonial .client-info h5 {
    color: #333;
    font-size: 1rem;
    font-weight: var(--font-weight);
    line-height: 1;
    margin: 0 22px 0 10px;
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .inner-testimonial .client-info h5 {
        font-size: 0.875rem;
    }
}

.inner-testimonial .client-info h5::before {
    background-color: #95999e;
    content: "";
    height: 1px;
    position: absolute;
    right: -15px;
    top: 8px;
    width: 7px;
}

.inner-testimonial .client-info span {
    color: #333;
    font-size: 0.875rem;
    font-weight: 400;
}

@media only screen and (max-width: 767px) {
    .testimonial-area.pt-60 {
        padding-top: 20px;
    }

    .testimonial-area.pb-80 {
        padding-bottom: 40px;
    }
}

.testimonial-area .owl-nav.disabled {
    display: block;
}

.testimonial-area .owl-nav div.owl-prev {
    right: 55px;
}

.testimonial-area .owl-nav div.owl-next {
    right: 15px;
}

.testimonial-area .owl-nav div {
    position: absolute;
    top: -50px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #888;
    width: 34px;
    left: auto;
    line-height: 32px;
    right: 0;
    height: 34px;
    border: 1px solid #ebebeb;
    background: #fff;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    border-radius: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

/* brand partners */

.brand-logo-active.owl-carousel .owl-item img {
    display: inline-block;
    width: auto;
}

.brand-logo-active .single-brand-logo {
    text-align: center;
}

.brand-logo-active .single-brand-logo img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.brand-logo-active .single-brand-logo:hover img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

.brand-logo-border {
    border: 1px solid #f1f1f1;
    padding: 60px 50px 60px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brand-logo-border {
        padding: 60px 20px 60px 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-logo-border {
        padding: 60px 20px 60px 50px;
    }
}

@media only screen and (max-width: 767px) {
    .brand-logo-border {
        padding: 40px 0px 40px 0px;
    }
}

.brand-logo-active-2.owl-carousel .owl-item img {
    display: inline-block;
}

.brand-logo-active-2 .single-brand-logo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.brand-logo-active-2 .single-brand-logo:hover img {
    opacity: 1;
}

.brand-logo-padding {
    padding: 75px 70px;
}

@media only screen and (max-width: 767px) {
    .brand-logo-padding {
        padding: 40px 15px;
    }
}

@media only screen and (max-width: 767px) {
    .brand-logo-area.pb-70 {
        padding-bottom: 50px;
    }
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.brand-logo-area h2 {
    font-size: 2rem;
    font-weight: 500;
}

.teams h2 {
    font-size: 1.563rem;
    font-weight: 500;
}

.testimonial-area h2 {
    font-size: 1.563rem;
    font-weight: 500;
}

/* FAQ */

.faq-content .panel {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
}

.faq-content.panel-group .panel-heading {
    border: 0px;
    border-bottom: 1px solid #e4e4e4;
}

.faq-content .panel-heading {
    padding: 16px 0;
}

.faq-content .panel-title {
    font-size: 0.813rem;
    padding-right: 30px;
    position: relative;
}

.faq-content .panel-title > span.pull-right {
    position: absolute;
    right: 0;
    top: 0;
}

.faq-content .panel-heading em {
    font-size: 0.75rem;
    color: #626262;
}

.faq-content.panel-group .panel-heading + .panel-collapse > .list-group,
.faq-content.panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 0;
    padding: 25px 0;
}

/* contact us page */

.contactsec1 .main-contactform .form-control {
    background-color: #f4f4f5;
    border: 1px solid transparent;
    border-radius: 0;
    box-shadow: none;
    color: #7f7f7f;
    font-size: 1rem;
    font-weight: var(--font-weight);
    height: 60px;
    letter-spacing: 0.1em;
    line-height: 24px;
    padding-bottom: 11px;
    padding-left: 28px;
    padding-right: 30px;
    padding-top: 10px;
    width: 100%;
}

.main-contactform .btn {
    border-radius: 50px;
}

.main-contactform .form-group {
    position: relative;
    padding-top: 20px;
    margin: 0 auto;
}

.main-contactform .form-group:first-child:last-child {
    height: 100%;
}

.main-contactform .form-group:first-child:last-child .form-control {
    height: 100%;
}

.main-contactform .form-group textarea {
    resize: none;
}

.main-contactform .form-group .input-highlight {
    content: "";
    width: 0;
    height: 2px;
    background: var(--buttons);
    position: absolute;
    display: block;
    bottom: 0;
    transition: width 0.4s ease;
}

.main-contactform .form-group label {
    position: absolute;
    left: 8px;
    top: 27px;
    transition: all 0.4s ease;
    font-size: 1.125rem;
}

.main-contactform .form-group .form-control {
    border-radius: 5px;
    border-width: 0 0 2px 0;
}

.main-contactform .form-group .form-control:focus,
.main-contactform .form-group .form-control.hasValue {
    box-shadow: none;
    border-color: #ced4da;
}

.main-contactform .form-group .form-control:focus ~ .input-highlight,
.main-contactform .form-group .form-control.hasValue ~ .input-highlight {
    width: 100%;
}

.main-contactform .form-group .form-control:focus ~ label,
.main-contactform .form-group .form-control.hasValue ~ label {
    transform: scale(0.7);
    top: 0;
    left: 0;
}

.contactsec1 .buttons-type {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.contactsec1 .buttons-type .btn {
    padding: 10px 30px;
    background-color: var(--buttons);
    color: var(--button-text);
}

.iconsec .headerLine {
    margin: 20px auto;
    display: block;
    width: 160px;
    height: 2px;
    background: var(--buttons);
}

.iconsec .inner-iconsec {
    padding-top: 100px;
    padding-bottom: 70px;
}

@media (max-width: 767px) {
    .iconsec .inner-iconsec {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .icondiv {
        padding-top: 30px;
    }
}

.icondiv > .fas {
    color: var(--icon);
}

/* map */

.mapcontent {
    height: 600px;
}

.mapcontent iframe {
    width: 100%;
    height: 400px;
    border: 0px;
}

.mapcontent img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

@media (max-width: 1400px) {
    .mapcontent {
        height: auto;
    }
}

/* login page */
.pincode .close {
    background: transparent;
    border: none;
}

.close {
    opacity: 1.5 !important;
}

.close-btn span {
    background: #000;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0px;
    font-size: 1.5rem;
    font-weight: bold;
    width: 35px;
    height: 35px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    z-index: 1;
}

@media (max-width: 767px) {
    .close-btn span {
        right: 0px;
        top: 0px;
    }
}

.loginpopup.close-btn {
    display: contents !important;
}

.modal-content .nav-tabs a {
    display: block;
    float: left;
    width: 50%;
    padding: 0;
    text-align: center;
    color: #999 !important;
    background: #ddd;
    height: 73px;
    line-height: 73px;
}

.modal-content .nav-tabs {
    background: #eee;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    width: 100%;
    border-bottom: none;
}

.modal-content a.active {
    background: #fff;
    color: #777;
}

.modal-content .nav-tabs a.active {
    background: #fff !important;
    color: #777;
}

.login_section.modal-content .modal-body div input:not([type="checkbox"]) {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    padding: 15px;
    font-size: 0.875rem;
    height: auto;
}

.modal-content .modal-body .forgot-password {
    color: #999 !important;
}

.modal-content .modal-body.loginsec {
    padding: 30px;
}

.modal-content .register-btn {
    display: flex;
    margin-top: 30px;
}

.modal-content .register-btn button {
    display: inline-block;
    background: var(--buttons);
    color: white;
    margin: 0;
    border: 0;
    cursor: pointer;
    width: 50%;
    height: 46px;
    padding: 0;
}

.modal-content .register-btn button:nth-child(1) {
    border-radius: 3px 0 0 3px;
    background: #aaa !important;
    color: #fff;
}

.modal-content .regcancel {
    font-size: 1rem;
    font-weight: 400;
    text-shadow: none;
}

.modal {
    text-align: center;
}

.card-hide {
    display: none;
}

@media screen and (min-width: 268px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 25%;
    }
}
@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-content .nav-tabs1 {
    display: inline-block;
    background: var(--buttons);
    color: white;
    margin: 0;
    border: 0;
    cursor: pointer;
    width: 100%;
    height: 46px;
    padding: 0;
}

.modal-content .nav-tabs1 a {
    display: block;
    float: left;
    width: 100%;
    padding: 0;
    text-align: center;
    background: #ddd;
    color: #999;
    height: 46px;
    line-height: 46px;
}

.modal-content .forgotmodal {
    padding-left: 30px;
    padding-right: 30px;
}

.verifyotp {
    padding-left: 30px;
    padding-right: 30px;
}

.registerbody {
    padding: 30px !important;
}

.verifybody {
    padding: 30px !important;
}

.signupbody {
    padding: 30px !important;
}

.modal-dialog {
    display: inline-block;
    width: 100% !important;
    text-align: left;
    vertical-align: middle;
    min-height: inherit;
    margin: auto;
}

@media (max-width: 767px) {
    .quick_view.modal-dialog {
        margin: 0.5rem;
    }
}

.login-sec button {
    border: none;
    padding-left: 3px;
    font-size: 0.875rem;
}

.loginsec button {
    border: none;
}

.loginsec button:hover {
    background-color: #222222;
    color: #fff !important;
}

.btn-primary {
    border: none;
    background-color: var(--buttons);
    color: var(--button-text);
}

.btn:hover {
    background-color: #222222;
    color: #fff;
}

.btn-primary:hover {
    background-color: #222222;
    color: #fff;
}

/* width */
.iti__country-list::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.iti__country-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.iti__country-list::-webkit-scrollbar-thumb {
    background: var(--buttons);
    border-radius: 10px;
}

/* Handle on hover */
.iti__country-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* my profile */

.account-tab > .tab-title-bg {
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
}

.account-tab > span .sub-title {
    font-size: 1.125rem;
}

.account-tab .sub-title em {
    margin-right: 7px;
}

.account-sidebar .sub-title span {
    display: inline-block;
    height: 21px;
    margin-bottom: -5px;
    width: 21px;
}

.account-sidebar.account-tab .account-tab-inner {
    padding: 0px;
    background: #f5f5f5 none repeat scroll 0 0;
    color: #3A2525 !important;
}
.account-sidebar.account-tab-inner.account-tab-stap ul li a
{
    color: #3A2525 !important;
}

.account-sidebar.account-tab > .tab-title-bg {
    padding: 12px 15px;
}

.account-sidebar ul {
    width: 100%;
}

.account-sidebar ul li {
    border-bottom: 1px solid #dcdcdc;
    position: relative;
}

.account-sidebar ul > li > a em {
    margin-right: 10px;
}

.account-sidebar ul li:last-child {
    border-bottom: none;
}

.account-sidebar ul li a {
    padding: 15px 15px;
    display: inline-block;
    width: 100%;
}

.account-sidebar ul li a .fa {
    position: absolute;
    right: 15px;
    top: 17px;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}

.account-sidebar ul li.active a,
.account-sidebar ul li:hover a {
    background: var(--buttons) none repeat scroll 0 0;
    color: var(--button-text);
}

.account-sidebar ul li.active a .fa,
.account-sidebar ul li:hover a .fa {
    right: 0px;
}

.account-sidebar ul li.active a::after,
.account-sidebar ul li:hover a::after {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--buttons);
    border-style: solid;
    border-width: 23px 22px 26px 17px;
    top: 0px;
    content: "";
    right: -38px;
    position: absolute;
}

.account-content {
    display: none;
}

.account-content .heading-section {
    display: inline-block;
    width: 100%;
}

.account-content .heading-section .heading {
    padding: 2px 0px;
}

.account-content .heading-section .common-btn {
    margin-top: -4px;
}

.account-content p,
.address-box p {
    margin-bottom: 0px;
    margin-top: 5px;
}

.account-content hr {
    margin-top: 5px;
}

.address-box .inner-heading {
    margin-bottom: 10px;
}

.address-box table.table {
    border: 1px solid #3A2525;
}

.address-box .address__data {
    font-size: 1.1rem;
}

#formEditAddress input {
    border: 1px solid #efefef !important;
}

#formEditAddress .select2-container--default .select2-selection--single {
    border: 1px solid #efefef !important;
    height: 34px;
}

.dark-bg {
    background: #141414;
    color: #fff;
}

.heading-part {
    position: relative;
}

.checkout-section h2.heading {
    letter-spacing: 1px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .heading-bg h2.heading {
        margin-top: 20px !important;
    }
}

.heading-bg h2.heading {
    background: #f5f5f5;
    padding: 8px 17px;
}

h2.heading {
    font-size: 1.563rem;
    line-height: 32px;
    letter-spacing: 1px;
    position: relative;
}

table.table {
    border-top: none;
    margin-bottom: 0;
}

.cart-total-table .table thead tr th {
    padding: 15px 25px;
    vertical-align: middle;
}

.cart-total-table .table-responsive input[type="checkbox"],
[type="radio"] {
    vertical-align: middle;
    margin-right: 5px;
}

.commun-table .table thead tr th {
    background: #f5f5f5;
    border-bottom: none;
    padding: 22px 20px;
    border-top: none;
}

.commun-table .table tbody tr:first-child td {
    border-top: medium none;
}

.cart-total-table .table tbody tr td {
    padding: 10px 25px;
    vertical-align: middle;
}

#data-step1 {
    display: block;
}

#data-step2 {
    display: none;
}

.checkout-section .input-box {
    margin-bottom: 23px;
}

.main-form input,
.main-form textarea,
.main-form select {
    background: #fff;
    padding: 8px 15px;
    width: 100%;
    border: 1px solid #e1e1e1;
    min-height: 40px;
}

.select-dropdown fieldset {
    padding: 0;
    line-height: 0;
}

.nice-select.option-drop,
.nice-select.option-drop ul.list {
    width: 100%;
}

.sub-heading {
    font-size: 1.313rem;
    line-height: 32px;
    margin-bottom: 15px !important;
}

.checkout-section .check-box input[type="checkbox"] {
    margin: 5px 0 0;
    min-height: 1px;
    width: auto;
}

.checkbox + label,
.radio-button + label {
    cursor: pointer;
    line-height: 17px;
    padding-left: 25px;
    position: relative;
}

.check-box label {
    color: #222;
    display: inline;
    font-size: 0.938rem;
    font-weight: 400;
}

.checkout-section label {
    display: inline-block;
    font-weight: 700;
    margin-bottom: 5px;
    max-width: 100%;
}

.cart-item-table tbody tr td:last-child,
.cart-item-table thead tr th:last-child {
    text-align: center;
}

.cart-item-table thead tr th:first-child,
.cart-item-table tbody tr td:first-child {
    max-width: 110px;
}

.commun-table th ul li {
    text-align: left;
    float: left;
    margin-right: 30px;
}

.commun-table th span {
    float: left;
    font-size: 0.875rem;
    font-weight: 400;
    clear: both;
}

.price-box {
    display: inline-block;
}

.commun-table th ul li .price {
    font-size: 0.875rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1769px) {
    .price-box .price {
        font-size: 1rem !important;
    }
}

.price-box .price {
    font-size: 1.125rem;
    font-weight: 500;
    color: #222e3e;
}

.commun-table th ul li:last-child {
    margin-right: 0;
    float: right;
}

.dashboard-right {
    margin-bottom: 100px;
}

.welcome-text h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #2b2f4c;
    text-align: left;
    text-align: center;
    margin: 5px auto;
}

.dash-body {
    padding: 20px;
}

.dash-body h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: left;
    color: #2b2f4c;
    font-weight: 500;
}

.more-links {
    text-align: left;
    padding: 20px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #2b2f4c;
    display: block;
    border-top: 1px solid #efefef;
}

.more-links:hover {
    color: var(--buttons) !important;
}

.dash-body ul li {
    display: inline-block;
    margin-right: 10px;
}

.dash-body ul li:last-child {
    margin-right: 0;
}

.small-reward-dt {
    color: #fff;
    background: var(--buttons);
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 0.75rem;
    display: block;
}

.rewards-link5 {
    font-size: 0.875rem;
    font-weight: 500;
    color: #2b2f4c;
}

.rewards-link5:hover {
    color: var(--buttons) !important;
}

.short-desc {
    display: flex;
    width: 100%;
    padding: 4px 0;
}

.order-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #2b2f4c;
}

.order-title span {
    font-size: 0.75rem;
    display: inline-block;
    background: #2b2f4c;
    color: #fff;
    height: 18px;
    border-radius: 100%;
    width: 18px;
    text-align: center;
    font-weight: 700;
    margin-left: 5px;
    cursor: pointer;
}

.order-title span:hover {
    background: var(--buttons);
}

.short-desc p {
    margin-left: auto;
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    color: #3e3f5e;
}

.dash-order-list li {
    display: block !important;
    margin-right: 0 !important;
}

.wallet-body h2 {
    font-weight: 500;
}

.order-status {
    margin-left: auto;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--buttons);
}

[data-inverted][data-position~="top"][data-tooltip]:before {
    background: #2b2f4c !important;
}

[data-position="top right"][data-tooltip]:before {
    right: 0.1em !important;
}

[data-tooltip][data-inverted]:after {
    background: #2b2f4c !important;
    color: #fff;
}

[data-tooltip]:after {
    border: 1px solid #d4d4d5;
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    font-family: "Roboto", sans-serif;
}

.wallet-body {
    padding: 20px 20px 0;
}

.wallet-list {
    padding-top: 10px;
}

.wallet-list li {
    width: 50% !important;
    display: inline-block;
    margin-right: 0 !important;
    margin-bottom: 20px;
    margin-left: -2px;
}

.inner-wallet-links {
    font-size: 0.875rem;
    font-weight: 500;
    color: #2b2f4c;
    display: flex;
    align-items: center;
}

.inner-wallet-links em {
    font-size: 1.125rem;
    margin-right: 5px;
}

.newsletter-input {
    position: relative;
}

.newsletter-btn {
    background: var(--buttons);
    position: absolute;
    right: 2px;
    top: 3px;
    height: 35px;
    border: 0;
    width: 35px;
    border-radius: 3px;
    font-size: 1.125rem;
    color: #fff;
}

.newsletter-input .form-control {
    font-size: 0.875rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #2b2f4c;
    border: 1px solid #efefef;
    position: relative;
    padding: 0 3rem 0 1rem;
    height: 40px;
}

/* --- Dashboard My Orders --- */
.Order__otp {
    color: var(--buttons) !important;
    font-size: 1rem !important;
}
.dash-bg-right-title h6 {
    border-bottom: 1px solid #efefef;
    padding: 15px 20px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #2b2f4c;
    margin-bottom: 0;
}

.order-dash-desc {
    padding: 20px;
}

.order-dash-desc li {
    display: inline-block;
    vertical-align: top;
}

.order-img {
    background: #f9f9f9;
    padding: 10px;
    border: 1px solid #efefef;
    border-radius: 5px;
}

.order-img img {
    width: 50px;
    height: 50px;
}

.order-desc {
    margin-left: 15px;
}

.order-desc h4 {
    font-size: 1rem;
    color: #2b2f4c;
    margin-bottom: 5px;
    line-height: 24px;
    text-align: left;
    font-weight: 700;
}
.order__status {
    font-weight: bold;
}
.order-desc p {
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    margin-bottom: 7px;
}

.track-order {
    padding: 20px 0 20px;
}

.track-order h4 {
    margin-left: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2b2f4c;
    text-align: left;
}

@media (max-width: 479px) {
    .order_track_page.main-content {
        padding: 0;
    }
}

.order_track_page .dashboard-right table > tbody > tr > td:nth-child(2) {
    text-align: right;
}

.order_track_page .bs-wizard {
    border-bottom: none;
}
/*Form Wizard*/
.bs-wizard {
    border-bottom: solid 1px #e0e0e0;
    padding: 0;
    margin-top: 0;
    display: flex;
    width: 100%;
}

.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
    width: 100%;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    font-size: 0.875rem;
    margin-bottom: 5px;
    font-weight: 400;
}

@media (max-width: 384px) {
    .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
        font-size: 0.699rem;
    }
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
    color: #999;
    font-size: 0.875rem;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: var(--buttons);
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: " ";
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50px;
    position: absolute;
    border: 1px solid lightgrey;
    top: 8px;
    left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 8px;
    box-shadow: none;
    margin: 22px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    box-shadow: none;
    background: var(--buttons);
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #f2f3f8;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}

.progress {
    background-color: #f2f3f8 !important;
}

/*END Form Wizard*/

.call-bill {
    padding: 15px 20px;
    display: flex;
    border-top: 1px solid #efefef;
    align-items: center;
}

.delivery-man {
    font-size: 0.875rem;
    color: #2b2f4c;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
}

.delivery-man a {
    margin-left: 5px;
    color: var(--buttons);
}

.delivery-man a:hover {
    color: var(--a-hover) !important;
    text-decoration: underline !important;
}

.order-bill-slip {
    margin-left: auto;
}

.bill-btn {
    display: block;
    background: var(--buttons);
    color: var(--button-text);
    padding: 10px 15px;
    font-weight: 500;
    border-radius: 5px;
}

/* --- My Rewards --- */

.reward-body-dtt {
    padding: 8.6rem 0;
    text-align: center;
}

.reward-img-icon {
    width: 80px;
    height: 80px;
    display: inline-block;
    background: #f9f9f9;
    border-radius: 100%;
    border: 2px solid #efefef;
    position: relative;
}

.my__account .reward-img-icon img{
    width: 80px;
    height: 80px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 100%;
}

.reward-img-icon img {
    text-align: center;
}

.rewrd-title {
    display: block;
    margin-top: 20px;
    font-weight: 500;
    color: #3e3f5e;
    font-size: 0.875rem;
    text-align: center;
    text-overflow: ellipsis;
}

.cashbk-price {
    color: #2b2f4c;
    margin-top: 12px;
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 0;
    text-align: center;
}

.reward-body-all {
    display: flex;
    text-align: center;
}

.reward-body-all li {
    display: inline-block;
    width: 33.333%;
    padding: 30px 10px;
    border-right: 1px solid #efefef;
}

.reward-body-all li:last-child {
    border-right: 0;
}

.tt-icon {
    width: 45px;
    height: 45px;
    display: inline-block;
    background: var(--buttons);
    border-radius: 100%;
    padding: 11px 0;
}

.tt-icon em {
    color: #fff;
    font-size: 1.5rem;
}

.total-rewards span {
    display: block;
    margin-top: 20px;
    font-weight: var(--font-weight);
    color: #3e3f5e;
    font-size: 0.875rem;
    text-align: center;
}

.total-rewards h4 {
    color: #2b2f4c;
    margin-top: 12px;
    font-weight: var(--font-weight);
    font-size: 1.125rem;
    margin-bottom: 0;
    text-align: center;
}

.date-reward {
    display: inline-block;
    margin-top: 20px;
    font-weight: 500;
    background: #f9f9f9;
    border: 1px dashed #efefef;
    padding: 2px 15px;
    border-radius: 5px;
    font-size: 0.75rem;
    color: #2b2f4c;
}

.rewards-coupns {
    position: relative;
}

.top-coup-code {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 0.75rem;
    color: #fff;
    background: var(--buttons);
    border-radius: 0 5px 0 3px;
    cursor: pointer;
    border: 1px dashed #fff;
}

.rotate-img {
    transform: rotate(260deg);
}

.table-responsive {
    border: 0;
    border-radius: 0 0 5px 5px;
    margin-bottom: 0;
    overflow-x: inherit;
}

table.table.offer-table {
    margin-bottom: 0px;
}

.offer-table {
    height: auto;
    overflow: hidden;
    border-radius: 0;
}

.offer-table thead {
    font-weight: 500;
    padding: 14px !important;
    border-radius: 0;
    color: #2b2f4c !important;
}

.offer-table thead tr th:first-child {
    border-bottom-left-radius: 0;
}

.offer-table thead tr th {
    color: #2b2f4c;
    font-size: 0.875rem;
    background-color: #dcdcdc;
    font-weight: 500;
}

.offer-table tfoot {
    font-weight: 400;
    padding: 14px !important;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    color: #2b2f4c !important;
}

.offer-table tfoot td {
    color: #fff;
    font-size: 0.875rem;
    background-color: #2b2f4c;
    padding: 0.75rem !important;
}

.offer-table.earning-table td {
    padding: 1rem !important;
}

table {
    margin: 0px auto 0px;
    font-size: 0.875rem;
    width: 100%;
}

.offer-table tbody {
    background: #fff;
}

.offer-table td,
.offer-table th {
    border-top: 1px solid #efefef !important;
}

.offer-table td {
    padding: 1.5rem 0.75rem !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.offer-table th:first-child {
    border-top: 0 !important;
}

.offer-table thead th {
    vertical-align: bottom;
    border-bottom: 0 !important;
}

.course_active {
    color: #ed2a26;
}

.offer-table tbody tr td {
    font-size: 0.875rem;
    vertical-align: middle;
    font-weight: 400;
    color: #3e3f5e;
}

.offer_active {
    color: var(--buttons);
    font-weight: 700;
}

.add-cash-body {
    padding: 20px;
    color: #3A2525;
}

.history-body {
    height: 360px;
    overflow-y: auto;
}

.history-list li {
    display: block;
    padding: 20px;
    border-bottom: 1px solid #efefef;
}

.history-list li:last-child {
    border-bottom: 0;
}

.purchase-history {
    display: flex;
    align-items: center;
}

.purchase-history-right {
    margin-left: auto;
    text-align: center;
}

.purchase-history-left h4 {
    font-size: 1rem;
    color: #2b2f4c;
    margin-bottom: 8px;
    text-align: left;
    font-weight: 500;
}

.purchase-history-left p {
    font-size: 0.875rem;
    font-weight: 500;
    color: #3e3f5e;
    text-align: left;
    margin-bottom: 8px;
    line-height: 24px;
}

.purchase-history-left p ins {
    text-decoration: none;
    text-transform: uppercase;
    color: var(--buttons);
}

.purchase-history-left span {
    font-weight: 400;
    font-size: 0.813rem;
    color: #3e3f5e;
    text-align: left;
    display: block;
}

.purchase-history-right span {
    display: block;
    font-size: 1rem;
    font-weight: var(--font-weight);
    color: var(--buttons);
    text-align: center;
}

.purchase-history-right a {
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 9px;
    display: block;
    color: #2b2f4c;
}

.purchase-history-right a:hover {
    color: var(--a-hover) !important;
}

.no_history {
    padding-bottom: 4.375rem;
}

.dash-bg-right1 {
    margin-top: 30px;
    border-radius: 3px;
    background: #f5f5f5;
    border: 1px solid #eeeeee;
    box-shadow: 0 1px 2px 0 #e9e9e9;
}

#editAddress hr {
    color: #bababc;
}

#addAddress hr {
    color: #bababc;
}

#formAddAddress .select2-container--default .select2-selection--single,
#formAddAddress input {
    border: 1px solid #efefef !important;
}

#formAddAddress .select2-container .select2-selection--single {
    height: 34px;
}

.dash-bg-right1:first-child {
    margin: auto;
}

.total-checkout-group {
    padding: 20px 20px 0;
    border-top: 2px solid #efefef;
    margin-top: 10px;
}

.cart-total-dil {
    display: flex;
    width: 100%;
    align-items: center;
}

.cart-total-dil h4 {
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: #2b2f4c;
    line-height: 24px;
}

.cart-total-dil span {
    color: #222222;
    font-size: 1rem;
    margin-left: auto;
    font-weight: 400;
    line-height: 24px;
}

.main-total-cart {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #e5e5e5;
}

.main-total-cart h2 {
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: var(--font-weight);
    color: #2b2f4c;
    line-height: 24px;
}

.main-total-cart span {
    color: var(--buttons);
    font-size: 1.125rem;
    margin-left: auto;
    font-weight: 700;
    line-height: 24px;
}

.user-item em {
    margin-right: 10px;
    font-size: 1.125rem;
}

.dash-bg-right-title h4 {
    border-bottom: 1px solid #efefef;
    padding: 15px 20px;
    font-size: 1rem;
    font-weight: var(--font-weight);
    color: #2b2f4c;
}

label.control-label {
    font-size: 0.75rem;
    font-weight: 500;
    color: #2b2f4c;
}

.control-label {
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    color: #2b2f4c;
    margin-bottom: 0.8rem;
}

.ui[class*="left icon"].card-detail-desc {
    width: 100%;
}

.ui[class*="left icon"].card-detail-desc.input > input {
    padding-left: 1em !important;
    padding-right: 1em !important;
    height: 34px;
}

.ui.input > input {
    font-weight: 500 !important;
    background: #fff;
    border: 1px solid #efefef !important;
    color: #2b2f4c !important;
    border-radius: 5px !important;
}

.ui.input > input:focus {
    border: 1px solid var(--buttons) !important;
}

.ui.selection.active.dropdown.form-dropdown {
    border-color: var(--buttons) !important;
}

.ui.selection.active.dropdown.form-dropdown .menu {
    border-color: var(--buttons) !important;
}

.ui.dropdown.form-dropdown .menu > .item {
    color: #2b2f4c !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
}

.ui.selection.dropdown.form-dropdown:hover {
    border-color: #efefef !important;
}

.ui.selection.dropdown.form-dropdown {
    color: #2b2f4c !important;
    font-weight: 500 !important;
    border: 1px solid #efefef !important;
}

.ui.selection.visible.dropdown.form-dropdown > .text:not(.default) {
    font-weight: 500 !important;
}

.ui.search .card-inputfield {
    border-radius: 0.3rem !important;
    width: 100%;
}

.monthwrap > select {
    background: #fff;
    border: 1px solid #efefef !important;
    color: grey !important;
    padding-top: 6px;
    padding-bottom: 6px;
}

.img-add {
    display: block;
}

.img-add [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
    float: left;
}

.img-add [type="file"] + label {
    cursor: pointer;
    outline: none;
    transition: all 0.3s;
    vertical-align: middle;
    margin: 0;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: var(--buttons);
    color: #fff;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    border: 1px solid #ffff;
    font-size: 0.75rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

/* cart  */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .shopping_cart_area {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .shopping_cart_area {
        margin-top: 60px;
    }
}

.header_cart_page {
    border-bottom: 1px solid #ededed;
}

.other_bread {
    padding-top: 41px;
    padding-bottom: 41px;
}

.table_description {
    border: 1px solid #ededed;
    margin-bottom: 70px;
    margin-top: 2px;
}

@media only screen and (max-width: 767px) {
    .table_description {
        margin-bottom: 60px;
    }
}

.table_description .cart_page-content table {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .table_description .cart_page-content table thead {
        display: none;
    }
}

.table_description .cart_page-content table thead tr th {
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    padding: 10px;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .table_description .cart_page-content table tbody tr {
        display: block;
        border-bottom: 1px solid #ededed;
        padding: 15px 0;
    }
}

.table_description .cart_page-content table tbody tr td {
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    text-align: center;
    padding: 10px;
}

@media only screen and (max-width: 767px) {
    .table_description .cart_page-content table tbody tr td {
        display: block;
        width: 100% !important;
        margin: 0 auto;
        border: 0;
    }
}

.table_description .cart_page-content table tbody tr td.header_product_remove {
    width: 100px;
}

.table_description .cart_page-content table tbody tr td.header_product_remove a {
    font-size: 1.25rem;
    color: #222222;
}

.table_description .cart_page-content table tbody tr td.header_product_remove a:hover {
    color: var(--buttons);
}

.table_description .cart_page-content table tbody tr td.header_product_thumb {
    width: 150px;
    padding: 20px;
}

.table_description .cart_page-content table tbody tr td.header_product_thumb img {
    height: 100px;
    width: 100px;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

@media (min-width: 992px) and (max-width: 1400px) {
    .table_description .cart_page-content table tbody tr td.header_product_thumb {
        padding: 15px;
        width: 25%;
        height: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .table_description .cart_page-content table tbody tr td.header_product_thumb {
        padding: 15px;
        width: 30%;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .table_description .cart_page-content table tbody tr td.header_product_thumb {
        padding: 0 30px;
        width: 320px !important;
    }
}

@media only screen and (max-width: 479px) {
    .table_description .cart_page-content table tbody tr td.header_product_thumb {
        padding: 0 30px;
        width: 100% !important;
    }
}

.table_description .cart_page-content table tbody tr td.header_product_name {
    width: 230px;
}

.table_description .cart_page-content table tbody tr td.header_product_name a {
    color: #222222;
    text-transform: capitalize;
    font-size: 0.875rem;
    font-weight: 400;
}

.table_description .cart_page-content table tbody tr td.header_product_name a:hover {
    color: var(--buttons);
}

.table_description .cart_page-content table tbody tr td.header_product-price {
    width: 150px;
    color: #222222;
    font-size: 1rem;
    font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .table_description .cart_page-content table tbody tr td.header_product-price {
        width: 140px;
    }
}

.table_description .cart_page-content table tbody tr td.header_product_quantity {
    width: 218px;
}

.table_description .cart_page-content table tbody tr td.header_product_quantity label {
    font-weight: 500;
    margin-right: 5px;
}

.table_description .cart_page-content table tbody tr td.header_product_quantity input {
    width: 60px;
    height: 40px;
    padding: 0 5px 0 10px;
    background: none;
    border: 1px solid #ededed;
}

.table_description .cart_page-content table tbody tr td.header_product_total {
    width: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .table_description .cart_page-content table tbody tr td.header_product_total {
        width: 135px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .table_description.wishlist .cart_page-content table tbody tr td.header_product_total {
        min-width: 150px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .table_description.wishlist .cart_page-content table tbody tr td.header_product_total {
        min-width: 150px;
    }
}

.cart_page-content table thead tr:last-child th,
.table_description table tbody tr td:last-child {
    border-right: 0;
}

.cart-header {
    background-color: #f5f5f5;
    color: #3A2525 !important;
}

.cart_submit {
    padding: 12px;
}

.cart_submit > a.cart_shopping {
    background-color: var(--buttons);
    padding: 5px 10px;
    color: var(--button-text);
}

.cart_submit > a.cart_shopping:hover {
    background-color: #212121;
    color: #fff;
}

.cart_submit > button {
    float: right;
}

.continue_shopping {
    display: inline-block;
}

.continue_shopping .btn-shopping {
    background-color: var(--buttons);
    color: #fff;
    border: 0;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    height: 38px;
    line-height: 18px;
    padding: 10px 15px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 3px;
}

@media only screen and (max-width: 767px) {
    .cart_submit {
        text-align: center;
    }
}

.cart_submit button {
    background: #222222;
    border: 0;
    color: #ffffff;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    height: 38px;
    line-height: 18px;
    padding: 10px 15px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 3px;
}

.cart_submit button:hover {
    background: var(--buttons);
}

.cart-main-content .cart-page-title {
    color: #212121;
    text-align: left;
    font-size: 1.5rem;
    line-height: 16px;
    font-style: normal;
    text-transform: none;
    margin: 0 0 30px;
    font-weight: var(--font-weight);
}

.cart-main-content .cart-shiping-update-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 60px;
}

.cart-main-content .cart-shiping-update-wrapper .cart-clear > button {
    border: medium none;
    cursor: pointer;
    margin-right: 27px;
    transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
    .cart-main-content .cart-shiping-update-wrapper {
        display: block;
        padding: 30px 0 15px;
    }
}

.cart-tax,
.discount-code-wrapper,
.grand-total-content {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 0px;
    padding: 10px 20px 10px;
    margin-bottom: 10px;
}

.cart-tax .title-wrap,
.discount-code-wrapper .title-wrap,
.grand-total-content .title-wrap {
    position: relative;
}

.cart-tax .title-wrap::before,
.discount-code-wrapper .title-wrap::before,
.grand-total-content .title-wrap::before {
    background-color: #e3e1e1;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 13px;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: 1;
}

.cart-tax .title-wrap h4.cart-bottom-title,
.cart-tax .title-wrap .cart-bottom-title.h4,
.discount-code-wrapper .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap .cart-bottom-title.h4,
.grand-total-content .title-wrap h4.cart-bottom-title,
.grand-total-content .title-wrap .cart-bottom-title.h4 {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0;
    padding-right: 18px;
    background-color: #f5f5f5;
    position: relative;
    z-index: 1;
    color: #212121;
}

.cart-tax .tax-wrapper,
.discount-code-wrapper .tax-wrapper,
.grand-total-content .tax-wrapper {
    margin-top: 22px;
}

.tax-select-wrapper {
    margin: 15px 0 0;
}

.tax-select-wrapper select {
    width: 100%;
    height: 42px;
    line-height: 42px;
    border: 1px solid #f5f5f5;
    background: #fff;
    padding-left: 20px;
    border-radius: 0px;
}

.tax-select-wrapper input {
    width: 100%;
    height: 42px;
    line-height: 42px;
    border: 1px solid #f5f5f5;
    background: #fff;
    padding-left: 20px;
    border-radius: 0px;
}

.tax-select-wrapper .tax-select {
    margin-bottom: 20px;
}

.tax-select-wrapper .tax-select label {
    color: #212121;
    font-size: 0.875rem;
    margin: 0 0 10px;
}

.tax-select-wrapper button.cart-btn-2 {
    background: var(--buttons);
    border-radius: 0px;
    color: #fff;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    line-height: 1;
    padding: 18px 58px;
    text-transform: uppercase;
}

.tax-select-wrapper button.cart-btn-2:hover {
    color: #fff;
    background-color: var(--dark-mode);
}

.discount-code-wrapper .title-wrap {
    margin-bottom: 22px;
}

.discount-code-wrapper .discount-code p {
    margin: 0 0 15px;
}

.discount-code-wrapper .discount-code input {
    background: #fff;
    border: 1px solid #f5f5f5;
    height: 45px;
    margin-bottom: 30px;
    padding-left: 10px;
    outline: none;
    width: 100%;
    border-radius: 0px;
}

.discount-code-wrapper .discount-code button.cart-btn-2 {
    background: var(--buttons);
    border: none;
    border-radius: 0px;
    color: #fff;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    line-height: 1;
    padding: 18px 58px;
    text-transform: uppercase;
}

.discount-code-wrapper .discount-code button.cart-btn-2:hover {
    color: #fff;
    background-color: var(--dark-mode);
}

.grand-total-content h5,
.grand-total-content .h5 {
    font-size: 0.875rem;
    margin: 36px 0 27px;
    font-weight: 400;
}

.grand-total-content h5 span,
.grand-total-content .h5 span {
    float: right;
    font-size: 1.125rem;
    font-weight: 700;
}

.grand-total-content .total-shipping {
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    margin: 0 0 27px;
    padding: 28px 0;
}

.grand-total-content .total-shipping h5,
.grand-total-content .total-shipping .h5 {
    font-size: 0.875rem;
    margin: 0;
}

.grand-total-content .total-shipping ul {
    padding: 19px 0 0 0;
}

.grand-total-content .total-shipping ul li {
    color: #474747;
    list-style: outside none none;
    margin: 0 0 6px;
    display: block;
}

.grand-total-content .total-shipping ul li input {
    background: #e9e9e9 none repeat scroll 0 0;
    border: 1px solid #d7d7d7;
    border-radius: 5px !important;
    color: #474747;
    cursor: pointer;
    height: 13px;
    margin-right: 10px;
    padding: 0;
    position: relative;
    top: 2px;
    width: 13px;
}

.grand-total-content .total-shipping ul li span {
    float: right;
}

.grand-total-content .grand-total-content-title {
    color: var(--buttons);
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0 0 25px;
}

.grand-total-content .grand-total-content-title span {
    float: right;
}

.grand-total-content a {
    background-color: var(--buttons);
    color: var(--button-text);
    border-radius: 0px;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1;
    padding: 18px 10px 17px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}

.grand-total-content a:hover {
    background-color: #212121;
    color: #fff;
}

.cart-shiping-update-wrapper .cart-clear > a,
.cart-shiping-update-wrapper .cart-shiping-update > a {
    background-color: #f2f2f2;
    border-radius: 0;
    color: #212121;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    line-height: 1;
    padding: 18px 63px 17px;
    text-transform: uppercase;
}

.cart-shiping-update-wrapper .cart-clear > a {
    background-color: var(--buttons);
    color: #fff;
}

td.header_product_stock {
    width: 15%;
}

.order-area-content1 h3,
.order-area-content1 .h3 {
    position: relative;
    top: 20px;
    display: flex;
    justify-content: center;
}

.order-area-content1 .order-wrap-content {
    padding: 15px 0;
}

.order-area-content1 .order-wrap-content .order-product-info .order-top-content ul {
    display: flex;
    justify-content: space-between;
}

.order-area-content1 .order-wrap-content .order-product-info .order-top-content ul li {
    font-size: 1rem;
    font-weight: var(--font-weight);
    list-style: outside none none;
    color: #212121;
}

.order-area-content1 .order-wrap-content .order-product-info .order-middle-content {
    border-bottom: 1px solid #dee0e4;
    border-top: 1px solid #dee0e4;
    margin: 29px 0;
    padding: 19px 0 18px;
}

.order-area-content1 .order-wrap-content .order-product-info .order-middle-content li {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
}

.order-area-content1 .order-wrap-content .order-product-info .order-bottom-content ul {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.order-area-content1 .order-wrap-content .order-product-info .order-bottom-content ul li.order-shipping {
    font-size: 1rem;
    color: #212121 !important;
    font-weight: var(--font-weight);
}

.order-area-content1 .order-wrap-content .order-product-info .order-total-content {
    border-bottom: 1px solid #dee0e4;
    border-top: 1px solid #dee0e4;
    margin: 18px 0 33px;
    padding: 17px 0 19px;
}

.order-area-content1 .order-wrap-content .order-product-info .order-total-content ul {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.order-area-content1 .order-wrap-content .order-product-info .order-total-content ul li {
    font-weight: var(--font-weight);
    color: var(--buttons);
    font-size: 1rem;
    list-style: outside none none;
}

.order-area-content1 .order-wrap-content .order-product-info .order-total-content ul li.order-total {
    font-weight: var(--font-weight);
    color: #212121;
    font-size: 1.125rem;
}

@media only screen and (max-width: 767px) {
    .order-area-content1 .order-wrap-content {
        padding: 38px 30px 44px;
    }
}

.order-area-content1 .payment-accordion {
    margin: 0 0 16px;
}

.order-area-content1 .payment-accordion:last-child {
    margin: 0 0 0;
}

.order-area-content1 .payment-accordion h4 a,
.order-area-content1 .payment-accordion .h4 a {
    color: #212121;
    font-size: 1rem;
    margin: 0;
    font-weight: var(--font-weight);
}

.order-area-content1 .payment-accordion .panel-body {
    padding: 5px 0 0 0;
}

.order-area-content1 .payment-accordion .panel-body p {
    padding: 0 0 0 10px;
    font-size: 0.875rem;
    color: #474747;
    line-height: 24px;
}

.order-area-content1 .Place-order {
    position: relative;
    bottom: 30px;
    display: flex;
    justify-content: center;
}

.order-area-content1 .Place-order a {
    background-color: var(--buttons);
    color: #fff;
    display: block;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1;
    padding: 18px 20px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 2px;
    z-index: 9;
}

.order-area-content1 .Place-order a:hover {
    background-color: #212121;
}

.open-checkout-toggle,
.open-checkout-toggle2 {
    display: none;
}

/* newsletter area css here*/
.popup_box {
    background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    visibility: hidden;
    width: 100%;
    z-index: 9999999;
}

.popup_box .popup_content {
    background-color: #fff;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 500px;
}

@media (max-width: 767px) {
    .popup_box .popup_content {
        width: 300px;
    }
}

.popup_box .popup_content.newsletter {
    width: 800px;
}

.popup_box .popup_content.newsletter .content img {
    width: 350px;
    height: 370px;
}

@media (max-width: 991px) {
    .popup_box .popup_content.newsletter .content img {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .popup_box .popup_content.newsletter {
        width: 70%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .popup_box .popup_content.newsletter {
        width: 90%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.popup_box .popup_content.newsletter .content {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .popup_box .popup_content.newsletter .content {
        padding: 30px 30px 15px 30px;
        height: auto;
    }
}

.popup_box .popup_content.newsletter .content .wrapper1 {
    width: 100%;
}

.popup_box .popup_content.newsletter .content .wrapper1 h2 {
    font-size: 1.875rem;
    text-transform: uppercase;
    margin: 0 0 30px;
}

.box-content .newletter-label {
    width: 60%;
    margin-bottom: 36px;
}

@media (max-width: 767px) {
    .box-content .newletter-label {
        width: auto;
    }
}

.box-content label {
    font-weight: 400;
    font-size: 0.875rem;
}

#subscribe-form form {
    width: 340px;
    margin: 0 auto;
    position: relative;
}

@media (max-width: 767px) {
    #subscribe-form form {
        width: auto;
    }
}

#subscribe-form form input {
    background: #ebebeb;
    padding: 0 127px 0 20px;
    height: 45px;
    border: 0;
    width: 100%;
    color: #747474;
    margin-bottom: 0;
}

#subscribe-form #notification {
    color: #f00;
}

#subscribe-form form a.subscribe-btn-outline {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 110px;
    background: var(--buttons);
    cursor: pointer;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: var(--font-weight);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    line-height: 45px;
}

.box-content .subscribe-bottom {
    margin-top: 20px;
}

.box-content .subscribe-bottom #newsletter_popup_dont_show_again {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}

.box-content .subscribe-bottom label {
    margin: 0;
    font-size: 0.813rem;
}

.popup_box .popup_close {
    color: #ffffff;
    cursor: pointer;
    display: block;
    text-align: center;
    text-decoration: none;
    background: #000;
    width: 53px;
    height: 25px;
    line-height: 27px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.813rem;
    font-weight: 700;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.popup_box .popup_close:hover {
    background: #cc0000;
}

/* quick view */

/* pop-up modal */
.product_navactive.owl-carousel .owl-nav {
    display: block !important;
}

.product_navactive.owl-carousel .owl-nav div {
    position: absolute;
    background: #f2f2f2;
    border-radius: 3px;
    color: #333;
    height: 32px;
    top: 50%;
    transform: translatey(-50%);
    width: 32px;
    text-align: center;
    left: -7px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
}

.product_navactive.owl-carousel:hover .owl-nav div {
    opacity: 1;
    visibility: visible;
}

.product_navactive.owl-carousel .owl-nav div.owl-next {
    right: -7px;
    left: auto;
}

.tab-content > .tab-pane.active {
    display: block;
    height: auto;
    opacity: 1;
    overflow: visible;
}

.modal_tab_img {
    text-align: center;
    margin-bottom: 10px;
}

.modal_tab_img img {
    margin: 0 auto;
    height: 40vh;
}

.modal_tab_button ul li a {
    padding: 0;
    border: 1px solid #ededed;
    margin: 0 2px;
}

.quick_view .modal-content button.close {
    position: absolute;
    right: 15px;
    width: 35px;
    height: 35px;
    display: block;
    border: 1px solid #ededed;
    top: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.125rem;
    z-index: 9;
}

.modal-dialog.modal-dialog-centered {
    min-width: 1100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .modal-dialog.modal-dialog-centered {
        min-width: 1000px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .modal-dialog.modal-dialog-centered {
        min-width: 950px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .modal-dialog.modal-dialog-centered {
        min-width: 730px;
    }
}

@media only screen and (max-width: 767px) {
    .modal-dialog.modal-dialog-centered {
        min-width: 90%;
    }
}

@media (max-width: 768px) {
    .modal-dialog-centered .product_details .owl-nav {
        bottom: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .product_details {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.product_details {
    margin-top: 50px;
    margin-bottom: 50px;
}

.product_details .owl-nav {
    position: relative;
    bottom: 60px;
}

.product_details .owl-next {
    position: absolute;
    right: 0;
}

.product-thumb {
    margin-top: 10px;
}

.product-thumb img {
    height: 150px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .product-thumb img {
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .product-thumb img {
        height: 150px;
    }
}

@media (max-width: 767px) {
    .product-thumb img {
        height: auto;
    }

    .product_details .owl-nav {
        bottom: 60px;
    }
}

@media (max-width: 991px) {
    .product-thumb {
        margin-bottom: 20px;
    }
}

.product-slider {
    margin-bottom: 20px;
}

#img-1 {
    border: 1px solid #ededed;
}

#img-1 img {
    height: 500px;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    cursor: pointer;
}

.product_navactive.owl-carousel {
    display: block;
}

/*mini cart css here*/
.mini_cart::-webkit-scrollbar {
    width: 5px;
}

.mini_cart::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mini_cart::-webkit-scrollbar-thumb {
    background-color: var(--button);
    outline: 1px solid slategrey;
}

.mini_cart_wrapper {
    position: relative;
}

.mini_cart_wrapper .table > :not(:last-child) > :last-child > * {
    border-bottom-color: inherit !important;
}
.cart_gallery table.table {
    border-color: #eee !important;
    border: none;
}

@media only screen and (max-width: 767px) {
    .mini_cart {
        min-width: 300px;
        height: 100%;
        padding: 18px 18px 33px;
    }
}

@media only screen and (max-width: 767px) {
    .cart_info a {
        font-size: 0.813rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_total span {
        font-size: 0.813rem;
    }
}

@media only screen and (max-width: 767px) {
    .cart_total span {
        font-size: 0.813rem;
    }
}

/*mini cart css end*/

.table tr,
.table tr td {
    vertical-align: middle;
}

.main_header a,
.main_header button,
.main_header img,
.main_header input,
.main_header span {
    transition: all 0.3s ease 0s;
}

.main_header a {
    text-decoration: none;
    cursor: pointer;
}

.innersubtotal {
    width: 100%;
    float: right;
}

.mini_cart-subtotal .product-name {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
}

.itemside {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.itemside .aside {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.img-sm {
    object-fit: contain;
    width: 80px;
    height: 80px;
}

.itemside .info {
    padding-left: 15px;
    padding-right: 7px;
}

.minicartinfo {
    width: 100%;
}

.itemside .title {
    display: block;
    margin-bottom: 5px;
    color: #212529;
}

.small {
    text-align: left;
    font-size: 0.875rem;
    padding: 3px;
}

.price-wrap {
    padding-right: 2px;
    padding-bottom: 10px;
}

.cartEdit {
    display: none;
}

#myTable .form-control {
    width: auto;
    display: inline-block;
}

.button-container .form-control {
    max-width: 80px;
    text-align: center;
    display: inline-block;
    margin: 0px 5px;
}

.sep_cart .button-container .form-control {
    margin: 0;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
}

.cart-qty-plus,
.cart-qty-minus {
    width: 38px;
    height: 38px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #000;
}

.main_header button {
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}

.price {
    font-size: small;
    color: #212529;
}

var {
    font-style: normal;
}

.checkoutbtn {
    display: flex;
    margin: auto;
    position: sticky;
    left: 0;
    bottom: 0;
    right: 0;
}

.checkoutbtn a {
    width: 100%;
    margin-right: 5px;
}

.mini_cart-subtotal {
    display: flex;
    border-top: none !important;
}
.header_account_list{
    color: #3A2525 !important;
}
.track_order_btn,.hover-btn:hover
{
 background-color: #000;
}
.header_account_list.register ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.header_account_list.register ul li:nth-child(2),
.header_account_list.register ul li:nth-child(1) {
    border-top: none;
}

.header_account_list.register ul li a {
    display: block;
    font-size: 0.938rem;
    text-transform: capitalize;
    position: relative;
    color: #000;
    line-height: 50px;
}

.header_account_area .header_account_list .profile__name {
    color: #3A2525 !important;
}

@media (min-width: 576px) and (max-width: 991px) {
    .profile__name {
        line-height: 1.5 !important;
    }
}

.header_account_list .fas {
    color: var(--buttons);
}

.header_account_list > ul > li:hover ul.myaccount {
    visibility: visible;
    max-height: 360px;
    padding: 24px 20px;
    overflow: auto;
}

.header_account_list > ul > li ul.myaccount {
    position: absolute;
    min-width: 250px;
    padding: 0 20px;
    background: #ffffff;
    box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
    max-height: 0;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 99;
    text-align: left;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.header_account_list > ul > li:hover ul.myaccount::-webkit-scrollbar {
    width: 5px;
}

.header_account_list > ul > li:hover ul.myaccount::-webkit-scrollbar-thumb {
    background-color: var(--buttons);
    outline: 1px solid slategrey;
}

.my-profile-img > img {
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    display: inline-block;
    height: 50px;
    width: 50px;
}

.account-profile {
    cursor: default;
    position: absolute;
    margin-left: 10px;
}

.add-to-fav button[type="button"] {
    border: none !important;
    border-color: transparent !important;
    color: #adb5bd !important;
    font-size: 1rem;
    overflow: hidden;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
    display: block;
}

.error-show {
    display: block;
}

.error-hide {
    display: none;
}

.single_p {
    text-decoration: line-through;
}

.address-show {
    display: block;
}

.address-hide {
    display: none;
}

.edit-delete {
    float: right !important;
}

.add_new_address_btn {
    border-radius: 0px;
    padding: 15px 0 0;
    margin-bottom: 10px;
}

.visible #addAddress {
    display: block;
}

.select2-container,
.select2-dropdown,
.select2-search,
.select2-results {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.sweet-alert button {
    background-color: var(--buttons) !important;
}

/* flash sales */

.inner_flash_sales .deals_product_inner {
    border: none;
    padding: 0;
}

.inner_flash_sales .flash_boder {
    border: 1px solid #eee;
}

.inner_flash_sales .deals_product_inner .addto_cart_btn {
    margin: 10px auto;
}

.inner_flash_sales .product_thumb {
    height: 300px;
    width: 300px;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    position: relative;
}

.inner_flash_sales .product_carousel_content .product_thumb a img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.alertify-notifier .ajs-message.ajs-success {
    color: #fff;
    background: var(--buttons) !important;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0, 5);
}

.float-right {
    float: right;
}

.select2-container {
    width: 100% !important;
}

.dash-bg-right1 h3 {
    padding-left: 15px;
    padding-top: 20px;
}

/*calender*/

.ui-datepicker-calendar {
    margin: 40px 20px;
}

@media (max-width: 479px) {
    .ui-datepicker-calendar {
        margin: 15px auto;
        width: 80%;
    }
}

#calendar .ui-icon-circle-triangle-e {
    display: block;
}

#calendar .ui-datepicker-title {
    display: flex;
    justify-content: center;
    position: relative;
}

.product_ratting em {
    color: #fdd835;
}

/*favourite*/

/* / seller-details / */

.seller__sec .product_thumb {
    border: 2px solid #f5f5f5;
    border-radius: 100%;
    height: 150px;
    width: 150px;
    overflow: hidden;
    margin: 15px auto;
    display: block;
    position: relative;
}

.seller__sec .product_right_bar .single_product > figure:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.seller__sec .product_content h4 {
    line-height: 0px;
    margin: 25px auto;
    padding-top: 0px;
    font-weight: var(--font-weight);
    letter-spacing: 0.5px;
}

.seller__sec .product_right_bar .single_product > figure {
    border: none;
}

.seller-sec .reward-body-dtt {
    padding: 4.7rem 0;
}
.seller-sec .single_product:hover .price_box {
    opacity: 1;
}
.seller-sec .product_quantity {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 32px;
    line-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
}
.seller-sec .top_Content {
    display: flex;
    position: relative;
}
.seller-sec .top_Content .seller_wishlist {
    position: absolute;
    right: 10px;
    top: -5px;
}
.seller-sec .product_content {
    margin-top: 5px;
}
.seller-sec .single_product {
    padding: 20px;
}
.seller-sec .reward-img-icon {
    width: 150px;
    height: 150px;
    display: inline-block;
    background: #f9f9f9;
    border-radius: 100%;
    border: 2px solid #efefef;
    position: relative;
}

.seller-sec .owl-nav div {
    top: 44%;
}

@media (max-width: 767px) {
    .seller-sec .product_items {
        padding-top: 80px;
    }
    .seller-sec .owl-nav div {
        top: 56%;
    }
}

/* pincode css */
.pincode.modal-dialog.modal-dialog-centered {
    min-width: auto;
}
.defaulthead {
    border-bottom: none;
}
.warning-title {
    color: #f08080;
}

.selectallsec select::-webkit-scrollbar {
    width: 3px;
}
.selectallsec select::-webkit-scrollbar-thumb {
    background-color: var(--buttons);
}
.modal-content .modal-body div select {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #ddd;
    margin-bottom: 26px;
    padding: 15px;
    font-size: 0.875rem;
    height: auto;
}
::selection {
    background: transparent;
}
::-moz-selection {
    background: transparent;
}
.wrapper-demo {
    margin: 60px 0 0 0;
    *zoom: 1;
    font-weight: 400;
}
.wrapper-demo:after {
    clear: both;
    content: "";
    display: table;
}
.wrapper-dropdown-5 {
    position: relative;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.wrapper-dropdown-5:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    right: 25px;
    margin-top: -3px;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: var(--buttons) transparent;
}
.wrapper-dropdown-5 .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    border-bottom: none;
    list-style: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
}
.wrapper-dropdown-5 .dropdown li {
    padding: 0 10px;
}
.wrapper-dropdown-5 .dropdown li a {
    display: block;
    text-decoration: none;
    color: #333;
    padding: 10px 0;
    transition: all 0.3s ease-out;
    border-bottom: 1px solid #e6e8ea;
}
.wrapper-dropdown-5 .dropdown li:last-of-type a {
    border: none;
}
.save:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    content: "\f004";
}

.saved:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    content: "\f004";
    font-weight: 900;
}
.wrapper-dropdown-5 .dropdown li em {
    margin-right: 5px;
    color: inherit;
    vertical-align: middle;
}
/* / Hover state / */

.wrapper-dropdown-5 .dropdown li:hover a {
    color: #57a9d9;
}
/* / Active state / */

.wrapper-dropdown-5.active {
    border-radius: 5px 5px 0 0;
    box-shadow: none;
    border-bottom: none;
    color: white;
}
.wrapper-dropdown-5.active:after {
    border-color: #82d1ff transparent;
}
.wrapper-dropdown-5.active .dropdown {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    max-height: 150px;
    overflow-y: scroll;
}
.wrapper-dropdown-5.active .dropdown::-webkit-scrollbar {
    width: 3px;
}
.wrapper-dropdown-5.active .dropdown::-webkit-scrollbar-thumb {
    background-color: var(--buttons);
}
.deliver_notice, .address_notice {
    color: #f08080;
}

.availinner {
    border: 1px solid lightgrey;
    margin: 20px auto;
    padding: 10px;
    display: block;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.availinner img {
    width: 30px;
    height: 30px;
    max-width: 100%;
    max-height: 100%;
    padding-bottom: 10px;
}
.quick_deliver {
    display: flex;
}
.hide {
    display: none;
}

.Cancel__item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

/* order status color */

.received_status_bg {
    color: #3c8dbc;
    font-size: 1rem !important;
}
.awaiting_status_bg {
    color: #565650;
    font-size: 1rem !important;
}
.shipped_status_bg {
    color: #f39c12;
    font-size: 1rem !important;
}
.delivered_status_bg {
    color: #00a65a;
    font-size: 1rem !important;
}
.returned_and_cancel_status_bg {
    color: #dd4b39;
    font-size: 1rem !important;
}
.midtrans_iframe {
    width: 100%;
    height: 100%;
    border: none;
}
.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
    background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
}
.menu-item-has-children .active,
.sub_menu li .active {
    color: var(--buttons);
}
.select-container {
    display: inline-block;
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 4px 4px 0 0;
    width: 100%;
    box-sizing: border-box;
}

.selection-detail-container {
    display: block;
    line-height: 30px;
    padding: 10px;
    height: 100%;
}

.select-placeholder {
    color: #999;
    display: inline;
}

.selected-text {
    color: #000;
    font-weight: var(--font-weight);
}

.select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}

.select2-selection__arrow b {
    border-color: #888 transparent transparent;
    border-style: solid;
    border-width: 6px 5px 0;
    height: 0;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 140%;
    width: 0;
    right: 120%;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.dropdown-container {
    border: 1px solid #dddddd;
    border-top: transparent;
    padding: 10px;
}

.search-result {
    max-height: 100px;
    overflow-y: auto;
}

.dropdown-container .search-box {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
}

.search-results-list {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;
}

.search-results-list li:hover {
    background-color: #5897fb;
    color: #fff;
}

.search-results-list li {
    padding: 10px 6px;
    border-bottom: 1px solid #dddddd;
}

.delete-selected-text {
    position: absolute;
    right: 30px;
    cursor: pointer;
}

.render-selected-text .select-placeholder {
    display: none;
}
.search-result > p {
    padding: 10px 6px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 0px;
}

input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 6px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1.5px solid #bbbbbb;
    background-color: #e7e6e7;
    border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
    background-color: var(--buttons);
}

.payment__Sec .payment__icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin: auto 10px;
    max-width: 100%;
    max-height: 100%;
}

.payment__Sec hr {
    color: #bababc;
}

.payment__Sec .ui-datepicker,
.payment__Sec .ui-datepicker .ui-datepicker-header {
    border: 1px solid #dee2e6;
}

.payment__Sec .ui-datepicker-header {
    background: #f5f5f5;
}

.bg-custom {
    background: #f5f5f5;
}

.alert .close {
    position: absolute;
    right: 10px;
    font-weight: 900;
}

.walletUsed {
    display: none;
}

.wallet_recharge_modal .form-check .form-check-input {
    float: none;
}

.wallet_recharge_modal .close {
    background: transparent;
    border: transparent;
    font-weight: bold;
}

.wallet_recharge_modal .custom-control {
    margin-bottom: 5px;
}

.calender_data {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}

#orderConfirm .close {
    border: none;
}

.cancel_confirm > button {
    margin-right: 3px;
}
#sslczPayBtn {
    position: relative;
    background: #3498db;
    border: 0 none;
    color: #fff;
    padding: 6px !important;
    border-radius: 4px;
    height: 33px !important;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
    width: 130px !important;
    outline: 0;
    overflow: hidden;
    align-items: center;
    text-align: end;
}

.cart-main-content .table_description {
    margin: auto;
}

.product_name a {
    text-overflow: ellipsis;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    font-weight: var(--font-weight);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name_categories h4,
.number_product {
    text-overflow: ellipsis;
}

.product_details_right .seller_name p {
    margin: 15px auto;
}

.product_details_right .quick_deliver {
    position: relative;
}

.product_details_right .quick_deliver button {
    position: absolute;
    right: 0;
    line-height: 22px;
    font-weight: var(--font-weight);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.product_details_right .price-marquee button span {
    font-weight: var(--font-weight);
}

.old_price s {
    color: #878787 !important;
}

.btn-1 {
    z-index: 1;
}

.btn-1:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: #000;
    transition: all 1s ease;
}

.btn-1:hover {
    color: #fff;
}

.btn-1:hover:after {
    left: 0;
    width: 100%;
}

.btn-1:active {
    top: 2px;
}

/* refer and earn */
.referearn .copycode_refer{
    position: relative;
    margin: 1.25rem auto;
    width: 60%;
}

@media (min-width:480px) and (max-width:767px) {
    .referearn .copycode_refer{
        width: 70%;
    }
}

@media (max-width:479px) {
    .referearn .copycode_refer{
        width: 100%;
    }
}

.referearn .copycode_refer input {
    width: 100%;
    height: 45px;
    padding: 0 120px 0 0px;
    border-radius: 50px!important;
    border: 1px solid #343A40;

}

.referearn .copycode_refer button {
    height: 45px;
    padding: 0 20px;
    border-radius: 50px;
    background-color: #343A40;
    border: none;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 0px;
    right: 0px;
}

.referearn .copycode_refer button span.icon1 {
    margin-right: 5px;
}

.referearn .copycode_refer button span {
    display: inline-block;
}

.referearn .copycode_refer:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 50px;
}

.referearn .gift{
    font-size: 4rem;
}

/* ==========================================================================
  #DarkMode
========================================================================== */
/* #switch-mode */
#switch-mode {
    position: fixed;
    bottom: 80px;
    z-index: 110;
    right: 2.2%;
    cursor: pointer;
}

#switch-mode .mode-control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 10px;
    transition: all 0.5s ease;
}

#switch-mode .mode-control em {
    font-size: 20px;
}

#switch-mode .dark-mode {
    background-color: #787878;
    border: 3px solid #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

#switch-mode .dark-mode em {
    color: #ffffff;
}

#switch-mode .dark-mode:hover {
    background-image: linear-gradient(to right, #000, #6d6d6d);
}

#switch-mode .light-mode {
    background-color: #f1f1f1;
    border: 3px solid #121212;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

#switch-mode .light-mode em {
    color: #000;
}

body.dark-mode {
    background-color: var(--dark-mode);
    /* color: #e0e0e0; */
    height: auto;
}

body.dark-mode a {
    /* color: #3A2525; */
}

body.dark-mode a:hover {
    color: var(--buttons);
}

body.dark-mode .bg-white {
    background-color: var(--dark-mode) !important;
}

body.dark-mode h1,
body.dark-mode h2,
body.dark-mode h3,
body.dark-mode h4,
body.dark-mode h5,
body.dark-mode h6 {
    color: #3A2525;
}
body.dark-mode h1,
body.dark-mode h2
{
    font-weight: 800;
}

body.dark-mode .pagination ul li.active a,
body.dark-mode .pagination ul li:hover a {
    color: #ffffff;
}

body.dark-mode .pagination ul li.pagi-prev:hover:before,
body.dark-mode .pagination ul li.pagi-next:hover:after {
    background-color: #ffffff;
}

body.dark-mode .category_hover_content .select_option {
    color: var(--dark-mode);
}

body.dark-mode .category_hover_content .select_option .option a {
    color: var(--dark-mode);
}

body.dark-mode .login-sec button {
    background-color: var(--dark-mode);
}

body.dark-mode .product_container {
    background-color: var(--dark-mode);
}

body.dark-mode .deals_product_inner {
    background-color: var(--dark-mode);
}

body.dark-mode .active .slider_content h1,
body.dark-mode .active .slider_content h2,
body.dark-mode .active .slider_content h3,
body.dark-mode .active .slider_content a,
body.dark-mode .active .slider_content p,
body.dark-mode .active .slider_content span {
    color: var(--dark-mode);
}

body.dark-mode .action_links ul li a {
    color: var(--dark-mode);
}



body.dark-mode .list_action_right ul li.add_to_cart a {
    color: #fff !important;
    border: 1px solid #fff;
}

body.dark-mode .sub_menu.myaccount a {
    color: #000 !important;
}
/*
body.dark-mode .cart-main-content .cart-page-title,
body.dark-mode .table_description .cart_page-content table tbody tr td.header_product-price,
body.dark-mode .table_description .cart_page-content table tbody tr td.header_product_quantity input,
body.dark-mode .commun-table .table tbody tr:first-child td,
body.dark-mode .product-detail-sec .product_details_right h1 a,
body.dark-mode .product-detail-sec .product_details_right .product_desc p,
body.dark-mode .product-detail-sec .product_details_right .price_box span.old_price,
body.dark-mode .product-detail-sec .product_variant.size label,
body.dark-mode .product-detail-sec .product_variant.quantity label,
body.dark-mode .product-detail-sec .product_variant.quantity input,
body.dark-mode .product-detail-sec .product_meta span,
body.dark-mode .product-detail-sec .policy-content > div > div h5,
body.dark-mode .product_d_info .reviews_wrapper .reviews_meta p strong,
body.dark-mode .blog_sidebar_content1 .content1_categories ul li a,
body.dark-mode .about-us-content p,
body.dark-mode .inner-testimonial .client-content p,
body.dark-mode .typographysec blockquote,
body.dark-mode .table-striped th,
body.dark-mode .table-bordered,
body.dark-mode .counterup2 .counter-count,
body.dark-mode .content_list.content_filter form input,
body.dark-mode .product_right_bar .desc_title,
body.dark-mode .main_header a,
body.dark-mode .connect-with-us p,
body.dark-mode .pop_desc_title,
body.dark-mode .product_info_button ul li a.active,
body.dark-mode .header_account_area .header_account_list .profile__name,
body.dark-mode label.control-label,
body.dark-mode .text-dark,
body.dark-mode .wallet__history_data p,
body.dark-mode .payment__Sec .checkout-section label {
    color: #3A2525 !important;
    font-weight: 600;
}

body.dark-mode .main_menu_content nav > ul > li ul.sub_menu li a,
body.dark-mode .mega_menu_inner > li > a,
body.dark-mode .mega_menu_inner > li ul li a,
body.dark-mode .cart_info a,
body.dark-mode .cart_info p,
body.dark-mode .cart_total span,
body.dark-mode .cart_button a,
body.dark-mode .quick_view .modal-content button.close,
body.dark-mode .product_details_right h1 a,
body.dark-mode .product_details_right .product_desc p,
body.dark-mode .product_details_right .price_box span.old_price,
body.dark-mode .product_variant.size label,
body.dark-mode .product_variant.size .niceselect_option,
body.dark-mode .product_variant.quantity label,
body.dark-mode .product_meta span,
body.dark-mode .nice-select .option,
body.dark-mode .nice-select .option a,
body.dark-mode .mobile_wrapper a,
body.dark-mode .mobile_wrapper,
body.dark-mode .countdown_number,
body.dark-mode .countdown_title,
body.dark-mode .popular-categories .pop_list-categories .pop_desc_listcat .name_categories h4,
body.dark-mode .blog-post-style3 .grid-item .blog-post *,
body.dark-mode .testi-content-text,
body.dark-mode .page_title h1,
body.dark-mode .page_title h2,
body.dark-mode .tag_cloud a,
body.dark-mode .nice-select span,
body.dark-mode .pagination ul li a,
body.dark-mode .list_action_right ul li a,
body.dark-mode .share_links ul li a,
body.dark-mode .grand-total-content h5,
body.dark-mode .grand-total-content .h5,
body.dark-mode .user-item,
body.dark-mode .cart-text h4,
body.dark-mode .cart-text .desc,
body.dark-mode .cart-item-price span,
body.dark-mode .dash-bg-right-title h4,
body.dark-mode .address-detail-all h4,
body.dark-mode .action-btn,
body.dark-mode .category-header h4,
body.dark-mode .heading-bg h2.heading,
body.dark-mode .cart-total-dil h4,
body.dark-mode .main-total-cart h2,
body.dark-mode .track-order h4,
body.dark-mode .delivery-man a,
body.dark-mode .no_history h3,
body.dark-mode .blog_sidebar_content1 .tag_content1 ul li a,
body.dark-mode .team-members .team-member .team-desc > p,
body.dark-mode .team-members .team-member .team-desc > span,
body.dark-mode .blog_details .post_content blockquote p,
body.dark-mode .alerts .close,
body.dark-mode .card-text,
body.dark-mode .card-title,
body.dark-mode .main-contactform .form-group label,
body.dark-mode .dash-body h2,
body.dark-mode .more-links,
body.dark-mode .order-status,
body.dark-mode .wallet-body h2,
body.dark-mode .inner-wallet-links,
body.dark-mode .cashbk-price,
body.dark-mode .offer-table.earning-table td,
body.dark-mode .purchase-history-left h4,
body.dark-mode .purchase-history-right a,
body.dark-mode .signupbody label,
body.dark-mode .signupbody p,
body.dark-mode .signupbody a,
body.dark-mode .header_account_list.register ul li a,
body.dark-mode .modal-content .nav-tabs1 a,
body.dark-mode .categories_content_toggle > ul > li ul.cate_mega_menu > li > a,
body.dark-mode .owl-item.active .slider-animated-content h3,
body.dark-mode .owl-item.active .slider-animated-content h1,
body.dark-mode .main_header button.cartShow,
body.dark-mode .ftr a,
body.dark-mode .blog_details .social_icons ul li a em,
body.dark-mode .team-members .team-member .team-desc > h3,
body.dark-mode .login_section .card-body p,
body.dark-mode .categories_content_toggle > ul > li > a,
body.dark-mode #pincodeModalLabel,
body.dark-mode .pincode .close,
body.dark-mode .wallet_history .modal-dialog,
body.dark-mode #orderConfirm .modal-dialog,
body.dark-mode .quick_view.modal-dialog,
body.dark-mode .name_categories h4,
body.dark-mode .pop_desc_listcat,
body.dark-mode .pop_desc_listcat .view-more a {
    color: var(--dark-mode) !important;
} */

body.dark-mode .pincode .warning-title {
    color: #f08080 !important;
}

body.dark-mode .login-sec button {
    color: #fff;
}

body.dark-mode .copy-right a {
    color: var(--buttons);
}

body.dark-mode .animate-border:after {
    border-left: 10px solid #212121;
    border-right: 10px solid #212121;
}

body.dark-mode header {
    background-color: var(--dark-mode);
}

body.dark-mode .view-all::before {
    border-top: 0.1rem solid #fff;
}

body.dark-mode .circular-progress .progress {
    background-color: var(--dark-mode) !important;
}

body.dark-mode .connect-with-us p::before {
    background: url(../images/darkmodephone.png) no-repeat 0 0;
}

body.dark-mode .shop_toolbar_btn > button.btn-grid-view.active {
    background: url(../images/darkmodegrid.png) no-repeat scroll center center;
}

body.dark-mode .shop_toolbar_btn > button.btn-list-view {
    background: url(../images/darkmodelist.png) no-repeat scroll center center !important;
}

body.dark-mode .shop_toolbar_btn > button.btn-grid-view {
    background: url(../images/darkmodegrid.png) no-repeat scroll center center;
}

body.dark-mode .shadow,
body.dark-mode .shadow-sm {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.15) !important;
    /* border-radius: 9px; */

}
.rounded
{
    border-radius: 8px !important;
}
/* new switcher */

ul.color-inputs {
    list-style-type: none;
    padding: 0;
}

.color-inputs li {
    margin-bottom: 15px;
    margin-bottom: 9px;
}

.color-inputs label {
    display: block;
    text-transform: capitalize;
    font-weight: 700;
    padding-top: 4px;
    font-size: 16px;
    color: #000;
    margin-bottom: 5px;
    padding-bottom: 6px;
}

.sp-palette-container {
    display: none !important;
}

.styler-footer {
    padding: 25px;
    background-color: #24344a;
}

/* spectrum customize color */
.sp-colorize {
    height: 22px;
    cursor: pointer;
}

@media (max-width: 1920px) {
    .color-inputs .sp-colorize-container.sp-add-on {
        height: 12px;
        top: -2px;
        left: 3px;
        box-shadow: none;
    }
}

.color-inputs .sp-colorize-container.sp-add-on {
    position: absolute;
    height: 22px;
    width: 34px !important;
    top: 8px;
    left: 8px;
    border: none !important;
}

@media (max-width: 1920px) {
    .color-inputs input.spectrum.with-add-on {
        padding-top: 4px;
        padding-bottom: 4px;
        line-height: 18px;
    }
}

.color-inputs input.spectrum.with-add-on {
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 20px;
    cursor: pointer;
    background: #1d2f48;
    border: 1px solid #3a4e69;
    color: #fff;
    width: 100%;
}

.color-inputs .sp-original-input-container {
    display: block;
    text-align: center;
}

/* color-switcher */
.demo-style-switch .switch-button em {
    color: var(--buttons);
}

.demo-style-switch {
    position: fixed;
    z-index: 9999;
    top: 200px;
    left: -200px;
    background: #ffffff;
}

.demo-style-switch:hover {
    opacity: 1 !important;
}

.demo-style-switch .switched-options {
    position: relative;
    width: 200px;
    text-align: left;
    padding: 1px 14px;
    background-color: #fff;
}

.demo-style-switch .config-title {
    text-transform: capitalize;
    font-weight: 700;
    padding-top: 4px;
    font-size: 16px;
    color: #000;
    border-bottom: 1px dotted #cccccc;
    border-top: 1px dotted #cccccc;
    margin-bottom: 5px;
    padding-bottom: 6px;
}

.demo-style-switch ul {
    margin-bottom: 4px;
}

.demo-style-switch ul .active {
    color: #005885;
    font-weight: 700;
}

.demo-style-switch ul .active a {
    color: #005885;
    font-weight: 700;
}

.demo-style-switch ul .p {
    font-weight: 400;
    font-size: 12px;
    color: #ccc;
    margin-top: 10px;
}

.demo-style-switch ul li a {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #808080;
}

.demo-style-switch ul li a:hover {
    color: #008ed6;
}

.demo-style-switch ul.styles {
    margin-top: 10px;
}

.demo-style-switch ul.styles li {
    display: inline-block;
    margin-right: 5px;
}

.demo-style-switch .switch-button {
    opacity: 1 !important;
    background: #fff !important;
    padding: 10px;
    font-size: 24px;
    color: #272727 !important;
    position: absolute;
    overflow: hidden;
    right: -44px;
    top: -10;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
}

.demo-style-switch .switch-button:hover {
    color: #008ed6;
    cursor: pointer;
    text-decoration: none;
}

.mh-demo-styles h4 {
    color: #202026cf;
    margin: 10px 0;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
}

.mh-demo-styles ul {
    margin-bottom: 12px;
}

.mh-demo-styles ul li {
    display: block;
    width: 100%;
}

.demo-style-switch ul li a img {
    border: 1px solid #000;
}

input[type="radio"]:disabled + label {
    color: #bababc;
}

.alertify-notifier.ajs-right {
    text-align: center;
}

/* coupan */

.discount-code .coupan_text{
    border: 2px dashed #aaa;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    text-transform: uppercase;
}

.discount-code .inner__sec_discount{
    border: 1px solid #bcbdbdbf;
    border-radius: 5px;
    padding: 10px;

}

.coupan__wrapper{
    height: 280px;
    overflow-y: scroll;
}

.coupan__wrapper::-webkit-scrollbar-thumb {
    background: var(--buttons);
    border-radius: 10px;

}

.coupan__wrapper::-webkit-scrollbar{
    width: 5px;
}

.coupan__wrapper::-webkit-scrollbar-track {
    background: #ddd;
}

.redclr{
    color:#D33;
}

.themeclr{
    color:var(--buttons);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*Bank Transfer recipient*/
.bank_transfer_warning{
    display:none;
}

.receipt_img{
    height: 150px;
    width: 150px;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    margin-bottom: 10px;
    border: 1px solid #ddd;
}


.attach_image_delete{

    border: 1px solid #ddd;
    color: #212121;
    padding: 7px 10px;
    border-radius: 100%;
    position: relative;
    cursor: pointer;
    top: -70px;
    right: 18px;
    background-color: #fff;
}

#bank_receipt_form{
    display: flex;
    align-items: center;
    margin: 20px;
}

#bank_receipt_form .fileinput{
    padding: 0;
    background-color: #fff;
    border: 1px solid #eee;
    line-height: 27px;
}

#bank_receipt_form button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.border-notifi{
    border:1px solid lightgrey;
    margin-bottom:5px;
}
.border-notifi p{
    margin-bottom:20px;
}
.p15-4{
    padding: 15px 4px;
}
.order_track_page .call-bill{
    border-bottom: 1px solid #efefef;
    border-top:0px !important;
}
.close{
   background: orange;
    border: 1px solid orange;
    padding: 1px 8px;
    color: white;
    border-radius: 20px;
    font-size: 17px;
}
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #f2f3f8;
  top: 0;
  bottom: 0;
  left: 10%;
  margin-left: -6px;
}

/* Container around content */
.container_modal {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 90%;
}

/* The circles on the timeline */
.container_modal::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #f2f3f8;
  border: 4px solid #FF9F55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 10%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 6px 0px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}
.modal-content {
    padding-bottom: 30px;
}

.mt-15{
    margin-top:15px !important;
}

.glyphicon-question-sign:before {
    content: "\e085";
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

td.checkoutbtn{
    border-bottom:none;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
  left: 31px;
  }

  /* Full-width containers */
  .container_modal {
  width: 100%;
  padding-left: 70px;
  padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container_modal::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
  left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
  left: 0%;
  }
}

/* Print button */
button.print-button {
    width: 100px;
    height: 100px;
  }
  span.print-icon, span.print-icon::before, span.print-icon::after, button.print-button:hover .print-icon::after {
    border: solid 4px #333;
  }
  span.print-icon::after {
    border-width: 2px;
  }

  button.print-button {
    position: relative;
    padding: 0;
    border: 0;
    float:right;
    border: none;
    background: transparent;
  }

  span.print-icon, span.print-icon::before, span.print-icon::after, button.print-button:hover .print-icon::after {
    box-sizing: border-box;
    background-color: #fff;
  }

  span.print-icon {
    position: relative;
    display: inline-block;
    padding: 0;
    margin-top: 0%;

    width: 60%;
    height: 35%;
    background: #fff;
    border-radius: 20% 20% 0 0;
  }

  span.print-icon::before {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 12%;
    right: 12%;
    height: 110%;

    transition: height .2s .15s;
  }

  span.print-icon::after {
    content: " ";
    position: absolute;
    top: 55%;
    left: 12%;
    right: 12%;
    height: 0%;
    background: #fff;
    background-repeat: no-repeat;
    background-size: 70% 90%;
    background-position: center;
    background-image: linear-gradient(
      to top,
      #fff 0, #fff 14%,
      #333 14%, #333 28%,
      #fff 28%, #fff 42%,
      #333 42%, #333 56%,
      #fff 56%, #fff 70%,
      #333 70%, #333 84%,
      #fff 84%, #fff 100%
    );

    transition: height .2s, border-width 0s .2s, width 0s .2s;
  }

  button.print-button:hover {
    cursor: pointer;
  }

  button.print-button:hover .print-icon::before {
    height:0px;
    transition: height .2s;
  }
  button.print-button:hover .print-icon::after {
    height:120%;
    transition: height .2s .15s, border-width 0s .16s;
  }

